import BaseService from './_base.service';

export const markService = {
    page,
    search,
    combo,
    edit,
    create,
    one,
    remove,
    removeLogic
};

function page(page,size, callback) {
    return BaseService.get("/mark/page/"+page+"/"+size, callback,)
}
function search(page,size,filter, callback) {
    return BaseService.post("/mark/page/"+page+"/"+size,filter, callback, true)
}
function combo(callback) {
    return BaseService.get("/mark/combo/", callback)
}
function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/mark/"+id, body, callback, false)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/mark/", body, callback, false)
}

function one(id,callback) {
    return BaseService.get("/mark/one/"+id, callback);
}

function remove(id,callback) {
    return BaseService.delete("/mark/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete("/mark/"+id+"/logic", callback);
}
