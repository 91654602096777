import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudForm from "../../crud/CrudForm"
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {markService as service} from "../../../services"

const initElement = {
     name:"",
     code: "",
     ordermark: 100
  };


export default function FormMark() {
  const classes = useStyles();
  const [objectform, setObjectform] = React.useState({...initElement});

  const estructureform =  [
          {
           type:"input",
           name: "code",
           label: "Código",
           col: 6
          },
         {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 6
          },
          {
           type:"input",
           name: "ordermark",
           label: "Orden",
           col: 6,
           inputtype:"number"
          }
    ];


  const recoveryElement = function(objectedit,element){
    objectedit['name']=element.name?element.name:"";
    objectedit['code']=element.code?element.code:"";
    objectedit['ordermark']=element.ordermark?element.ordermark:"";
    return objectedit;
  }

  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      }
      if(!object['code']){
        toast.notify("Debe indicar del código", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }



  return (

        <CrudForm
            objectform = {objectform}
            estructureform = {estructureform}
            titleEdit = {'Editar familia'}
            titleNew = {'Nueva familia'}
            urledit={"/lens/mark/"}
            urlCancel={"/lens/mark"}
            service = {service}
            recoveryElement = {recoveryElement}
            valid = {isValid}
          />
  );
}
