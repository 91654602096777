import BaseService from './_base.service';

export const bannersService = {
    page,
    search,
    edit,
    create,
    one,
    remove,
    suscription,
    removeLogic
};

function page(page,size, callback) {
    return BaseService.get("/banners/page/"+page+"/"+size, callback, true)
}
function search(page,size,filter, callback) {
    return BaseService.post("/banners/page/"+page+"/"+size,filter, callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/banners/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/banners/", body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/banners/one/"+id, callback);
}

function remove(id,callback) {
    return BaseService.delete("/banners/"+id, callback);
}

function suscription(page,size, callback) {
    return BaseService.get("/user/banners/page/"+page+"/"+size, callback, true)
}

function removeLogic(id,callback) {
    return BaseService.delete("/banners/"+id+"/logic", callback);
}
