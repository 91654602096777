import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Link } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import Collapse from '@material-ui/core/Collapse';
import LocalSeeIcon from '@material-ui/icons/LocalSee';
import VideocamIcon from '@material-ui/icons/Videocam';
import CameraRollIcon from '@material-ui/icons/CameraRoll';
import CameraIcon from '@material-ui/icons/Camera';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import SwitchCameraIcon from '@material-ui/icons/SwitchCamera';
import CameraFrontIcon from '@material-ui/icons/CameraFront';
import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import StoreIcon from '@material-ui/icons/Store';
import StorefrontIcon from '@material-ui/icons/Storefront';
import PersonIcon from '@material-ui/icons/Person';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DnsIcon from '@material-ui/icons/Dns';
import ForumIcon from '@material-ui/icons/Forum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScaleLeft, faAddressCard, faBell, faStar, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import LanguageIcon from '@material-ui/icons/Language';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import TurnedInIcon from '@material-ui/icons/TurnedIn';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { LinkOffOutlined as LinkIcon, Maximize as MaximizeIcon } from '@material-ui/icons';

export default function Header(props) {
      const [openAttrLens, setOpenAttrLens] = React.useState(false);

      const handleClickAttrLens = () => {
            setOpenAttrLens(!openAttrLens);
      };
      const [openRentals, setOpenRentals] = React.useState(false);

      const handleClickRentals = () => {
            if (!openRentals) {
                  setOpenAttrLens(false);
                  setOpenDealers(false);
                  setOpenUsers(false);
                  setOpenLens(false);
                  setOpenCamaras(false);
                  setOpenForo(false);
                  setOpenCMS(false);
                  setOpenMarket(false);
                  setOpenBlog(false);
                  setOpenPartner(false);
                  setOpenReport(false);
            }
            setOpenRentals(!openRentals);
      };
      const [openDealers, setOpenDealers] = React.useState(false);

      const handleClickDealers = () => {
            if (!openDealers) {
                  setOpenAttrLens(false);
                  setOpenRentals(false);
                  setOpenUsers(false);
                  setOpenLens(false);
                  setOpenCamaras(false);
                  setOpenForo(false);
                  setOpenCMS(false);
                  setOpenMarket(false);
                  setOpenBlog(false);
                  setOpenPartner(false);
                  setOpenReport(false);
            }
            setOpenDealers(!openDealers);
      };
      const [openUsers, setOpenUsers] = React.useState(false);

      const handleClickUsers = () => {
            if (!openUsers) {
                  setOpenAttrLens(false);
                  setOpenRentals(false);
                  setOpenDealers(false);
                  setOpenLens(false);
                  setOpenCamaras(false);
                  setOpenForo(false);
                  setOpenCMS(false);
                  setOpenMarket(false);
                  setOpenBlog(false);
                  setOpenPartner(false);
                  setOpenReport(false);
            }
            setOpenUsers(!openUsers);
      };

      const [openLens, setOpenLens] = React.useState(false);

      const handleClickLens = () => {
            if (!openLens) {
                  setOpenAttrLens(false);
                  setOpenRentals(false);
                  setOpenDealers(false);
                  setOpenUsers(false);
                  setOpenCamaras(false);
                  setOpenForo(false);
                  setOpenCMS(false);
                  setOpenMarket(false);
                  setOpenBlog(false);
                  setOpenPartner(false);
                  setOpenReport(false);
            }
            setOpenLens(!openLens);
      };

      const [openCamaras, setOpenCamaras] = React.useState(false);

      const handleClickCamaras = () => {
            if (!openCamaras) {
                  setOpenAttrLens(false);
                  setOpenRentals(false);
                  setOpenDealers(false);
                  setOpenUsers(false);
                  setOpenLens(false);
                  setOpenForo(false);
                  setOpenCMS(false);
                  setOpenMarket(false);
                  setOpenBlog(false);
                  setOpenPartner(false);
                  setOpenReport(false);
            }
            setOpenCamaras(!openCamaras);
      };

      const [openForo, setOpenForo] = React.useState(false);

      const handleClickForo = () => {
            if (!openForo) {
                  setOpenAttrLens(false);
                  setOpenRentals(false);
                  setOpenDealers(false);
                  setOpenUsers(false);
                  setOpenLens(false);
                  setOpenCamaras(false);
                  setOpenCMS(false);
                  setOpenMarket(false);
                  setOpenBlog(false);
                  setOpenPartner(false);
                  setOpenReport(false);
            }
            setOpenForo(!openForo);
      };
      const [openCMS, setOpenCMS] = React.useState(false);

      const handleClickCMS = () => {
            if (!openCMS) {
                  setOpenAttrLens(false);
                  setOpenRentals(false);
                  setOpenDealers(false);
                  setOpenUsers(false);
                  setOpenLens(false);
                  setOpenForo(false);
                  setOpenCamaras(false);
                  setOpenMarket(false);
                  setOpenBlog(false);
                  setOpenPartner(false);
                  setOpenReport(false);
            }
            setOpenCMS(!openCMS);
      };

      const [openMarket, setOpenMarket] = React.useState(false);

      const handleClickMarket = () => {
            if (!openMarket) {
                  setOpenAttrLens(false);
                  setOpenRentals(false);
                  setOpenDealers(false);
                  setOpenUsers(false);
                  setOpenLens(false);
                  setOpenForo(false);
                  setOpenCamaras(false);
                  setOpenCMS(false);
                  setOpenBlog(false);
                  setOpenPartner(false);
                  setOpenReport(false);
            }
            setOpenMarket(!openMarket);
      };

      const [openBlog, setOpenBlog] = React.useState(false);

      const handleClickBlog = () => {
            if (!openForo) {
                  setOpenAttrLens(false);
                  setOpenRentals(false);
                  setOpenDealers(false);
                  setOpenUsers(false);
                  setOpenLens(false);
                  setOpenCamaras(false);
                  setOpenCMS(false);
                  setOpenMarket(false);
                  setOpenForo(false);
                  setOpenPartner(false);
                  setOpenReport(false);
            }

            setOpenBlog(!openBlog);
      };

      const [openPartner, setOpenPartner] = React.useState(false);

      const handleClickPartner = () => {
            if (!openForo) {
                  setOpenAttrLens(false);
                  setOpenRentals(false);
                  setOpenDealers(false);
                  setOpenUsers(false);
                  setOpenLens(false);
                  setOpenCamaras(false);
                  setOpenCMS(false);
                  setOpenMarket(false);
                  setOpenForo(false);
                  setOpenBlog(false);
                  setOpenReport(false);
            }

            setOpenPartner(!openPartner);
      };

      const [openReport, setOpenReport] = React.useState(false);

      const handleClickReport = () => {
            if (!openForo) {
                  setOpenAttrLens(false);
                  setOpenRentals(false);
                  setOpenDealers(false);
                  setOpenUsers(false);
                  setOpenLens(false);
                  setOpenCamaras(false);
                  setOpenCMS(false);
                  setOpenMarket(false);
                  setOpenForo(false);
                  setOpenBlog(false);
                  setOpenPartner(false);
            }

            setOpenReport(!openReport);
      };

      return (
            <Drawer
                  variant="permanent"
                  classes={{
                        paper: clsx(props.classes.drawerPaper, !props.open && props.classes.drawerPaperClose),
                  }}
                  open={props.open}
            >
                  <div className={props.classes.toolbarIcon}>
                        <IconButton onClick={props.handleDrawerClose}>
                              <ChevronLeftIcon />
                        </IconButton>
                  </div>
                  <Divider />
                  <List>
                        <div>
                              <Link to="/dashboard" style={{ color: 'inherit', textDecoration: 'none' }}>
                                    <ListItem button>
                                          <ListItemIcon>
                                                <DashboardIcon />
                                          </ListItemIcon>
                                          <ListItemText primary="Panel de control" />
                                    </ListItem>
                              </Link>
                        </div>
                  </List>
                  <Divider />
                  <List>
                        <div>
                              <ListItem button onClick={handleClickLens}>
                                    <ListItemIcon>
                                          <LocalSeeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Lentes" />
                                    {openLens ? <ExpandLess /> : <ExpandMore />}
                              </ListItem>
                              <Collapse in={openLens} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                          <Link to="/lens" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <LocalSeeIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Lentes" />
                                                </ListItem>
                                          </Link>
                                          <Link to="/series" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <CameraEnhanceIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Series" />
                                                </ListItem>
                                          </Link>
                                          <Link to="/manufactures" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <AccountBalanceIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Fabricantes" />
                                                </ListItem>
                                          </Link>
                                          <ListItem button onClick={handleClickAttrLens} className={props.classes.nested}>
                                                <ListItemIcon>
                                                      <InboxIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Atributos" />
                                                {openAttrLens ? <ExpandLess /> : <ExpandMore />}
                                          </ListItem>
                                          <Collapse in={openAttrLens} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                      <Link to="/lens/types" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                            <ListItem button className={props.classes.nested2}>
                                                                  <ListItemIcon>
                                                                        <MergeTypeIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="Tipos" />
                                                            </ListItem>
                                                      </Link>
                                                      <Link to="/lens/mark" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                            <ListItem button className={props.classes.nested2}>
                                                                  <ListItemIcon>
                                                                        <AccountTreeIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="Familia" />
                                                            </ListItem>
                                                      </Link>
                                                      <Link to="/lens/focal" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                            <ListItem button className={props.classes.nested2}>
                                                                  <ListItemIcon>
                                                                        <CameraIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="Focales" />
                                                            </ListItem>
                                                      </Link>
                                                      <Link to="/lens/tstop" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                            <ListItem button className={props.classes.nested2}>
                                                                  <ListItemIcon>
                                                                        <ControlCameraIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="TStop" />
                                                            </ListItem>
                                                      </Link>
                                                      <Link to="/lens/length" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                            <ListItem button className={props.classes.nested2}>
                                                                  <ListItemIcon>
                                                                        <SwitchCameraIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="Tamaño" />
                                                            </ListItem>
                                                      </Link>
                                                      <Link to="/lens/weigth" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                            <ListItem button className={props.classes.nested2}>
                                                                  <ListItemIcon>
                                                                        <FitnessCenterIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="Peso" />
                                                            </ListItem>
                                                      </Link>
                                                      <Link to="/lens/minfocus" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                            <ListItem button className={props.classes.nested2}>
                                                                  <ListItemIcon>
                                                                        <CameraFrontIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="Enfoque min." />
                                                            </ListItem>
                                                      </Link>
                                                      <Link to="/lens/frontdiameter" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                            <ListItem button className={props.classes.nested2}>
                                                                  <ListItemIcon>
                                                                        <FlipCameraAndroidIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="Diametro" />
                                                            </ListItem>
                                                      </Link>
                                                </List>
                                          </Collapse>
                                    </List>
                              </Collapse>
                        </div>
                  </List>
                  <Divider />
                  <List>
                        <div>
                              <ListItem button onClick={handleClickCamaras}>
                                    <ListItemIcon>
                                          <VideocamIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Camaras" />
                                    {openCamaras ? <ExpandLess /> : <ExpandMore />}
                              </ListItem>
                              <Collapse in={openCamaras} timeout="auto" unmountOnExit>
                                    <List>
                                          <Link to="/cameras" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <VideocamIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Camaras" />
                                                </ListItem>
                                          </Link>
                                          <Link to="/cameras/resolution" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <CameraRollIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Modelos" />
                                                </ListItem>
                                          </Link>
                                    </List>
                              </Collapse>
                        </div>
                  </List>
                  <Divider />
                  <List>
                        <div>
                              <ListItem button onClick={handleClickRentals}>
                                    <ListItemIcon>
                                          <LocalMallIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Rentals" />
                                    {openRentals ? <ExpandLess /> : <ExpandMore />}
                              </ListItem>
                              <Collapse in={openRentals} timeout="auto" unmountOnExit>
                                    <List>
                                          <Link to="/rentals" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <LocalMallIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Rentals" />
                                                </ListItem>
                                          </Link>
                                          <Link to="/rentals/family" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <StorefrontIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Familias" />
                                                </ListItem>
                                          </Link>
                                          <Link to="/rentals/lens" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <LocalSeeIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Lentes por familia" />
                                                </ListItem>
                                          </Link>
                                          <Link to="/rentals/users" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <PersonIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Usuarios rentals" />
                                                </ListItem>
                                          </Link>
                                    </List>
                              </Collapse>
                        </div>
                  </List>
                  <Divider />

                  <List>
                        <div>
                              <Link to="/manufacturesus" style={{ color: 'inherit', textDecoration: 'none' }}>
                                    <ListItem button>
                                          <ListItemIcon>
                                                <MaximizeIcon />
                                          </ListItemIcon>
                                          <ListItemText primary="Suscripcion Fabr." />
                                    </ListItem>
                              </Link>
                        </div>
                  </List>
                  <Divider />
                  <List>
                        <div>
                              <ListItem button onClick={handleClickDealers}>
                                    <ListItemIcon>
                                          <StoreIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Dealers" />
                                    {openDealers ? <ExpandLess /> : <ExpandMore />}
                              </ListItem>
                              <Collapse in={openDealers} timeout="auto" unmountOnExit>
                                    <List>
                                          <Link to="/dealers" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <StoreIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Dealers" />
                                                </ListItem>
                                          </Link>
                                          <Link to="/dealers/family" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <StorefrontIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Familias" />
                                                </ListItem>
                                          </Link>
                                          <Link to="/dealers/manufacture" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <CameraEnhanceIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Fabricantes por Fa." />
                                                </ListItem>
                                          </Link>
                                          <Link to="/dealers/users" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <PersonIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Usuarios dealers" />
                                                </ListItem>
                                          </Link>
                                    </List>
                              </Collapse>
                        </div>
                  </List>
                  <Divider />
                  <List>
                        <div>
                              <ListItem button onClick={handleClickUsers}>
                                    <ListItemIcon>
                                          <PersonIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Usuarios" />
                                    {openUsers ? <ExpandLess /> : <ExpandMore />}
                              </ListItem>
                              <Collapse in={openUsers} timeout="auto" unmountOnExit>
                                    <List>
                                          <Link to="/users" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <PersonIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Usuarios" />
                                                </ListItem>
                                          </Link>
                                    </List>
                                    <List>
                                          <Link to="/crew" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <PersonIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Crew" />
                                                </ListItem>
                                          </Link>
                                    </List>
                                    <List>
                                          <Link to="/suscriptions" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <CreditCardIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Suscripciones" />
                                                </ListItem>
                                          </Link>
                                    </List>
                                    <List>
                                          <Link to="/suscription/state" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <DnsIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Estado suscripciones" />
                                                </ListItem>
                                          </Link>
                                    </List>
                                    <List>
                                          <Link to="/job" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <WorkIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Puestos" />
                                                </ListItem>
                                          </Link>
                                    </List>
                                    <List>
                                          <Link to="/language" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <GTranslateIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Idiomas" />
                                                </ListItem>
                                          </Link>
                                    </List>
                                    <List>
                                          <Link to="/coupon" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <LocalOfferIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Cupones" />
                                                </ListItem>
                                          </Link>
                                    </List>
                              </Collapse>
                        </div>
                  </List>
                  <Divider />

                  <List>
                        <div>
                              <ListItem button onClick={handleClickForo}>
                                    <ListItemIcon>
                                          <ForumIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Foro" />
                                    {openForo ? <ExpandLess /> : <ExpandMore />}
                              </ListItem>
                              <Collapse in={openForo} timeout="auto" unmountOnExit>
                                    <List>
                                          <Link to="/foro" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <ForumIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Foro" />
                                                </ListItem>
                                          </Link>
                                    </List>
                                    <List>
                                          <Link to="/foro/complaint" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <FontAwesomeIcon icon={faBalanceScaleLeft} size="lg" />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Denuncias" />
                                                </ListItem>
                                          </Link>
                                    </List>
                              </Collapse>
                        </div>
                  </List>
                  <Divider />
                  <List>
                        <Link to="/reels" style={{ color: 'inherit', textDecoration: 'none' }}>
                              <ListItem button>
                                    <ListItemIcon>
                                          <ForumIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Reels" />
                              </ListItem>
                        </Link>
                  </List>
                  <Divider />
                  <List>
                        <div>
                              <ListItem button onClick={handleClickBlog}>
                                    <ListItemIcon>
                                          <RssFeedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Blog" />
                                    {openBlog ? <ExpandLess /> : <ExpandMore />}
                              </ListItem>
                              <Collapse in={openBlog} timeout="auto" unmountOnExit>
                                    <List>
                                          <Link to="/blog" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <RssFeedIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Blog" />
                                                </ListItem>
                                          </Link>
                                    </List>
                                    <List>
                                          <Link to="/categoryblog" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <MergeTypeIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Categorías" />
                                                </ListItem>
                                          </Link>
                                    </List>
                                    <List>
                                          <Link to="/tag" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <TurnedInIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Etiquetas" />
                                                </ListItem>
                                          </Link>
                                    </List>
                              </Collapse>
                        </div>
                  </List>
                  <Divider />
                  <List>
                        <div>
                              <Link to="/banners" style={{ color: 'inherit', textDecoration: 'none' }}>
                                    <ListItem button>
                                          <ListItemIcon>
                                                <ImageIcon />
                                          </ListItemIcon>
                                          <ListItemText primary="Banners" />
                                    </ListItem>
                              </Link>
                        </div>
                  </List>
                  <Divider />
                  <List>
                        <div>
                              <ListItem button onClick={handleClickMarket}>
                                    <ListItemIcon>
                                          <ShoppingCartIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Marketplace" />
                                    {openMarket ? <ExpandLess /> : <ExpandMore />}
                              </ListItem>
                              <Collapse in={openMarket} timeout="auto" unmountOnExit>
                                    <List>
                                          <Link to="/marketplace/" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <ShoppingCartIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Lentes y cámaras" />
                                                </ListItem>
                                          </Link>
                                    </List>
                                    <List>
                                          <Link to="/marketplace/complaint" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <FontAwesomeIcon icon={faBalanceScaleLeft} size="lg" />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Denuncias" />
                                                </ListItem>
                                          </Link>
                                    </List>
                                    <List>
                                          <Link to="/wishproduct" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <FontAwesomeIcon icon={faStar} size="lg" />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Productos deseados" />
                                                </ListItem>
                                          </Link>
                                    </List>
                                    <List>
                                          <Link to="/orders" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <FontAwesomeIcon icon={faCreditCard} size="lg" />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Compras" />
                                                </ListItem>
                                          </Link>
                                    </List>
                              </Collapse>
                        </div>
                  </List>
                  <Divider />
                  <List>
                        <div>
                              <Link to="/credits" style={{ color: 'inherit', textDecoration: 'none' }}>
                                    <ListItem button>
                                          <ListItemIcon>
                                                <FontAwesomeIcon icon={faAddressCard} size="lg" />
                                          </ListItemIcon>
                                          <ListItemText primary="Credits" />
                                    </ListItem>
                              </Link>
                        </div>
                  </List>
                  <Divider />
                  <List>
                        <div>
                              <Link to="/notifications" style={{ color: 'inherit', textDecoration: 'none' }}>
                                    <ListItem button>
                                          <ListItemIcon>
                                                <FontAwesomeIcon icon={faBell} size="lg" />
                                          </ListItemIcon>
                                          <ListItemText primary="Notificaciones" />
                                    </ListItem>
                              </Link>
                        </div>
                  </List>
                  <Divider />
                  <List>
                        <div>
                              <ListItem button onClick={handleClickPartner}>
                                    <ListItemIcon>
                                          <GroupWorkIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Partners" />
                                    {openPartner ? <ExpandLess /> : <ExpandMore />}
                              </ListItem>
                              <Collapse in={openPartner} timeout="auto" unmountOnExit>
                                    <List>
                                          <Link to="/partners/" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <GroupWorkIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Partners" />
                                                </ListItem>
                                          </Link>
                                    </List>
                                    <List>
                                          <Link to="/partnerscategory" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <FontAwesomeIcon icon={faBalanceScaleLeft} size="lg" />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Categorías" />
                                                </ListItem>
                                          </Link>
                                    </List>
                              </Collapse>
                        </div>
                  </List>
                  <Divider />
                  <List>
                        <div>
                              <ListItem button onClick={handleClickReport}>
                                    <ListItemIcon>
                                          <AssessmentIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Informes" />
                                    {openReport ? <ExpandLess /> : <ExpandMore />}
                              </ListItem>
                              <Collapse in={openReport} timeout="auto" unmountOnExit>
                                    <List>
                                          <Link to="/report/manufacture" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <AccountBalanceIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Fabricantes" />
                                                </ListItem>
                                          </Link>
                                    </List>
                                    <List>
                                          <Link to="/report/serie" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <ListItem button className={props.classes.nested}>
                                                      <ListItemIcon>
                                                            <CameraEnhanceIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="Series" />
                                                </ListItem>
                                          </Link>
                                    </List>
                              </Collapse>
                        </div>
                  </List>
                  <Divider />
                  <List>
                        <div>
                              <Link to="/confighome/1" style={{ color: 'inherit', textDecoration: 'none' }}>
                                    <ListItem button>
                                          <ListItemIcon>
                                                <DashboardIcon />
                                          </ListItemIcon>
                                          <ListItemText primary="Configuracion home" />
                                    </ListItem>
                              </Link>
                        </div>
                  </List>
                  <Divider />
                  <List>
                        <div>
                              <Link to="/linkshome" style={{ color: 'inherit', textDecoration: 'none' }}>
                                    <ListItem button>
                                          <ListItemIcon>
                                                <LinkIcon />
                                          </ListItemIcon>
                                          <ListItemText primary="Links home" />
                                    </ListItem>
                              </Link>
                        </div>
                  </List>
                  <Divider />
                  <List>
                        <div>
                              <Link to="/popup" style={{ color: 'inherit', textDecoration: 'none' }}>
                                    <ListItem button>
                                          <ListItemIcon>
                                                <MaximizeIcon />
                                          </ListItemIcon>
                                          <ListItemText primary="Popups" />
                                    </ListItem>
                              </Link>
                        </div>
                  </List>
            </Drawer>
      );
}
