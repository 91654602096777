import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles,drawerWidth } from "../../layout/styles";
import CustomPaginationActionsTable from "../../table/Table"
import Filter from "../../filter/Filter"
import Form from "../../form/Form"
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {rentalService as service, countryService, cityService, rentalfamilyService, rolesService, rentalViewService} from "../../../services"
import ExploitIndividualData from '../../exploitdata/ExploitIndividualData';

const initElement = {
     name:"",
     description: "",
     web: "",
     mail: "",
     address: "",
     phone: "",
     latitude: null,
     longitude: null,
     family:{
        id:null,
        name:""
     },
     city:{
        id:null,
        name:""
     },
     country:{
        id:null,
        name:""
     },
     active: true,
     pay: false
  };





export default function FormRental() {
  const history = useHistory();
  let { id } = useParams();
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [objectform, setObjectform] = React.useState({...initElement});
  const [titleWindow, setTitleWindow] = React.useState(id && id > 0? 'Editar rental':'Nuevo rental');
  const [idRow, setIdrow] = React.useState(id);
  const [idSerie, setIdSerie] = React.useState(-1);
  const [cities,setCities] = React.useState([]);

  const [open, setOpen] = React.useState(true);
  const [rows,setRows] = React.useState([{}]);


  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };


  const [estructureform,setEstructureform] = React.useState([
         {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 6
          },
          {
           type:"input",
           name: "web",
           label: "Web",
           col: 6
          },
          {
           type:"input",
           name: "mail",
           label: "Email",
           col: 6,
           inputtype:"mail"
          },
          {
           type:"input",
           name: "phone",
           label: "Teléfono",
           col: 6,
          },
          {
           type:"input",
           name: "address",
           label: "Dirección",
           col: 6,
          },
          {
           type:"input",
           name: "latitude",
           label: "Latitud",
           col: 6,
          },
          {
           type:"input",
           name: "longitude",
           label: "Longitud",
           col: 6,
          },
          {
           type:"autocomplete",
           name: "family.id",
           label: "Familia",
           service: rentalfamilyService.combo,
           col: 6
          },
          {
           type:"autocomplete",
           name: "country.id",
           label: "País",
           service: countryService.combo,
           relationfield:'city.id',
           servicerelation:cityService.comboCountry,
           col: 6
         },
          {
           type:"autocomplete",
           name: "city.id",
           label: "Ciudad",
           options: Array(),
           col: 6
         },
         {
          type:"checkbox",
          name: "pay",
          label: "Esta suscrito",
          col: 6
        },
         {
           type:"textarea",
           name: "description",
           label: "Descripción",
           col: 12
          }
    ]);




  useEffect(() => {
      if(idRow && idRow > 0){
        searchOne();
      };

  }, []);

  const searchOne = function(){
    service.one(idRow,(data, error) => {
      if(data && data.data){
        var element = data.data;
        let objectedit = JSON.parse(JSON.stringify(objectform));
        objectedit['name']=element.name?element.name:"";
        objectedit['image']=element.image?element.image:"";
        objectedit['web']=element.web?element.web:"";
        objectedit['mail']=element.mail?element.mail:"";
        objectedit['address']=element.address?element.address:"";
        objectedit['phone']=element.phone?element.phone:"";
        objectedit['pay']=element.pay?element.pay:false;
        objectedit['latitude']=element.latitude?element.latitude:"";
        objectedit['longitude']=element.longitude?element.longitude:"";
        objectedit['description']=element.description?element.description:"";
        objectedit['family']=  {id:element.family && element.family.id?element.family.id:null, name: element.family && element.family.name?element.family.name:null};
        objectedit['city']=  {id:element.city && element.city.id?element.city.id:null, name: element.city && element.city.name?element.city.name:null};
        objectedit['country']=  {id:element.city && element.city.state && element.city.state.country  && element.city.state.country.id ?element.city.state.country.id:null, name: element.city && element.city.state && element.city.state.country  && element.city.state.country.name ?element.city.state.country.name:null};
        setObjectform(objectedit);

     }
    });
  }


  const cancelForm = (object) => {
      history.push('/rentals');
  }

  const saveForm = (object) => {
       if(isValid(object)) {
         var objToSave = cleanElementsBeforeSave(object);
         if (idRow && idRow > 0){
           objToSave['id'] = id;
           update(objToSave,false);
         } else {
           create(objToSave,false);
         }
       }

  }

  const create = (object,cleanSerie) => {
    service.create(object, (data, error) => {
        if(data){
          setIdrow(data.data.id)
          history.push('/rentals/'+data.data.id);
        }
    });
  }

  const update = (object,cleanSerie) => {
    service.edit(id,object, (data, error) => {
        if(data){

        }
    });
  }



  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      }
      if(!object['city']['id']){
        toast.notify("Debe indicar la ciudad", {position:'top', duration: 10000})
        return false;
      }
      if(!object['family']['id']){
        toast.notify("Debe indicar la familia", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }

  const cleanElementsBeforeSave = function(object){
    let newObj = JSON.parse(JSON.stringify(object));
    return newObj;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title={titleWindow} classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} className={classes.bodycontainer}>
              <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                  <Form objectform={objectform} fields={estructureform}  searchFilter={saveForm} cleanFilter={cancelForm} labelsave="Guardar" labelcancel="Cancelar"/>
              </Grid>
              <Grid item sm={12} xs={12} style={{ marginBottom: '10px'}}>
                {idRow && idRow > 0 && <ExploitIndividualData id={idRow} service={rentalViewService} /> }
              </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
