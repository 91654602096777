import React, { useState, useEffect }    from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles,drawerWidth } from "../../layout/styles";
import CustomPaginationActionsTable from "../../table/Table"
import Filter from "../../filter/Filter"
import {rentaluserService as service, rentalfamilyService,suscriptionService} from "../../../services"



  const initFilter =  {
      name : "",
      email: "",
      phone: "",
      suscription: null,
      familyrental: null
  };


export default function Users() {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const [page, setPage] = React.useState(0);
    const [filter, setFilter] = React.useState(initFilter);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const fieldstable = ["name","email","suscriptionType","suscriptionActive","suscriptionToken","familyRental.name"];
    const headersTable =  ["Nombre","Email","Tipo Suscripcion","Activa","Token stripe","Familia"];

    const [open, setOpen] = React.useState(true);
    const [rows,setRows] = React.useState([{}]);
    const [rowsTotal,setRowsTotal] = React.useState(0);

    const handleDrawerOpen = () => {
      setOpen(true);
    };
    const handleDrawerClose = () => {
      setOpen(false);
    };

    const changePageTable = (page,size) => {
      search(page,size);
    };


    const cleanFilter = (filter) => {
      console.log(filter);
      setFilter(filter);
      search(0,rowsPerPage);

    }

    const searchFilter = (filter) => {
      console.log(filter);
      setFilter(filter);
      search(0,rowsPerPage);
    }


    const createEstructureFilter =  [
         {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 4

         },
         {
          type:"input",
          name: "email",
          label: "Email",
          col: 4

        },
        {
          type:"input",
          name: "phone",
          label: "Tlf.",
          col: 4
         },
         {
           type:"autocomplete",
           name: "suscription",
           label: "Tipos suscripción",
           service: suscriptionService.combo,
           col: 4
          },
          {
            type:"autocomplete",
            name: "familyrental",
            label: "Familias de Rental",
            service: rentalfamilyService.combo,
            col: 4
           }
      ];


    const search = (page,size) => {
      setPage(page);
      setRowsPerPage(size);
      service.search(page,size,filter, (data, error) => {
          if(data){
            console.log(data);
            setRows(data.data);
            setRowsTotal(data.total);
          } else {
            setRows([{}]);
            setRowsTotal(0);
          }
      });
    }

    useEffect(() => {
      search(0,10);
    }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title="Usuarios con acceso a rentals" classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container style={{marginBottom: '50px'}}>
            <Filter cleanFilter={cleanFilter} searchFilter={searchFilter} filter={filter} estructure={createEstructureFilter} titlebutton={'Crear usuario rental'}  hiddenButton={true} />
          </Grid>
          <Grid container spacing={3} className={classes.bodycontainer}>
            <CustomPaginationActionsTable rows={rows} fields={fieldstable} fieldId={"id"} rowsPerPage={10} rowsTotal={rowsTotal} changePage={changePageTable} headers={headersTable} page={page}/>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
