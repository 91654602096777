import BaseService from './_base.service';

export const manufactureService = {
    page,
    search,
    combo,
    edit,
    create,
    one,
    remove,
    removeLogic
};

function page(page,size, callback) {
    return BaseService.get("/manufacture/page/"+page+"/"+size, callback)
}

function search(page,size,filter, callback) {
    return BaseService.post("/manufacture/page/"+page+"/"+size,filter, callback, true)
}

function combo(callback) {
    return BaseService.get("/manufacture/combo/", callback)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/manufacture/"+id, body, callback, false)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/manufacture/", body, callback, false)
}

function one(id,callback) {
    return BaseService.get("/manufacture/one/"+id, callback);
}

function remove(id,callback) {
    return BaseService.delete("/manufacture/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete("/manufacture/"+id+"/logic", callback);
}
