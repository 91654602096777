import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudForm from "../../crud/CrudForm"
import { useStyles,drawerWidth } from "../../layout/styles";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {blogService as service, categoryblogService, tagService, blogViewService} from "../../../services"
import ExploitIndividualData from '../../exploitdata/ExploitIndividualData';

const initElement = {
     title:"",
     categories:[],
     exceptBlog: "",
     html: "",
     seoTitle: "",
     seoDesc: "",
     seoImg: "",
     datepublic: null,
     legend: null,
     seoKeys:"",
     tags:[],
     featured: false
  };





export default function FormBlog() {
  let { id } = useParams();
  const classes = useStyles();
  const [objectform, setObjectform] = React.useState({...initElement});


  const [estructureform,setEstructureform] = React.useState([
         {
           type:"input",
           name: "title",
           label: "Titulo",
           col: 4
          },
          {
           type:"input",
           name: "datepublic",
           label: "Fecha de publicación",
           inputtype:"date",
           col: 4,
          },
          {
           type:"autocomplete",
           name: "categories",
           label: "Categorías",
           multiple:true,
           service: categoryblogService.combo,
           col: 4
          },
          {
           type:"textarea",
           name: "exceptBlog",
           label: "Descripcion breve",
           col: 12
          },
          {
           type:"editor",
           name: "html",
           label: "Contenido",
           col: 12,
          },
          {
           type:"input",
           name: "seoTitle",
           label: "Seo TItulo",
           col: 12,
          },
          {
           type:"textarea",
           name: "seoDesc",
           label: "Descripcion SEO",
           col: 12
          },
          {
           type:"input",
           name: "seoKeys",
           label: "SEO Keys",
           col: 6,
          },
          {
           type:"input",
           name: "legend",
           label: "Leyenda",
           col: 6,
          },
          {
           type:"checkbox",
           name: "featured",
           label: "Destacada",
           col: 6,
          },
          {
           type:"autocomplete",
           name: "tags",
           label: "Etiquetas",
           multiple:true,
           create:true,
           service: tagService.combo,
           col: 6
          },
          {
          type:"image",
          name: "seoImg",
          label: "Imagen",
          col: 2
          },


    ]);


  const recoveryElement = function(objectedit,element){
    objectedit['title']=element.title?element.title:"";
    objectedit['exceptBlog']=element.exceptBlog?element.exceptBlog:"";
    objectedit['html']=element.html?element.html:"";
    objectedit['seoTitle']=element.seoTitle?element.seoTitle:"";
    objectedit['seoDesc']=element.seoDesc?element.seoDesc:"";
    objectedit['seoKeys']=element.seoKeys?element.seoKeys:"";
    objectedit['legend']=element.legend?element.legend:"";
    objectedit['seoImg']=element.seoImg?element.seoImg:"";
    objectedit['datepublic']=element.datepublic?element.datepublic:"";
    objectedit['categories']=element.categories?element.categories:[];
    objectedit['featured']=element.featured?element.featured:false;
    objectedit['tags']=element.tags?element.tags:[];
    if(objectedit['datepublic'].length > 10){
      objectedit['datepublic'] =   objectedit['datepublic'];
    }
    return objectedit;
  }



  const isValid = function(object){
    debugger;
    
      if(!object['title']){
        toast.notify("Debe indicar el titulo", {position:'top', duration: 10000})
        return false;
      }
      if(!object['exceptBlog']){
        toast.notify("Debe indicar uan descripcion breve", {position:'top', duration: 10000})
        return false;
      }
      if(!object['html']){
        toast.notify("Debe indicar el contenido", {position:'top', duration: 10000})
        return false;
      }
      if(!object['datepublic']){
        toast.notify("Debe indicar la fecha de publicación", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }

  return (
    <CrudForm
        objectform = {objectform}
        estructureform = {estructureform}
        titleEdit = {'Editar Entrada'}
        titleNew = {'Nueva entrada'}
        urledit={"/blog/"}
        urlCancel={"/blog"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
        haveother={id && id > 0}
        othersform={<ExploitIndividualData id={id} service={blogViewService} /> }
      />
  );
}
