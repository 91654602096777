import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles, drawerWidth } from '../../layout/styles';
import CustomPaginationActionsTable from '../../table/Table';
import Filter from '../../filter/Filter';
import { orderproductService as service } from '../../../services';

const initFilter = {
      state: null,
};

export default function Orders() {
      const history = useHistory();
      const classes = useStyles();
      const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
      const [page, setPage] = React.useState(0);
      const [filter, setFilter] = React.useState(initFilter);
      const [rowsPerPage, setRowsPerPage] = React.useState(10);
      const fieldstable = ['product.title', 'from.name', 'pickupCountry.name', 'to.name', 'deliveryCountry.name', 'price', 'priceDelivery', 'total', 'state'];
      const headersTable = ['Producto', 'Vendedor', 'Pais origen', 'Comprador', 'Pais destino', 'Precio', 'Envio', 'Total', 'Estado'];

      const [open, setOpen] = React.useState(true);
      const [rows, setRows] = React.useState([{}]);
      const [rowsTotal, setRowsTotal] = React.useState(0);

      const handleDrawerOpen = () => {
            setOpen(true);
      };
      const handleDrawerClose = () => {
            setOpen(false);
      };

      const changePageTable = (page, size) => {
            search(page, size);
      };

      const cleanFilter = () => {
            search(0, rowsPerPage);
      };

      const searchFilter = (filter) => {
            search(0, rowsPerPage);
      };

      const createEstructureFilter = [
            {
                  type: 'autocomplete',
                  name: 'state',
                  label: 'Estado',
                  options: [
                        { key: 'PENDING_PAY', name: 'Pendiente de pago' },
                        { key: 'PAY_COMPLETE', name: 'Pagado' },
                        { key: 'IN_ROUTE', name: 'Enviado' },
                        { key: 'DELIVERED', name: 'Entregado' },
                        { key: 'COMPLETE', name: 'Completado' },
                        { key: 'CANCEL', name: 'Cancelado' },
                        { key: 'WITHISSUE', name: 'Con incidencia' },
                  ],
                  col: 4,
            },
      ];

      const search = (page, size) => {
            if (!filter['state'] || filter['state'] == '') {
                  filter['state'] = null;
            }
            setPage(page);
            setRowsPerPage(size);
            service.search(page, size, filter, (data, error) => {
                  if (data) {
                        console.log(data);
                        setRows(data.data);
                        setRowsTotal(data.total);
                  } else {
                        setRows([{}]);
                        setRowsTotal(0);
                  }
            });
      };

      useEffect(() => {
            search(0, 10);
      }, []);

      const deleteHandler = (id) => {
            service.remove(id, (data, error) => {
                  if (data) {
                        console.log(data);
                        search(page, rowsPerPage);
                  }
            });
      };

      const eventSelect = (id) => {
            console.log('Selecciona el id ' + id);
            history.push('/orders/' + id);
      };

      return (
            <div className={classes.root}>
                  <CssBaseline />
                  <Header title="Lentes y camaras en venta" classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
                  <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
                  <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        <Container maxWidth="lg" className={classes.container}>
                              <Grid container style={{ marginBottom: '50px' }}>
                                    <Filter cleanFilter={cleanFilter} searchFilter={searchFilter} filter={filter} estructure={createEstructureFilter} hiddenButton={true} />
                              </Grid>
                              <Grid container spacing={3} className={classes.bodycontainer}>
                                    <CustomPaginationActionsTable
                                          rows={rows}
                                          fields={fieldstable}
                                          fieldId={'id'}
                                          rowsPerPage={10}
                                          rowsTotal={rowsTotal}
                                          changePage={changePageTable}
                                          headers={headersTable}
                                          page={page}
                                          deleteaction={true}
                                          deleteactionHandler={deleteHandler}
                                          selectHandler={eventSelect}
                                    />
                              </Grid>
                              <Box pt={4}>
                                    <Copyright />
                              </Box>
                        </Container>
                  </main>
            </div>
      );
}
