import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';
import Moment from 'react-moment';
import moment from 'moment';
import { ArrowBack } from '@material-ui/icons';

export default function ExploitListContactData(props) {

    
    const [day,setDay] = React.useState([]);
    const [week,setWeek] = React.useState([]);
    const [month,setMonth] = React.useState([]);

    const [weekSelected, setWeekSelected] = React.useState(moment());
    const [monthSelected, setMonthSelected] = React.useState(moment());


    const [daycountry,setDaycountry] = React.useState([]);
    const [weekcountry,setWeekcountry] = React.useState([]);
    const [monthcountry,setMonthcountry] = React.useState([]);

    const [weekcountrySelected, setWeekcountrySelected] = React.useState(moment());
    const [monthcountrySelected, setMonthcountrySelected] = React.useState(moment());
    

    let service = props.service;


    const moreViewTodayGeneral = () => {
        service.moreViewTodayGeneral((data,error) => {
             if(data && data.data){
                setDay(data.data);
             }
        });
    }

    const moreViewWeekGeneral = (date) => {
        service.moreViewWeekGeneral(date,(data,error) => {
             if(data && data.data){
                setWeek(data.data);
             }
        });
    }

    const moreViewMonthGeneral = (month,year) => {
        service.moreViewMonthGeneral(month,year,(data,error) => {
             if(data && data.data){
                setMonth(data.data);
             }
        });
    }

    /** COUNTRY */


    const moreViewCountryTodayGeneral = () => {
        service.moreViewCountryTodayGeneral((data,error) => {
             if(data && data.data){
                debugger;
                setDaycountry(data.data);
             }
        });
    }

    const moreViewCountryWeekGeneral = (date) => {
        service.moreViewCountryWeekGeneral(date,(data,error) => {
             if(data && data.data){
                setWeekcountry(data.data);
             }
        });
    }


    const moreViewCountryMonthGeneral = (month,year) => {
        service.moreViewCountryMonthGeneral(month,year,(data,error) => {
             if(data && data.data){
                setMonthcountry(data.data);
             }
        });
    }

    

    useEffect(() => {
        moreViewTodayGeneral();
        moreViewWeekGeneral(weekSelected.format("YYYYMMDD"));
        moreViewMonthGeneral(monthSelected.format("MM"), monthSelected.format("YYYY"));

        moreViewCountryTodayGeneral();
        moreViewCountryWeekGeneral(weekcountrySelected.format("YYYYMMDD"));
        moreViewCountryMonthGeneral(monthcountrySelected.format("MM"), monthcountrySelected.format("YYYY"));

    }, []);

    const prevWeek = () => {
        var prev = weekSelected.subtract(1,'week');
        setWeekSelected(prev);
        moreViewWeekGeneral(prev.format("YYYYMMDD"));
    }


    const nextWeek = () => {
        var prev = weekSelected.add(1,'week');
        setWeekSelected(prev);
        moreViewWeekGeneral(prev.format("YYYYMMDD"));
    }



    const prevMonth = () => {
        var prev = monthSelected.subtract(1,'month');
        setMonthSelected(prev);
        moreViewMonthGeneral(prev.format("MM"), prev.format("YYYY"));
    }


    const nextMonth = () => {
        var prev = monthSelected.add(1,'month');
        setMonthSelected(prev);
        moreViewMonthGeneral(prev.format("MM"), prev.format("YYYY"));
    }


     /** COUNTRY */
    const prevWeekcountry = () => {
        var prev = weekcountrySelected.subtract(1,'week');
        setWeekcountrySelected(prev);
        moreViewCountryWeekGeneral(prev.format("YYYYMMDD"));
    }

     const nextWeekcountry = () => {
        var prev = weekcountrySelected.add(1,'week');
        setWeekcountrySelected(prev);
        moreViewCountryWeekGeneral(prev.format("YYYYMMDD"));
    }

    const prevMonthcountry = () => {
        var prev = monthcountrySelected.subtract(1,'month');
        setMonthcountrySelected(prev);
        moreViewCountryMonthGeneral(prev.format("MM"), prev.format("YYYY"));
    }


    const nextMonthcountry = () => {
        var prev = monthcountrySelected.add(1,'month');
        setMonthcountrySelected(prev);
        moreViewCountryMonthGeneral(prev.format("MM"), prev.format("YYYY"));
    }



    return (
        <Grid container spacing={3}>

            <Grid item sm={12} xs={12} style={{textAlign:'center', marginBottom: '10px'}}>
                <h3>ESTADISTICAS CONTACTOS</h3>
            </Grid>

            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>Más CONTACTOS HOY (En total)</h3>
                    </Grid>
                    {day.length  > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={day}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Nº veces"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>

            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>Más CONTACTOS a la semana en total</h3>
                    </Grid>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginRight: "10px", cursor: "pointer", marginBottom: "-5px"}} onClick={prevWeek} /> 
                        <label>Semana {weekSelected.format("W")} ( {weekSelected.startOf('week').format("DD-MM-YYYY")} - {weekSelected.endOf('week').format("DD-MM-YYYY")} )</label>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginLeft: "10px", cursor: "pointer", transform: "rotate(180deg)", marginBottom: "-5px"}} onClick={nextWeek} /> 
                    </Grid>
                    {week.length  > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={week}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Nº veces"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>
            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>Más CONTACTOS al més</h3>
                    </Grid>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginRight: "10px", cursor: "pointer", marginBottom: "-5px"}} onClick={prevMonth} /> 
                        <label>Més {monthSelected.format("MM")} - {monthSelected.format('YYYY')}</label>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginLeft: "10px", cursor: "pointer", transform: "rotate(180deg)", marginBottom: "-5px"}} onClick={nextMonth} /> 
                    </Grid>
                    {month.length  > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={month}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Nº veces"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>
        

            <Grid item sm={12} xs={12} style={{textAlign:'center', marginBottom: '10px'}}>
                <h3>ESTADISTICAS CONTACTOS POR PAIS</h3>
            </Grid>

            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>PAISES CON MAS CONTACTOS HOY (En total)</h3>
                    </Grid>
                    {daycountry.length  > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={daycountry}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Nº veces"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>
            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>PAISES CON MÁS CONTACTOS A LA SEMANA</h3>
                    </Grid>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginRight: "10px", cursor: "pointer", marginBottom: "-5px"}} onClick={prevWeekcountry} /> 
                        <label>Semana {weekcountrySelected.format("W")} ( {weekcountrySelected.startOf('week').format("DD-MM-YYYY")} - {weekcountrySelected.endOf('week').format("DD-MM-YYYY")} )</label>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginLeft: "10px", cursor: "pointer", transform: "rotate(180deg)", marginBottom: "-5px"}} onClick={nextWeekcountry} /> 
                    </Grid>
                    {weekcountry.length  > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={weekcountry}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Nº veces"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>

            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>PAISES CON MAS CONTACTOS EN EL MES</h3>
                    </Grid>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginRight: "10px", cursor: "pointer", marginBottom: "-5px"}} onClick={prevMonthcountry} /> 
                        <label>Més {monthcountrySelected.format("MM")} - {monthcountrySelected.format('YYYY')}</label>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginLeft: "10px", cursor: "pointer", transform: "rotate(180deg)", marginBottom: "-5px"}} onClick={nextMonthcountry} /> 
                    </Grid>
                    {monthcountry.length  > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={monthcountry}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Nº veces"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>
            
        </Grid>
    );

}