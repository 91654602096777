import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudForm from "../../crud/CrudForm"
import { useStyles,drawerWidth } from "../../layout/styles";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {bannersService as service, bannerpositionService} from "../../../services"

const initElement = {
     name:"",
     title:"",
     image: "",
     url: "",
     start: "",
     finish: "",
     position: {id:null,name:""},
  };


export default function FormBanner() {
  let { id } = useParams();
  const classes = useStyles();
  const [objectform, setObjectform] = React.useState({...initElement});


  const [estructureform,setEstructureform] = React.useState([
         {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 4
          },
          {
            type:"input",
            name: "title",
            label: "Titulo",
            col: 4
           },
           {
             type:"input",
             name: "url",
             label: "Link",
             col: 4
            },
          {
           type:"input",
           name: "start",
           label: "Fecha de desde",
           inputtype:"date",
           col: 4,
          },
          {
           type:"input",
           name: "finish",
           label: "Fecha de hasta",
           inputtype:"date",
           col: 4,
          },
          {
           type:"autocomplete",
           name: "position.id",
           label: "Posición",
           service: bannerpositionService.combo,
           col: 4
          },
          {
          type:"image",
          name: "image",
          label: "Imagen",
          col: 2
          },


    ]);


  const recoveryElement = function(objectedit,element){
    objectedit['name']=element.name?element.name:"";
    objectedit['title']=element.title?element.title:"";
    objectedit['image']=element.image?element.image:"";
    objectedit['url']=element.url?element.url:"";
    objectedit['start']=element.start?element.start:"";
    objectedit['finish']=element.finish?element.finish:"";
    objectedit['position']=element.position?element.position:"";
    return objectedit;
  }



  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el titulo", {position:'top', duration: 10000})
        return false;
      }
      if(!object['finish']){
        toast.notify("Debe indicar la fecha hasta", {position:'top', duration: 10000})
        return false;
      }
      if(!object['url']){
        toast.notify("Debe indicar la url", {position:'top', duration: 10000})
        return false;
      }
      if(!object['image']){
        toast.notify("Debe indicar la imagen", {position:'top', duration: 10000})
        return false;
      }
      if(!object['start']){
        toast.notify("Debe indicar la fecha desde", {position:'top', duration: 10000})
        return false;
      }
      if(!object['position'] || !object['position']['id']){
        toast.notify("Debe indicar la posición", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }

  return (
    <CrudForm
        objectform = {objectform}
        estructureform = {estructureform}
        titleEdit = {'Editar banner'}
        titleNew = {'Nuevo banner'}
        urledit={"/banners/"}
        urlCancel={"/banners"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />
  );
}
