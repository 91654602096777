import BaseService from './_base.service';

export const dealerService = {
    page,
    search,
    edit,
    create,
    one,
    remove,
    removeLogic
};

function page(page,size, callback) {
    return BaseService.get("/dealer/page/"+page+"/"+size, callback, true)
}

function search(page,size,filter, callback) {
    return BaseService.post("/dealer/page/"+page+"/"+size,filter, callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/dealer/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/dealer/", body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/dealer/one/"+id, callback);
}

function remove(id,callback) {
    return BaseService.delete("/dealer/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete("/dealer/"+id+"/logic", callback);
}
