import React, { useState, useEffect }   from 'react';
import { useStyles,drawerWidth } from "../../layout/styles";
import { useParams,useHistory } from "react-router-dom";
import {manufactureSusService as service, manufactureService} from "../../../services"
import CrudTable from '../../crud/CrudTable';
import LocalMallIcon from '@material-ui/icons/LocalMall';

const initFilter =  {
    title: "",
    name : ""
};

export default function ManufactureSus() {
  const classes = useStyles();
  const fieldstable = ["manufacture.name","endSuscription"];
  const headersTable =  ["Fabricante","Fecha fin suscripción"];
  const history = useHistory();


  const createEstructureFilter =  [

      {
         type:"autocomplete",
         name: "manufacture",
         label: "Fabricante",
         service: manufactureService.combo,
         col: 4
        }
    ];


    const handleEditLens = (sus) =>{
        history.push("/manufacturesus/serie/"+sus.id+"/"+sus.manufacture.id);
    }

  return (
     <CrudTable
     service={service}
     titlePage={"Suscripciones Fabricantes"}
     titleButton={"Añadir suscripcion"}
     urledit={"/manufacturesus/"}
     urlnew={"/manufacturesus/new/"}
     filter={initFilter}
     fieldstable={fieldstable}
     headersTable={headersTable}
     estructureForm={createEstructureFilter}
     moreActions={[{"handle":handleEditLens, icon:<LocalMallIcon />}]}
        
 />
  );
}
