import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudForm from "../../crud/CrudForm"
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {lengthService as service} from "../../../services"

const initElement = {
     name:"",
     lengthMM: null,
     lengthInches: null,
     lengthMMND: null,
     lengthInchesND: null,

  };


export default function FormLength() {
  const classes = useStyles();
  const [objectform, setObjectform] = React.useState({...initElement});


  const estructureform =  [
       {
         type:"input",
         name: "name",
         label: "Nombre",
         col: 6
        },
        {
         type:"input",
         name: "lengthMM",
         label: "Longitud mm",
         col: 6,
         inputtype:"number",
         step:0.01
        },
        {
         type:"input",
         name: "lengthInches",
         label: "Longitud pulgdas",
         col: 6,
         inputtype:"number",
         step:0.01
       },
        {
         type:"input",
         name: "lengthMMND",
         label: "Longitud mm ND",
         col: 6,
         inputtype:"number",
         step:0.01
        },
        {
         type:"input",
         name: "lengthInchesND",
         label: "Longitud Pulgadas ND",
         col: 6,
         inputtype:"number",
         step:0.01
        }
  ];

  const recoveryElement = function(objectedit,element){
    objectedit['name']=element.name?element.name:"";
    objectedit['lengthMM']=element.lengthMM?element.lengthMM:"";
    objectedit['lengthInches']=element.lengthInches?element.lengthInches:"";
    objectedit['lengthMMND']=element.lengthMMND?element.lengthMMND:"";
    objectedit['lengthInchesND']=element.lengthInchesND?element.lengthInchesND:"";
    return objectedit;
  }


  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      }
      if(!object['lengthMM']){
        toast.notify("Debe indicar la longitud en mm", {position:'top', duration: 10000})
        return false;
      }
      if(!object['lengthInches']){
        toast.notify("Debe indicar la longitud en pulgadas", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }

  return (
    <CrudForm
        objectform = {objectform}
        estructureform = {estructureform}
        titleEdit = {'Editar Tamaño'}
        titleNew = {'Nueva Tamaño'}
        urledit={"/lens/length/"}
        urlCancel={"/lens/length"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />
  );
}
