import React, { useState, useEffect }   from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudTable from "../../crud/CrudTable"
import {blogService as service, categoryblogService, tagService, blogViewService} from "../../../services"
import ExploitListData from '../../exploitdata/ExploitListData';

const initFilter =  {
    idcategory:null,
    idtag:null,
    text:"",
    title: "",
    before : "",
    after : "",
};


export default function Blog() {
  const [filter, setFilter] = React.useState(initFilter);
  const fieldstable = ["title","exceptBlog","datepublic","featured","numTotal", "numList","numView"];
  const headersTable =  ["Titulo","Descripcion","Fecha", "Destacada", "Vistas Totales", "Vis. Listado", "Vis. Perfil"];

  const createEstructureFilter =  [
       {
        type:"autocomplete",
        name: "idcategory",
        label: "Categoría",
        service: categoryblogService.combo,
        col: 3
       },
       {
        type:"autocomplete",
        name: "idtag",
        label: "Etiquetas",
        service: tagService.combo,
        col: 3
       },
       {
         type:"input",
         name: "title",
         label: "Titulo",
         col: 3
        },
        {
          type:"input",
          name: "text",
          label: "Texto",
          col: 3
         },
       {
         type:"input",
         name: "before",
         label: "Desde:",
         inputtype: "date",
         col: 3
        },
       {
         type:"input",
         name: "after",
         label: "Hasta:",
         inputtype: "date",
         col: 3
        },
    ];


  return (
      <CrudTable
            service={service}
            titlePage={"Blog"}
            titleButton={"Añadir entrada"}
            urledit={"/blog/"}
            urlnew={"/blog/new/"}
            filter={initFilter}
            fieldstable={fieldstable}
            headersTable={headersTable}
            estructureForm={createEstructureFilter}
            haveother={true}
            othersform={<ExploitListData service={blogViewService} />}
        />
  
  );
}
