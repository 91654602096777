import React, { useState, useEffect }    from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useStyles,drawerWidth } from "../../layout/styles";
import CustomPaginationActionsTable from "../../table/Table"
import Filter from "../../filter/Filter"
import {serierankingDayService as service, serieService} from "../../../services"
import CrudEmpty from '../../crud/CrudEmpty';
import { Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from 'recharts';


const moment = require("moment");
moment().locale("es");


  const initFilter =  {
      serie : null
  };


export default function SerieReport() {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [page, setPage] = React.useState(0);
  const [filter, setFilter] = React.useState(initFilter);
  const [tab, setTab] = React.useState(1);
  const [day, setDay] = React.useState(moment().add(-1, 'days').format('YYYY-MM-DD'));
  const [rowsVisitDay,setRowsVisitDay] = React.useState<any>([]);
  const [rowsSearchDay,setRowsSearchDay] = React.useState<any>([]);
  const [rowsVisitDayGraph,setRowsVisitDayGraph] = React.useState<any>([]);
  const [rowsSearchDayGraph,setRowsSearchDayGraph] = React.useState<any>([]);
  const [rowsVisitWeek,setRowsVisitWeek] = React.useState<any>([]);
  const [rowsSearchWeek,setRowsSearchWeek] = React.useState<any>([]);
  const [rowsVisitWeekGraph,setRowsVisitWeekGraph] = React.useState<any>([]);
  const [rowsSearchWeekGraph,setRowsSearchWeekGraph] = React.useState<any>([]);


  const [rowsVisitMonth,setRowsVisitMonth] = React.useState<any>([]);
  const [rowsSearchMonth,setRowsSearchMonth] = React.useState<any>([]);
  const [rowsVisitMonthGraph,setRowsVisitMonthGraph] = React.useState<any>([]);
  const [rowsSearchMonthGraph,setRowsSearchMonthGraph] = React.useState<any>([]);



  const cleanFilter = (filter) => {
    console.log(filter);
    setFilter(filter);
    search(filter,day);

  }

  const searchFilter = (filter) => {
    console.log(filter);
    setFilter(filter);
    var dayAux = moment().add(-1, 'days').format('YYYY-MM-DD');
    setDay(dayAux)
    search(filter,dayAux);
  }

  const search = (filter,day) => {
        setRowsVisitDay([]);
        setRowsSearchDay([]);
        setRowsVisitDayGraph([]);
        setRowsSearchDayGraph([]);
        setRowsVisitWeek([]);
        setRowsSearchWeek([]);
        setRowsVisitWeekGraph([]);
        setRowsSearchWeekGraph([]);
        setRowsVisitMonth([]);
        setRowsSearchMonth([]);
        setRowsVisitMonthGraph([]);
        setRowsSearchMonthGraph([]);

        var filterRankinDay= {from:day+"T00:00:00Z",to:day+"T23:59:59Z",search:false, day:true, week:false, month:false}

        service.allFilter(filterRankinDay, (data, error) => {
          if(data){
            setRowsVisitDay(data.data);
          } else {
            setRowsVisitDay([]);
          }
        });

        var filterRankinDaySearch= {from:day+"T00:00:00Z",to:day+"T23:59:59Z",search:true, day:true, week:false, month:false}

        service.allFilter(filterRankinDaySearch, (data, error) => {
          if(data){
            setRowsSearchDay(data.data);
          } else {
            setRowsSearchDay([]);
          }
        });

        
        var filterRankinWeek= {from:moment(day).add(-7,'days').format("YYYY-MM-DD")+"T00:00:00Z",to:day+"T23:59:59Z",search:false, week:true, day:false, month:false}

        service.allFilter(filterRankinWeek, (data, error) => {
          if(data){
            setRowsVisitWeek(data.data);
          } else {
            setRowsVisitWeek([]);
          }
        });

        var filterRankinWeekSearch= {from:moment(day).add(-7,'days').format("YYYY-MM-DD")+"T00:00:00Z",to:day+"T23:59:59Z",search:true, week:true, day:false, month:false}

        service.allFilter(filterRankinWeekSearch, (data, error) => {
          if(data){
            setRowsSearchWeek(data.data);
          } else {
            setRowsSearchWeek([]);
          }
        });


        var filterRankinMonth= {from:moment(day).add(-1,'months').format("YYYY-MM-DD")+"T00:00:00Z",to:day+"T23:59:59Z",search:false, month:true, week:false, day:false}

        service.allFilter(filterRankinMonth, (data, error) => {
          if(data){
            setRowsVisitMonth(data.data);
          } else {
            setRowsVisitMonth([]);
          }
        });

        var filterRankinMonthSearch= {from:moment(day).add(-1,'months').format("YYYY-MM-DD")+"T00:00:00Z",to:day+"T23:59:59Z",search:true, month:true, week:false, day:false}

        service.allFilter(filterRankinMonthSearch, (data, error) => {
          if(data){
            setRowsSearchMonth(data.data);
          } else {
            setRowsSearchMonth([]);
          }
        });


    if(filter.serie){

      var filterRankinDayVisitGraph= {serie: filter.serie, from:moment(day).add(-7,'days').format("YYYY-MM-DD")+"T00:00:00Z",to:day+"T23:59:59Z",search:false, day:true, month:false, week:false}

      service.allFilter(filterRankinDayVisitGraph, (data, error) => {
        if(data){
          setRowsVisitDayGraph(data.data);
        } else {
          setRowsVisitDayGraph([]);
        }
      });

      var filterRankinDaySearchGraph= {serie: filter.serie, from:moment(day).add(-7,'days').format("YYYY-MM-DD")+"T00:00:00Z",to:day+"T23:59:59Z",search:true, day:true, month:false, week:false}

      service.allFilter(filterRankinDaySearchGraph, (data, error) => {
        if(data){
          setRowsSearchDayGraph(data.data);
        } else {
          setRowsSearchDayGraph([]);
        }
      });

      var filterRankinWeekVisitGraph= {serie: filter.serie, from:moment(day).add(-7,'weeks').format("YYYY-MM-DD")+"T00:00:00Z",to:day+"T23:59:59Z",search:false, week:true, month:false, day:false}

      service.allFilter(filterRankinWeekVisitGraph, (data, error) => {
        if(data){
          setRowsVisitWeekGraph(data.data);
        } else {
          setRowsVisitWeekGraph([]);
        }
      });

      var filterRankinWeekSearchGraph= {serie: filter.serie, from:moment(day).add(-7,'weeks').format("YYYY-MM-DD")+"T00:00:00Z",to:day+"T23:59:59Z",search:true, week:true, month:false, day:false}

      service.allFilter(filterRankinWeekSearchGraph, (data, error) => {
        if(data){
          setRowsSearchWeekGraph(data.data);
        } else {
          setRowsSearchWeekGraph([]);
        }
      });



      var filterRankinMonthVisitGraph= {serie: filter.serie, from:moment(day).add(-7,'months').format("YYYY-MM-DD")+"T00:00:00Z",to:day+"T23:59:59Z",search:false, month:true, week:false, day:false}

      service.allFilter(filterRankinMonthVisitGraph, (data, error) => {
        if(data){
          setRowsVisitMonthGraph(data.data);
        } else {
          setRowsVisitMonthGraph([]);
        }
      });

      var filterRankinMonthSearchGraph= {serie: filter.serie, from:moment(day).add(-7,'months').format("YYYY-MM-DD")+"T00:00:00Z",to:day+"T23:59:59Z",search:true, month:true, week:false, day:false}

      service.allFilter(filterRankinMonthSearchGraph, (data, error) => {
        if(data){
          setRowsSearchMonthGraph(data.data);
        } else {
          setRowsSearchMonthGraph([]);
        }
      });

    }
  }


  const createEstructureFilter =  [
       {
         type:"autocomplete",
         name: "serie",
         label: "Serie",
         service: serieService.combo,
         col: 4
        }
    ];


    const prevDay = () => {
      var dayAux = moment(day).add(-1,'days').format('YYYY-MM-DD');
      setDay(dayAux);
      search(filter,dayAux);
  }


  const nextDay = () => {
    if(moment(day).add(1,'days') < moment().add(-1,'days')) {
      var dayAux = moment(day).add(1,'days').format('YYYY-MM-DD');
      setDay(dayAux);
      search(filter,dayAux);
    }
  }


  useEffect(() => {
    search(filter,day);
  }, []);

  const paintCell = {
    diffranking: (value,object) => {
      if (value) {
        if(value < 0){
          return <div style={{color:"green", display:"flex", alignItems:"center"}}><ArrowUpwardIcon />{value * -1 }</div>
        } else {
          return <div style={{color:"red", display:"flex", alignItems:"center"}}><ArrowDownwardIcon />{value}</div>
        }
      } else {
        return <> -- </>
      }
    }
  }

  const searchSerie = (id, data) => {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
          const element = data[key];
          if(element["serie"]["id"] == id){
              return <div style={{display: "flex", border: "1px solid #ccc",padding: "1em", borderRadius: "6px", alignItems:"center"}}>La seríe <b style={{padding: "0 0.5em"}}>{element["serie"]["name"]}</b> ocupa el puesto <b style={{padding: "0 0.5em"}}>{element["ranking"]}</b> {paintCell['diffranking'](element["diffranking"],element)}</div>
          }
        
      }
    }

    return <></>
  }


  return (
    <CrudEmpty title="Estadisticas Serie">
            <div>
                <Grid container style={{marginBottom: '50px'}}>
                    <Filter cleanFilter={cleanFilter} searchFilter={searchFilter} filter={filter} estructure={createEstructureFilter}  hiddenButton={true} expanded={true}/>
                </Grid>

                <Grid container style={{marginBottom: '50px'}}>
                    <Grid item sm={12} xs={12} style={{textAlign:'center', marginBottom: '10px'}}>
                        <b style={{paddingRight:"1em"}}>Día seleccionado: </b>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginRight: "10px", cursor: "pointer", marginBottom: "-5px"}} onClick={prevDay} /> 
                        <label> {moment(day).format("DD/MM/YYYY")}</label>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginLeft: "10px", cursor: "pointer", transform: "rotate(180deg)", marginBottom: "-5px"}} onClick={nextDay} /> 
                    </Grid>
                  </Grid>

                <Grid container style={{marginBottom: '50px'}}>
                        <Grid item sm={2} style={{textAlign:'left', marginTop: '50px', borderRight: "1px solid #ccc"}}>
                                <Button variant="outlined" color="primary" style={{marginLeft:'30px', width: "90%", marginBottom:"1em"}} onClick={(evt) => setTab(1)}> Ranking serie día visitas</Button>
                                <Button variant="outlined" color="primary" style={{marginLeft:'30px', width: "90%", marginBottom:"1em"}} onClick={(evt) => setTab(2)}> Ranking serie día búsquedas</Button>
                                {rowsVisitDayGraph.length > 0 &&  <Button variant="outlined" color="primary" style={{marginLeft:'30px', width: "90%", marginBottom:"1em"}} onClick={(evt) => setTab(3)}> Evolución de las visitas ultimos 7 días</Button> }
                                {rowsSearchDayGraph.length > 0 &&  <Button variant="outlined" color="primary" style={{marginLeft:'30px', width: "90%", marginBottom:"1em"}} onClick={(evt) => setTab(4)}> Evolución de las búsquedas ultimos 7 días</Button> }
                                <Button variant="outlined" color="primary" style={{marginLeft:'30px', width: "90%", marginBottom:"1em"}} onClick={(evt) => setTab(5)}> Ranking serie semana visitas</Button>
                                <Button variant="outlined" color="primary" style={{marginLeft:'30px', width: "90%", marginBottom:"1em"}} onClick={(evt) => setTab(6)}> Ranking serie semana búsquedas</Button>
                                {rowsVisitWeekGraph.length > 0 &&  <Button variant="outlined" color="primary" style={{marginLeft:'30px', width: "90%", marginBottom:"1em"}} onClick={(evt) => setTab(7)}> Evolución de las visitas ultimos 7 semana</Button> }
                                {rowsSearchWeekGraph.length > 0 &&  <Button variant="outlined" color="primary" style={{marginLeft:'30px', width: "90%", marginBottom:"1em"}} onClick={(evt) => setTab(8)}> Evolución de las búsquedas ultimos 7 semana</Button> }
                                <Button variant="outlined" color="primary" style={{marginLeft:'30px', width: "90%", marginBottom:"1em"}} onClick={(evt) => setTab(9)}> Ranking serie mes visitas</Button>
                                <Button variant="outlined" color="primary" style={{marginLeft:'30px', width: "90%", marginBottom:"1em"}} onClick={(evt) => setTab(10)}> Ranking serie mes búsquedas</Button>
                                {rowsVisitMonthGraph.length > 0 &&  <Button variant="outlined" color="primary" style={{marginLeft:'30px', width: "90%", marginBottom:"1em"}} onClick={(evt) => setTab(11)}> Evolución de las visitas ultimos 7 meses</Button> }
                                {rowsSearchMonthGraph.length > 0 &&  <Button variant="outlined" color="primary" style={{marginLeft:'30px', width: "90%", marginBottom:"1em"}} onClick={(evt) => setTab(12)}> Evolución de las búsquedas ultimos 7 meses</Button> }

                        </Grid>
                        <Grid item sm={10} style={{textAlign:'left', marginTop: '50px'}}>
                             {tab == 1 && 
                                <Grid container style={{marginBottom: '50px', padding:"1em"}}>
                                        <h2>Ranking serie día visitas</h2>

                                    {filter.serie && filter.serie > 0 && rowsVisitDay.length > 0 &&
                                      <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', paddingLeft:"2em"}}> {searchSerie(filter.serie, rowsVisitDay)}</Grid>
                                    }
                                        <CustomPaginationActionsTable rows={rowsVisitDay} fields={["ranking","serie.name","ammount","diffranking"]} fieldId={"id"} headers={["#","Serie","Visitas","Tendencia"]} deleteaction={false} editable={false} hiddenpagination={true} paintCell={paintCell}/>
                                </Grid>
                              }
                              {tab == 2 && 
                                <Grid container style={{marginBottom: '50px', padding:"1em"}}>
                                        <h2>Ranking serie día búsquedas</h2>

                                    {filter.serie && filter.serie > 0 && rowsSearchDay.length > 0 &&
                                      <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', paddingLeft:"2em"}}> {searchSerie(filter.serie, rowsSearchDay)}</Grid>
                                    }
                                        <CustomPaginationActionsTable rows={rowsSearchDay} fields={["ranking","serie.name","ammount","diffranking"]} fieldId={"id"} headers={["#","Serie","Búsquedas","Tendencia"]} deleteaction={false} editable={false} hiddenpagination={true} paintCell={paintCell}/>
                                </Grid>
                              }
                             
                              {tab == 3 && rowsVisitDayGraph.length > 0 && 
                                <Grid container style={{marginBottom: '50px', padding:"1em"}}>
                                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', paddingLeft:"2em"}}>
                                          <h2> Evolución de las visitas ultimos 7 días</h2>
                                    </Grid>
                                    <Grid item sm={12} xs={12} style={{textAlign:'center', marginBottom: '10px'}}>
                                      <LineChart width={700} height={250} data={rowsVisitDayGraph}>
                                              <CartesianGrid strokeDasharray="3 3" />
                                              <XAxis dataKey="dateLabel" />
                                              <YAxis />
                                              <Tooltip />
                                              <Legend />
                                              <Line dataKey="ranking" stroke="#8884d8" name="Posición ranking"/>
                                              <Line dataKey="ammount" stroke="#82ca9d" name="Visitas"/>
                                      </LineChart>
                                    </Grid>
                                  </Grid>
                              
                              }

                              {tab == 4 && rowsVisitDayGraph.length > 0 && 
                                <Grid container style={{marginBottom: '50px', padding:"1em"}}>
                                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', paddingLeft:"2em"}}>
                                          <h2> Evolución de las visitas ultimos 7 días</h2>
                                    </Grid>
                                    <Grid item sm={12} xs={12} style={{textAlign:'center', marginBottom: '10px'}}>
                                      <LineChart width={700} height={250} data={rowsSearchDayGraph}>
                                              <CartesianGrid strokeDasharray="3 3" />
                                              <XAxis dataKey="dateLabel" />
                                              <YAxis />
                                              <Tooltip />
                                              <Legend />
                                              <Line dataKey="ranking" stroke="#8884d8" name="Posición"/>
                                              <Line dataKey="ammount" stroke="#82ca9d" name="Búsquedas"/>
                                      </LineChart>
                                    </Grid>
                                  </Grid>
                              
                              }


                             {tab == 5 && 
                                <Grid container style={{marginBottom: '50px', padding:"1em"}}>
                                        <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', paddingLeft:"2em"}}>
                                              <h2>Ranking serie semana visitas</h2>
                                        </Grid>
                                        {rowsVisitWeek && rowsVisitWeek[0] && rowsVisitWeek[0]["dateDay"] && <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', paddingLeft:"2em"}}>
                                          <h4>Datos pertenecientes desde {moment(rowsVisitWeek[0]["dateDay"]).add(-7, "days").format("DD/MM/YYYY")} al {moment(rowsVisitWeek[0]["dateDay"]).format("DD/MM/YYYY")}</h4>
                                        </Grid> }
                                        {filter.serie && filter.serie > 0 && rowsVisitWeek.length > 0 &&
                                          <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', paddingLeft:"2em"}}> {searchSerie(filter.serie, rowsVisitWeek)}</Grid>
                                        }
                                        <CustomPaginationActionsTable rows={rowsVisitWeek} fields={["ranking","serie.name","ammount","diffranking"]} fieldId={"id"} headers={["#","Serie","Visitas","Tendencia"]} deleteaction={false} editable={false} hiddenpagination={true} paintCell={paintCell}/>
                                </Grid>
                              }
                              {tab == 6 && 
                                <Grid container style={{marginBottom: '50px', padding:"1em"}}>
                                        <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', paddingLeft:"2em"}}>
                                             <h2>Ranking serie semana búsquedas</h2>
                                        </Grid>
                                        {rowsSearchWeek && rowsSearchWeek[0] && rowsSearchWeek[0]["dateDay"] && <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', paddingLeft:"2em"}}>
                                          <h4>Datos pertenecientes desde {moment(rowsSearchWeek[0]["dateDay"]).add(-7, "days").format("DD/MM/YYYY")} al {moment(rowsSearchWeek[0]["dateDay"]).format("DD/MM/YYYY")}</h4>
                                        </Grid> }
                                        {filter.serie && filter.serie > 0 && rowsSearchWeek.length > 0 &&
                                          <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', paddingLeft:"2em"}}> {searchSerie(filter.serie, rowsSearchWeek)}</Grid>
                                        }
                                        <CustomPaginationActionsTable rows={rowsSearchWeek} fields={["ranking","serie.name","ammount","diffranking"]} fieldId={"id"} headers={["#","Serie","Búsquedas","Tendencia"]} deleteaction={false} editable={false} hiddenpagination={true} paintCell={paintCell}/>
                                </Grid>
                              }
                             
                              {tab == 7 && rowsVisitDayGraph.length > 0 && 
                                <Grid container style={{marginBottom: '50px', padding:"1em"}}>
                                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', paddingLeft:"2em"}}>
                                          <h2> Evolución de las visitas ultimos 7 semanas</h2>
                                    </Grid>
                                    <Grid item sm={12} xs={12} style={{textAlign:'center', marginBottom: '10px'}}>
                                      <LineChart width={700} height={250} data={rowsVisitWeekGraph}>
                                              <CartesianGrid strokeDasharray="3 3" />
                                              <XAxis dataKey="dateLabel" />
                                              <YAxis />
                                              <Tooltip />
                                              <Legend />
                                              <Line dataKey="ranking" stroke="#8884d8" name="Posición ranking"/>
                                              <Line dataKey="ammount" stroke="#82ca9d" name="Visitas"/>
                                      </LineChart>
                                    </Grid>
                                  </Grid>
                              
                              }

                              {tab == 8 && rowsVisitDayGraph.length > 0 && 
                                <Grid container style={{marginBottom: '50px', padding:"1em"}}>
                                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', paddingLeft:"2em"}}>
                                          <h2> Evolución de las visitas ultimos 7 semanas</h2>
                                    </Grid>
                                    <Grid item sm={12} xs={12} style={{textAlign:'center', marginBottom: '10px'}}>
                                      <LineChart width={700} height={250} data={rowsSearchWeekGraph}>
                                              <CartesianGrid strokeDasharray="3 3" />
                                              <XAxis dataKey="dateLabel" />
                                              <YAxis />
                                              <Tooltip />
                                              <Legend />
                                              <Line dataKey="ranking" stroke="#8884d8" name="Posición"/>
                                              <Line dataKey="ammount" stroke="#82ca9d" name="Búsquedas"/>
                                      </LineChart>
                                    </Grid>
                                  </Grid>
                              
                              }
                              {tab == 9 && 
                                <Grid container style={{marginBottom: '50px', padding:"1em"}}>
                                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', paddingLeft:"2em"}}>
                                            <h2>Ranking serie mes visitas</h2>
                                    </Grid>
                                    {rowsVisitMonth && rowsVisitMonth[0] && rowsVisitMonth[0]["dateDay"] && <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', paddingLeft:"2em"}}>
                                      <h4>Datos pertenecientes al més de {moment(rowsVisitMonth[0]["dateDay"]).locale("es").format("MMMM YYYY")}</h4>
                                    </Grid> }

                                    {filter.serie && filter.serie > 0 && rowsVisitMonth.length > 0 &&
                                      <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', paddingLeft:"2em"}}> {searchSerie(filter.serie, rowsVisitMonth)}</Grid>
                                    }
                                        <CustomPaginationActionsTable rows={rowsVisitMonth} fields={["ranking","serie.name","ammount","diffranking"]} fieldId={"id"} headers={["#","Serie","Visitas","Tendencia"]} deleteaction={false} editable={false} hiddenpagination={true} paintCell={paintCell}/>
                                </Grid>
                              }
                              {tab == 10 && 
                                <Grid container style={{marginBottom: '50px', padding:"1em"}}>
                                        <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', paddingLeft:"2em"}}>
                                            <h2>Ranking serie mes búsquedas</h2>
                                        </Grid>
                                        {rowsSearchMonth && rowsSearchMonth[0] && rowsSearchMonth[0]["dateDay"] && <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', paddingLeft:"2em"}}>
                                          <h4>Datos pertenecientes al més de {moment(rowsSearchMonth[0]["dateDay"]).locale("es").format("MMMM YYYY")}</h4>
                                        </Grid> }

                                    {filter.serie && filter.serie > 0 && rowsSearchMonth.length > 0 &&
                                      <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', paddingLeft:"2em"}}> {searchSerie(filter.serie, rowsSearchMonth)}</Grid>
                                    }
                                        <CustomPaginationActionsTable rows={rowsSearchMonth} fields={["ranking","serie.name","ammount","diffranking"]} fieldId={"id"} headers={["#","Serie","Búsquedas","Tendencia"]} deleteaction={false} editable={false} hiddenpagination={true} paintCell={paintCell}/>
                                </Grid>
                              }
                             
                              {tab == 11 && rowsVisitDayGraph.length > 0 && 
                                <Grid container style={{marginBottom: '50px', padding:"1em"}}>
                                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', paddingLeft:"2em"}}>
                                          <h2> Evolución de las visitas ultimos 7 meses</h2>
                                    </Grid>
                                    <Grid item sm={12} xs={12} style={{textAlign:'center', marginBottom: '10px'}}>
                                      <LineChart width={700} height={250} data={rowsVisitMonthGraph}>
                                              <CartesianGrid strokeDasharray="3 3" />
                                              <XAxis dataKey="dateLabel" />
                                              <YAxis />
                                              <Tooltip />
                                              <Legend />
                                              <Line dataKey="ranking" stroke="#8884d8" name="Posición ranking"/>
                                              <Line dataKey="ammount" stroke="#82ca9d" name="Visitas"/>
                                      </LineChart>
                                    </Grid>
                                  </Grid>
                              
                              }

                              {tab == 12 && rowsVisitDayGraph.length > 0 && 
                                <Grid container style={{marginBottom: '50px', padding:"1em"}}>
                                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', paddingLeft:"2em"}}>
                                          <h2> Evolución de las visitas ultimos 7 meses</h2>
                                    </Grid>
                                    <Grid item sm={12} xs={12} style={{textAlign:'center', marginBottom: '10px'}}>
                                      <LineChart width={700} height={250} data={rowsSearchMonthGraph}>
                                              <CartesianGrid strokeDasharray="3 3" />
                                              <XAxis dataKey="dateLabel" />
                                              <YAxis />
                                              <Tooltip />
                                              <Legend />
                                              <Line dataKey="ranking" stroke="#8884d8" name="Posición"/>
                                              <Line dataKey="ammount" stroke="#82ca9d" name="Búsquedas"/>
                                      </LineChart>
                                    </Grid>
                                  </Grid>
                              
                              }


                        </Grid>
                </Grid>
            </div>
    </CrudEmpty>
  );
}