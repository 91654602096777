import React, { useState, useEffect }   from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudTable from "../../crud/CrudTable"
import {partnersService as service, partnerscategoryService} from "../../../services"

const initFilter =  {
    idcategory:null,
    title: "",
    before : "",
    after : "",
};


export default function Partner() {
  const [filter, setFilter] = React.useState(initFilter);
  const fieldstable = ["image","name","category.name",];
  const headersTable =  ["#","Nombre","categoría"];

  const createEstructureFilter =  [
       {
        type:"autocomplete",
        name: "idcategory",
        label: "Categoría",
        service: partnerscategoryService.combo,
        col: 3
       },
       {
         type:"input",
         name: "name",
         label: "Nombre",
         col: 3
        }
    ];


  return (
      <CrudTable
            service={service}
            titlePage={"Partner"}
            titleButton={"Añadir partner"}
            urledit={"/partners/"}
            urlnew={"/partners/new/"}
            filter={initFilter}
            fieldstable={fieldstable}
            headersTable={headersTable}
            estructureForm={createEstructureFilter}
        />
  
  );
}
