import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles, drawerWidth } from '../../layout/styles';
import CustomPaginationActionsTable from '../../table/Table';
import Filter from '../../filter/Filter';
import Form from '../../form/Form';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import { productmarketplaceService as service, cameraService, serieService, lensService, userService, productViewService, productContactService, manufactureService, countryService } from '../../../services';
import ExploitIndividualData from '../../exploitdata/ExploitIndividualData';
import ExploitContactIndividualData from '../../exploitdata/ExploitContactIndividualData';

const initElement = {
      title: '',
      description: '',
      camera: {
            id: null,
            name: '',
      },
      serie: {
            id: null,
            name: '',
      },
      manufacture: {
            id: null,
            name: '',
      },
      lens: [
            {
                  id: null,
                  name: '',
            },
      ],
      price: '',
      user: {
            id: null,
            name: '',
      },
      image: null,
      image1: null,
      image2: null,
      image3: null,
      image4: null,
      image5: null,
      state: null,
      conditionProduct: null,
      productType: null,
      currency: null,
      manual: 0,
      directBuy: false,
      pickupStreet: null,
      pickupPostalcode: null,
      pickupCity: null,
      pickupState: null,
      pickupCountry: { id: null, name: '' },
      pickupPhone: null,
      kg: null,
      largo: null,
      alto: null,
      ancho: null,
};

export default function FormProduct() {
      const history = useHistory();
      let { id } = useParams();
      const classes = useStyles();
      const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
      const [objectform, setObjectform] = React.useState({ ...initElement });
      const [titleWindow, setTitleWindow] = React.useState(id && id > 0 ? 'Editar producto' : 'Crear producto');
      const [idRow, setIdrow] = React.useState(id);
      const [idSerie, setIdSerie] = React.useState(-1);
      const [cities, setCities] = React.useState([]);
      const [reload, setReload] = React.useState(false);

      const [open, setOpen] = React.useState(true);
      const [rows, setRows] = React.useState([{}]);

      const handleDrawerOpen = () => {
            setOpen(true);
      };
      const handleDrawerClose = () => {
            setOpen(false);
      };

      const [estructureform, setEstructureform] = React.useState([
            {
                  type: 'input',
                  name: 'title',
                  label: 'Titulo',
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'user.id',
                  label: 'Usuario',
                  service: userService.combomarketplace,
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'productType',
                  label: 'Tipo',
                  options: [
                        { key: 'LENSES', name: 'Lentes' },
                        { key: 'CAMERA', name: 'Camaras' },
                        { key: 'OTHER', name: 'Otros' },
                  ],
                  clear: ['lens', 'serie.id', 'camera.id'],
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'manufacture.id',
                  label: 'Fabricante',
                  service: manufactureService.combo,
                  clear: ['lens', 'serie.id', 'camera.id'],
                  ishidden: (objectform) => {
                        return !(objectform && objectform['productType'] && objectform['productType'] == 'OTHER');
                  },
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'camera.id',
                  label: 'Cámara',
                  service: cameraService.combo,
                  clear: ['lens', 'serie.id', 'manufacture.id'],
                  ishidden: (objectform) => {
                        return !(objectform && objectform['productType'] && objectform['productType'] == 'CAMERA');
                  },
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'serie.id',
                  label: 'Serie',
                  service: serieService.combo,
                  relationfield: 'lens',
                  clear: ['lens', 'camera.id', 'manufacture.id'],
                  servicerelation: lensService.comboSerie,
                  ishidden: (objectform) => {
                        return !(objectform && objectform['productType'] && objectform['productType'] == 'LENSES');
                  },
                  col: 4,
            },
            {
                  multiple: true,
                  type: 'autocomplete',
                  name: 'lens',
                  label: 'Lentes',
                  clear: ['camera.id', 'manufacture.id'],
                  ishidden: (objectform) => {
                        return !(objectform && objectform['productType'] && objectform['productType'] == 'LENSES');
                  },
                  options: Array(),
                  col: 4,
            },
            {
                  type: 'title',
                  name: 'space',
                  label: '',
                  col: 12,
            },
            {
                  type: 'autocomplete',
                  name: 'manual',
                  label: 'Automatic currency exchange',
                  options: [
                        { key: 1, name: 'NO' },
                        { key: 0, name: 'YES' },
                  ],
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'currency',
                  label: 'Currency',
                  options: [
                        { key: 'USD', name: 'US Dollar' },
                        { key: 'EUR', name: 'EURO' },
                  ],
                  ishidden: (objectform) => {
                        return (!objectform && !objectform['manual']) || objectform['manual'] != 0;
                  },
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'priceautomatic',
                  label: 'Price',
                  inputtype: 'number',
                  ishidden: (objectform) => {
                        return (!objectform && !objectform['manual']) || objectform['manual'] != 0;
                  },
                  step: 0.01,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'price',
                  label: 'Price in euro',
                  inputtype: 'number',
                  step: 0.01,
                  ishidden: (objectform) => {
                        return (!objectform && !objectform['manual']) || objectform['manual'] != 1;
                  },
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'pricedollar',
                  label: 'Price in dollars',
                  inputtype: 'number',
                  ishidden: (objectform) => {
                        return (!objectform && !objectform['manual']) || objectform['manual'] != 1;
                  },
                  step: 0.01,
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'state',
                  label: 'Estado',
                  options: [
                        { key: 'DRAFT', name: 'Borrador' },
                        { key: 'RETIRED', name: 'Retirado' },
                        { key: 'ACTIVE', name: 'Activo' },
                        { key: 'RESERVED', name: 'Reservado' },
                        { key: 'SOLD', name: 'Vendido' },
                  ],
                  col: 6,
            },
            {
                  type: 'autocomplete',
                  name: 'conditionProduct',
                  label: 'Product status',
                  options: [
                        { key: 'N', name: 'N - NEW' },
                        { key: 'D', name: 'D - DEMO' },
                        { key: 'E', name: 'E - EXCELLENT' },
                        { key: 'G', name: 'G - GOOD' },
                        { key: 'F', name: 'F - FAIR' },
                  ],
                  col: 6,
            },
            {
                  type: 'textarea',
                  name: 'description',
                  label: 'Descripción',
                  col: 12,
            },
            {
                  type: 'image',
                  name: 'image',
                  label: 'Imagen principal',
                  col: 2,
            },
            {
                  type: 'image',
                  name: 'image1',
                  label: 'Imagen Aux1',
                  col: 2,
            },
            {
                  type: 'image',
                  name: 'image2',
                  label: 'Imagen Aux2',
                  col: 2,
            },
            {
                  type: 'image',
                  name: 'image3',
                  label: 'Imagen Aux3',
                  col: 2,
            },
            {
                  type: 'image',
                  name: 'image4',
                  label: 'Imagen Aux4',
                  col: 2,
            },
            {
                  type: 'image',
                  name: 'image5',
                  label: 'Imagen Aux5',
                  col: 2,
            },
            {
                  type: 'title',
                  name: 'titledirect',
                  label: 'Venta directa',
                  col: 12,
            },
            {
                  type: 'checkbox',
                  name: 'directBuy',
                  label: '¿Se puede vender directamente?',
                  col: 12,
            },
            {
                  type: 'input',
                  name: 'pickupStreet',
                  label: 'Calle de recogida',
                  col: 8,
            },
            {
                  type: 'input',
                  name: 'pickupPostalcode',
                  label: 'Código postal recogida',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'pickupCity',
                  label: 'Ciudad recogida',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'pickupState',
                  label: 'Pronvincia/Estado recogida',
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'pickupCountry.id',
                  label: 'País recogida',
                  service: countryService.combo,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'pickupPhone',
                  label: 'Teléfono recogida',
                  col: 4,
            },
            {
                  type: 'title',
                  name: 'titlepacket',
                  label: 'Información del paquete de envio',
                  col: 12,
            },
            {
                  type: 'input',
                  name: 'kg',
                  label: 'Peso',
                  inputtype: 'number',
                  inputstep: 1,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'largo',
                  label: 'Largo paquete',
                  inputtype: 'number',
                  inputstep: 1,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'alto',
                  label: 'Alto paquete',
                  inputtype: 'number',
                  inputstep: 1,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'ancho',
                  label: 'Ancho paquete',
                  inputtype: 'number',
                  inputstep: 1,
                  col: 4,
            },
      ]);

      useEffect(() => {
            if (idRow && idRow > 0) {
                  searchOne();
            }
      }, []);

      const searchOne = function () {
            service.one(idRow, (data, error) => {
                  if (data && data.data) {
                        var element = data.data;
                        let objectedit = JSON.parse(JSON.stringify(objectform));
                        objectedit['title'] = element.title ? element.title : '';
                        objectedit['description'] = element.description ? element.description : '';
                        objectedit['state'] = element.state ? element.state : '';
                        objectedit['conditionProduct'] = element.conditionProduct ? element.conditionProduct : '';
                        objectedit['productType'] = element.productType ? element.productType : '';
                        objectedit['currency'] = element.currency ? element.currency : '';
                        objectedit['price'] = element.price ? element.price : '';
                        objectedit['pricedollar'] = element.pricedollar ? element.pricedollar : '';
                        objectedit['priceautomatic'] = element.currency && element.currency == 'EUR' ? element.price : element.pricedollar;
                        objectedit['manual'] = element.manual ? 1 : 0;
                        objectedit['currency'] = element.currency;
                        objectedit['address'] = element.address ? element.address : '';
                        objectedit['image'] = element.image && element.image != '' ? element.image : null;
                        objectedit['image1'] = element.image1 && element.image1 != '' ? element.image1 : null;
                        objectedit['image2'] = element.image2 && element.image2 != '' ? element.image2 : null;
                        objectedit['image3'] = element.image3 && element.image3 != '' ? element.image3 : null;
                        objectedit['image4'] = element.image4 && element.image4 != '' ? element.image4 : null;
                        objectedit['image5'] = element.image5 && element.image5 != '' ? element.image5 : null;
                        objectedit['pickupStreet'] = element.pickupStreet && element.imagpickupStreet5 != '' ? element.pickupStreet : null;
                        objectedit['pickupPostalcode'] = element.pickupPostalcode && element.pickupPostalcode != '' ? element.pickupPostalcode : null;
                        objectedit['pickupCity'] = element.pickupCity && element.pickupCity != '' ? element.pickupCity : null;
                        objectedit['pickupState'] = element.pickupState && element.pickupState != '' ? element.pickupState : null;
                        objectedit['pickupCountry'] = {
                              id: element.pickupCountry && element.pickupCountry.id ? element.pickupCountry.id : null,
                              name: element.pickupCountry && element.pickupCountry.name ? element.pickupCountry.name : null,
                        };
                        objectedit['pickupPhone'] = element.pickupPhone && element.pickupPhone != '' ? element.pickupPhone : null;
                        objectedit['directBuy'] = element.directBuy ? true : false;
                        objectedit['phone'] = element.phone ? element.phone : '';
                        objectedit['latitude'] = element.latitude ? element.latitude : '';
                        objectedit['longitude'] = element.longitude ? element.longitude : '';
                        objectedit['user'] = { id: element.user && element.user.id ? element.user.id : null, name: element.user && element.user.name ? element.user.name : null };
                        objectedit['camera'] = { id: element.camera && element.camera.id ? element.camera.id : null, name: element.camera && element.camera.name ? element.camera.name : null };
                        objectedit['serie'] = { id: element.serie && element.serie.id ? element.serie.id : null, name: element.serie && element.serie.name ? element.serie.name : null };
                        objectedit['manufacture'] = { id: element.manufacture && element.manufacture.id ? element.manufacture.id : null, name: element.manufacture && element.manufacture.name ? element.manufacture.name : null };
                        if (element.lens && element.lens.length > 0) {
                              var l = [{}];
                              for (var le of element.lens) {
                                    l.push({ id: le.id, name: le.focal.name });
                              }
                              objectedit['lens'] = l;
                        } else {
                              objectedit['lens'] = [];
                        }
                        objectedit['country'] = {
                              id: element.city && element.city.state && element.city.state.country && element.city.state.country.id ? element.city.state.country.id : null,
                              name: element.city && element.city.state && element.city.state.country && element.city.state.country.name ? element.city.state.country.name : null,
                        };

                        setObjectform(objectedit);
                        setReload(true);
                  }
            });
      };

      const cancelForm = (object) => {
            history.push('/marketplace');
      };

      const saveForm = (object) => {
            if (isValid(object)) {
                  var aux = { ...object };
                  aux['manual'] = aux['manual'] && aux['manual'] == 1 ? true : false;
                  var objToSave = cleanElementsBeforeSave(aux);
                  if (idRow && idRow > 0) {
                        objToSave['id'] = id;
                        update(objToSave, false);
                  } else {
                        create(objToSave, false);
                  }
            }
      };

      const create = (object, cleanSerie) => {
            service.create(object, (data, error) => {
                  if (data) {
                        setIdrow(data.data.id);
                        history.push('/marketplace/' + data.data.id);
                  }
            });
      };

      const update = (object, cleanSerie) => {
            service.edit(id, object, (data, error) => {
                  if (data) {
                  }
            });
      };

      const isValid = function (object) {
            console.log('Validar !!');
            console.log(object);
            if (!object['title']) {
                  toast.notify('Debe indicar el titulo', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['user']['id']) {
                  toast.notify('Debe indicar el usuario', { position: 'top', duration: 10000 });
                  return false;
            }
            if (object['manual'] == 1) {
                  if (!object['price'] || !object['pricedollar']) {
                        toast.notify('Al seleccionar cambio de divisa manual debe meter ambos precios', { position: 'top', duration: 10000 });
                        return false;
                  }
            } else {
                  if (!object['currency']) {
                        toast.notify('Debe indicar la moneda', { position: 'top', duration: 10000 });
                        return false;
                  }
                  if (!object['priceautomatic']) {
                        toast.notify('Debe indicar el precio', { position: 'top', duration: 10000 });
                        return false;
                  }
            }
            if (!object['productType']) {
                  toast.notify('Debe indicar el tipo de producto', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['camera']['id'] && object['productType'] == 'CAMERA') {
                  toast.notify('Debe indicar la camara o seleccione el tipo de producto OTROS', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['serie']['id'] && object['productType'] == 'LENSES') {
                  toast.notify('Debe indicarla serie o seleccione el tipo de producto OTROS', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['conditionProduct']) {
                  toast.notify('Conditions product is required', { position: 'top', duration: 10000 });
                  return false;
            }

            return true;
      };

      const cleanElementsBeforeSave = function (object) {
            let newObj = JSON.parse(JSON.stringify(object));
            return newObj;
      };

      return (
            <div className={classes.root}>
                  <CssBaseline />
                  <Header title={titleWindow} classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
                  <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
                  <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        <Container maxWidth="lg" className={classes.container}>
                              <Grid container spacing={3} className={classes.bodycontainer}>
                                    <Grid item sm={12} xs={12} style={{ textAlign: 'right', marginBottom: '10px' }}>
                                          <Form objectform={objectform} fields={estructureform} searchFilter={saveForm} cleanFilter={cancelForm} labelsave="Guardar" labelcancel="Cancelar" reload={reload} />
                                    </Grid>
                                    <Grid item sm={12} xs={12} style={{ marginBottom: '10px' }}>
                                          {idRow && idRow > 0 && <ExploitIndividualData id={idRow} service={productViewService} />}
                                          {idRow && idRow > 0 && <ExploitContactIndividualData id={idRow} service={productContactService} />}
                                    </Grid>
                              </Grid>

                              <Box pt={4}>
                                    <Copyright />
                              </Box>
                        </Container>
                  </main>
            </div>
      );
}
