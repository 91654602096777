import BaseService from './_base.service';

export const productContactService = {
    exploitDataDay,
    exploitDataWeek,
    exploitDatayear,
    exploitdatacountry,
    exploitDataCountryWeek,
    exploitDataCountryMonth,
    exploitDataCountryyear,
    moreViewTodayGeneral,
    moreViewWeekGeneral,
    moreViewMonthGeneral,
    moreViewCountryTodayGeneral,
    moreViewCountryWeekGeneral,
    moreViewCountryMonthGeneral,
};

const URL_BASE = "/appuser/productmarketplace/contactdata"

function exploitDataDay(date,id, callback) {
    return BaseService.get(URL_BASE+"/exploitdata/day/"+date+"/"+id, callback, true)
}

function exploitDataWeek(month,year,id, callback) {
    return BaseService.get(URL_BASE+"/exploitdata/week/"+month+"/"+year+"/"+id, callback, true)
}

function exploitDatayear(year,id, callback) {
    return BaseService.get(URL_BASE+"/exploitdata/year/"+year+"/"+id, callback, true)
}

function exploitdatacountry(id, callback) {
    return BaseService.get(URL_BASE+"/exploitdatacountry/"+id, callback, true)
}

function exploitDataCountryWeek(date,id, callback) {
    return BaseService.get(URL_BASE+"/exploitdatacountry/day/"+date+"/"+id, callback, true)
}

function exploitDataCountryMonth(month,year,id, callback) {
    return BaseService.get(URL_BASE+"/exploitdatacountry/month/"+month+"/"+year+"/"+id, callback, true)
}


function exploitDataCountryyear(year,id, callback) {
    return BaseService.get(URL_BASE+"/exploitdatacountry/year/"+year+"/"+id, callback, true)
}


/** LIST */

function moreViewTodayGeneral(callback) {
    return BaseService.get(URL_BASE+"/exploitdatamorviewgeneral", callback, true)
}

function moreViewWeekGeneral(date,callback) {
    return BaseService.get(URL_BASE+"/exploitdatamorviewgeneral/week/"+date, callback, true)
}

function moreViewMonthGeneral(month,year, callback) {
    return BaseService.get(URL_BASE+"/exploitdatamorviewgeneral/month/"+month+"/"+year, callback, true)
}

function moreViewCountryTodayGeneral(callback) {
    return BaseService.get(URL_BASE+"/exploitdatamorecountrygeneral", callback, true)
}

function moreViewCountryWeekGeneral(date,callback) {
    return BaseService.get(URL_BASE+"/exploitdatamorecountrygeneral/week/"+date, callback, true)
}

function moreViewCountryMonthGeneral(month,year, callback) {
    return BaseService.get(URL_BASE+"/exploitdatamorecountrygeneral/month/"+month+"/"+year, callback, true)
}

	

