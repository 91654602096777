import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudForm from "../../crud/CrudForm"
import { useStyles,drawerWidth } from "../../layout/styles";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {partnerscategoryService as service} from "../../../services"

const initElement = {
     name:"",
     order:null,
  };





export default function FormPartnersCategory() {
  let { id } = useParams();
  const classes = useStyles();
  const [objectform, setObjectform] = React.useState({...initElement});


  const [estructureform,setEstructureform] = React.useState([
         {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 4
          },
          {
            type:"input",
            name: "order",
            label: "Orden",
            inputtype:"number",
            col: 4
           },
          


    ]);


  const recoveryElement = function(objectedit,element){
    objectedit['name']=element.name?element.name:"";
    objectedit['order']=element.order?element.order:"";
    return objectedit;
  }



  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      }
      return true;
  }

  return (
    <CrudForm
        objectform = {objectform}
        estructureform = {estructureform}
        titleEdit = {'Editar categoría'}
        titleNew = {'Nueva categería'}
        urledit={"/partnerscategory/"}
        urlCancel={"/partnerscategory"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />
  );
}
