import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudForm from "../../crud/CrudForm"
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {minfocusService as service} from "../../../services"

const initElement = {
     name:"",
     sizeMM: null,
     sizeInches: null,
     sizeMMND: null,
     sizeInchesND: null,

  };


export default function FormMinFocus() {
  const classes = useStyles();
  const [objectform, setObjectform] = React.useState({...initElement});



  const estructureform =  [
       {
         type:"input",
         name: "name",
         label: "Nombre",
         col: 6
        },
        {
           type:"input",
           name: "sizeMM",
           label: "Tam. MM",
           col: 6,
           inputtype:"number",
           step:0.01
         },
          {
           type:"input",
           name: "sizeInches",
           label: "Tam. Pulgadas.",
           col: 6,
           inputtype:"number",
           step:0.01
         },
        {
         type:"input",
         name: "sizeInchesND",
         label: "Tam. Pulgadas ND",
         col: 6,
         inputtype:"number",
         step:0.01
        },
        {
         type:"input",
         name: "sizeInchesND",
         label: "Tam. Pulgadas ND",
         col: 6,
         inputtype:"number",
         step:0.01
        }

  ];


  const recoveryElement = function(objectedit,element){
    objectedit['name']=element.name?element.name:"";
    objectedit['sizeMM']=element.sizeMM?element.sizeMM:"";
    objectedit['sizeInches']=element.sizeInches?element.sizeInches:"";
    objectedit['sizeInchesND']=element.sizeInchesND?element.sizeInchesND:"";
    objectedit['sizeMMND']=element.sizeMMND?element.sizeMMND:"";
    return objectedit;
  }

  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      }
      if(!object['sizeMM']){
        toast.notify("Debe indicar el tamaño en mm", {position:'top', duration: 10000})
        return false;
      }
      if(!object['sizeInches']){
        toast.notify("Debe indicar el tamaño en pulgadas", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }


  return (
    <CrudForm
        objectform = {objectform}
        estructureform = {estructureform}
        titleEdit = {'Editar Enfoque Min.'}
        titleNew = {'Nueva Enfoque Min.'}
        urledit={"/lens/minfocus/"}
        urlCancel={"/lens/minfocus"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />
  );
}
