import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles,drawerWidth } from "../../layout/styles";
import CustomPaginationActionsTable from "../../table/Table"
import Filter from "../../filter/Filter"
import {productmarketplaceComplaintService as service} from "../../../services"

const initFilter =  {
    title: "",
    nameuser : "",
    emailuser : "",
    subject: "",
    motive : "",
    resolve : null
};



export default function Complaint() {
  const history = useHistory();
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [page, setPage] = React.useState(0);
  const [filter, setFilter] = React.useState(initFilter);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const fieldstable = ["user.email","productMarketplace.title","suject","cause","resolveBool"];
  const headersTable =  ["Usuario","Producto","Asunto denuncia","Motivo","Resulto"];

  const [open, setOpen] = React.useState(true);
  const [rows,setRows] = React.useState([{}]);
  const [rowsTotal,setRowsTotal] = React.useState(0);


  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const changePageTable = (page,size) => {
    search(page,size);
  };
  const cleanFilter = (filter) => {
    console.log(filter);
    setFilter(filter);
    search(0,rowsPerPage);

  }

  const searchFilter = (filter) => {
    console.log(filter);
    setFilter(filter);
    search(0,rowsPerPage);
  }


  const createEstructureFilter =  [
       {
         type:"input",
         name: "title",
         label: "Titulo producto",
         col: 4
        },
       {
         type:"input",
         name: "nameuser",
         label: "Nombre usuario",
         col: 4
        },
       {
         type:"input",
         name: "emailuser",
         label: "Email usuario",
         col: 4
        },
       {
         type:"input",
         name: "subject",
         label: "Asunto",
         col: 4
        },
       {
         type:"input",
         name: "motive",
         label: "Causa",
         col: 4
        },
        {
          type:"autocomplete",
          name: "resolve",
          label: "Resuelto",
          options: [{key:-1,name:"Todos"},{key:0,name:"No"},{key:1,name:"Si"}],
          col: 4
         }
    ];

  const search = (page,size) => {
    setPage(page);
    setRowsPerPage(size);
    service.search(page,size,filter, (data, error) => {
        if(data){
          console.log(data);
          setRows(data.data);
          setRowsTotal(data.total);
        } else {
          setRows([{}]);
          setRowsTotal(0);
        }
    });
  }

  useEffect(() => {
    search(0,10);
  }, []);


  const eventSelect = (id) => {
      console.log("Selecciona el id " + id);
      history.push('/marketplace/complaint/'+id);
  }


  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title="Denuncias de productos" classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container style={{marginBottom: '50px'}}>
            <Filter cleanFilter={cleanFilter} searchFilter={searchFilter} filter={filter} estructure={createEstructureFilter} hiddenButton={true} />
          </Grid>
          <Grid container spacing={3} className={classes.bodycontainer}>
            <CustomPaginationActionsTable rows={rows} fields={fieldstable} fieldId={"id"} rowsPerPage={10} rowsTotal={rowsTotal} changePage={changePageTable} headers={headersTable} page={page} selectHandler={eventSelect}/>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
