import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles,drawerWidth } from "../../layout/styles";
import CustomPaginationActionsTable from "../../table/Table"
import Filter from "../../filter/Filter"
import Form from "../../form/Form"
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {lensService as service, typeLensService, markService, manufactureService, serieService, diameterService, focalService, tstopService, minfocusService, lengthService, weightService} from "../../../services"

const initElement = {
     note:"",
     serie:{
        id:null,
        name:""
     },
     focal:{
        id:null,
        name:""
     },
     frontDimeter:{
       id: null,
       name:""
     },
     length:{
        id:null,
        name:""
     },
     minFocus:{
        id:null,
        name:""
     },
     tStop:{
        id: null,
        name:""
     },
     weight:{
        id:null,
        name:""
     },
     mount:"",
     coberture:"",
     year:""
  };


export default function FormLens() {
  const history = useHistory();
  let { id } = useParams();
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [objectform, setObjectform] = React.useState({...initElement});
  const fieldstable = ["focal.name","tStop.name","weight.name","frontDimeter.name","minFocus.name","length.name","coberture","mount","year"];
  const headersTable =  ["Focal","T. Stop","Peso","Diametro Frontal","Min Focus","Largo","Cobertura","Montura","Año"];
  const [titleWindow, setTitleWindow] = React.useState(id && id > 0? 'Editar lente':'Nueva lente');
  const [idRow, setIdrow] = React.useState(id);
  const [idSerie, setIdSerie] = React.useState(-1);

  const [open, setOpen] = React.useState(true);
  const [rows,setRows] = React.useState([{}]);
  const [rowsTotal,setRowsTotal] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);


  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const changePageTable = (page,size) => {
    search(page,size,idSerie);
  };

  const handlerSerie = function(value){
    setIdSerie(value.key)
    search(0,10,value.key);
  }


  const estructureform =  [
         {
           type:"autocomplete",
           name: "serie.id",
           label: "Serie",
           service: serieService.combo,
           handler: handlerSerie,
           col: 6
          },
         {
           type:"autocomplete",
           name: "focal.id",
           label: "Focal",
           service: focalService.combo,
           col: 6
          },
         {
           type:"autocomplete",
           name: "tStop.id",
           label: "T.Stop",
           service: tstopService.combo,
           col: 6
          },
         {
           type:"autocomplete",
           name: "weight.id",
           label: "Peso",
           service: weightService.combo,
           col: 6
          },
         {
           type:"autocomplete",
           name: "frontDimeter.id",
           label: "Diam. Frontal",
           service: diameterService.combo,
           col: 6
          },
         {
           type:"autocomplete",
           name: "minFocus.id",
           label: "Min. Foc.",
           service: minfocusService.combo,
           col: 6
          },
         {
           type:"autocomplete",
           name: "length.id",
           label: "Tamaño",
           service: lengthService.combo,
           col: 6
          },
         {
           type:"input",
           name: "coberture",
           label: "Cobertura",
           col: 6
          },
         {
           type:"input",
           name: "mount",
           label: "Montura",
           col: 6
          },
         {
           type:"input",
           name: "year",
           label: "Año",
           col: 6
          },
    ];

  useEffect(() => {
      if(idRow && idRow > 0){
        searchOne(idRow);
      };

  }, []);


  const searchOne = function(id){
    service.one(id,(data, error) => {
      if(data && data.data){
        var element = data.data;
        let objectedit = JSON.parse(JSON.stringify(objectform));
        objectedit['note']=element.note?element.note:"";
        objectedit['focal']= {id:element.focal && element.focal.id?element.focal.id:null, name: element.focal && element.focal.name?element.focal.name:null};
        objectedit['frontDimeter']= {id:element.frontDimeter && element.frontDimeter.id?element.frontDimeter.id:null, name: element.frontDimeter && element.frontDimeter.name?element.frontDimeter.name:null};
        objectedit['length']= {id:element.length && element.length.id?element.length.id:null, name: element.length && element.length.name?element.length.name:null};
        objectedit['minFocus']= {id:element.minFocus && element.minFocus.id?element.minFocus.id:null, name: element.minFocus && element.minFocus.name?element.minFocus.name:null};
        objectedit['tStop']= {id:element.tStop && element.tStop.id?element.tStop.id:null, name: element.tStop && element.tStop.name?element.tStop.name:null};
        objectedit['weight']= {id:element.weight && element.weight.id?element.weight.id:null, name: element.weight && element.weight.name?element.weight.name:null};
        objectedit['serie']= {id:element.serie && element.serie.id?element.serie.id:null, name: element.serie && element.serie.name?element.serie.name:null};
        objectedit['mount']=element.mount?element.mount:"";
        objectedit['coberture']=element.coberture?element.coberture:"";
        objectedit['year']=element.year?element.year:"";
        setObjectform(objectedit);
        var serieID = element.serie && element.serie.id?element.serie.id:-1;
        setIdSerie(serieID);
        search(0,10,serieID);
     }
    });
  }

  const cancelForm = (object) => {
      history.push('/lens');
  }

  const saveForm = (object) => {
       if(isValid(object)) {
         var objToSave = cleanElementsBeforeSave(object);
         if (idRow && idRow > 0){
           objToSave['id'] = id;
           update(objToSave,false);
         } else {
           create(objToSave,false);
         }
       }

  }

  const create = (object,cleanSerie) => {
    service.create(object, (data, error) => {
        if(data){
          cleanObject(cleanSerie);
          search(0,10,idSerie);
        }
    });
  }

  const update = (object,cleanSerie) => {
    service.edit(id,object, (data, error) => {
        if(data){
          cleanObject(cleanSerie);
          search(0,10,idSerie);
        }
    });
  }

  const deleteHandler = (id) => {
    service.remove(id, (data, error) => {
        if(data){
          console.log(data);
            search(0,10,idSerie);
        }
    });
  }

  const eventSelect = (id) => {
      history.push('/lens/'+id);
      setIdrow(id);
      searchOne(id);
  }

  const cleanObject = function(cleanSerie){
     setIdrow(-1);
     setObjectform(currentObjectform => ({...currentObjectform, ['note']: "" }));
     setObjectform(currentObjectform => ({...currentObjectform, ['focal']: {id:null, name:""} }));
     setObjectform(currentObjectform => ({...currentObjectform, ['frontDimeter']: {id:null, name:""} }));
     setObjectform(currentObjectform => ({...currentObjectform, ['length']: {id:null, name:""} }));
     setObjectform(currentObjectform => ({...currentObjectform, ['minFocus']: {id:null, name:""} }));
     setObjectform(currentObjectform => ({...currentObjectform, ['tStop']: {id:null, name:""} }));
     setObjectform(currentObjectform => ({...currentObjectform, ['weight']: {id:null, name:""} }));
     setObjectform(currentObjectform => ({...currentObjectform, ['mount']: "" }));
     setObjectform(currentObjectform => ({...currentObjectform, ['coberture']: "" }));
     setObjectform(currentObjectform => ({...currentObjectform, ['year']: "" }));
     if(cleanSerie) {
       setObjectform(currentObjectform => ({...currentObjectform, ['serie']: {id:null, name:""} }));
     }
  }

  const isValid = function(object){
      if(!object['serie']['id']){
        toast.notify("Debe indicar la serie", {position:'top', duration: 10000})
        return false;
      } else if(!object['focal']['id']){
        toast.notify("Debe indicar la focal", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }

  const cleanElementsBeforeSave = function(object){
    let newObj = JSON.parse(JSON.stringify(object));
     if(!newObj['frontDimeter']['id']){  newObj['frontDimeter'] = null; }
     if(!newObj['length']['id']){ newObj['length'] = null;}
     if(!newObj['minFocus']['id']){  newObj['minFocus'] = null; }
     if(!newObj['tStop']['id']){ newObj['tStop'] = null;}
     if(!newObj['weight']['id']){  newObj['weight'] = null; }

     return newObj;
  }

  const search = (page,size,serieID) => {
    setPage(page);
    setRowsPerPage(size);
    var filter = {serie:serieID};
    service.search(page,size,filter, (data, error) => {
        if(data){
          setRows(data.data);
          setRowsTotal(data.total);
        } else {
          setRows([{}]);
          setRowsTotal(0);
        }
    });
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title={titleWindow} classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} className={classes.bodycontainer}>
              <Grid item sm={6} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                  <Form objectform={objectform} fields={estructureform}  searchFilter={saveForm} cleanFilter={cancelForm} labelsave="Guardar" labelcancel="Cancelar"/>
              </Grid>
              <Grid item sm={6} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                <CustomPaginationActionsTable rows={rows} fields={fieldstable} fieldId={"id"} rowsPerPage={10} rowsTotal={rowsTotal} changePage={changePageTable} headers={headersTable} page={page} deleteaction={true} deleteactionHandler={deleteHandler} selectHandler={eventSelect}/>
              </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
