import React, { useState, useEffect }   from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudTable from "../../crud/CrudTable"
import {reelsService as service, typeLensService, markService, manufactureService} from "../../../services"

const initFilter =  {
    title: "",
    nameuser : "",
    emailuser : "",
    nameserie : "",
    attrtype : "",
    attrmark: "",
    manufacture: null,
};


export default function Blog() {
  const [filter, setFilter] = React.useState(initFilter);
  const fieldstable = ["title","message","user.email","vimeo|youtube|videopath"];
  const headersTable =  ["Titulo","Mensaje","Usuario","Video"];


  const createEstructureFilter =  [
       {
         type:"input",
         name: "title",
         label: "Titulo",
         col: 4
        },
       {
         type:"input",
         name: "nameuser",
         label: "Nombre usuario",
         col: 4
        },
       {
         type:"input",
         name: "emailuser",
         label: "Email usuario",
         col: 4
        },
       {
         type:"input",
         name: "nameserie",
         label: "Nombre Serie",
         col: 4
        },
       {
         type:"autocomplete",
         name: "attrtype",
         label: "Tipo",
         service: typeLensService.combo,
         col: 4
        },
        {
          type:"autocomplete",
          name: "attrmark",
          label: "Familia",
          service: markService.combo,
          col: 4
         },
         {
           type:"autocomplete",
           name: "manufacture",
           label: "Fabricante",
           service: manufactureService.combo,
           col: 4
          }
    ];


  return (
      <CrudTable
            service={service}
            titlePage={"Reels"}
            titleButton={"Añadir Reel'"}
            urledit={"/reels/"}
            urlnew={"/reels/new/"}
            filter={initFilter}
            fieldstable={fieldstable}
            headersTable={headersTable}
            estructureForm={createEstructureFilter}
        />

  );
}
