import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudForm from "../../../crud/CrudForm"
import { useStyles,drawerWidth } from "../../../layout/styles";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {modalService as service} from "../../../../services"

const initElement = {
     name:"",
     link: "",
     active: true,
     content:"",
     cookie: "CIN"+(new Date()).getMilliseconds(),
     seconds: 5,
     always: true,
  };


export default function FormPopup() {
  let { id } = useParams();
  const classes = useStyles();
  const [objectform, setObjectform] = React.useState({...initElement});


  const [estructureform,setEstructureform] = React.useState([
         {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 4
          },
          {
            type:"input",
            name: "link",
            label: "Link donde debe mostrarse",
            col: 4
           },
           {
             type:"checkbox",
             name: "active",
             label: "Activo",
             col: 4
            },
            {
              type:"checkbox",
              name: "always",
              label: "Mostrar siempre (en caso de que no se marque esta casilla solo se mostrara una vez)",
              col: 4
             },
            {
              type:"input",
              name: "cookie",
              label: "Nombre dela cookie",
              col: 4
             },
             {
               type:"input",
               name: "seconds",
               label: "Segundos Delay",
               inputtype:"number",
               col: 4
              },
              {
                type:"editor",
                name: "content",
                label: "Contenido",
                col: 12
               },

             

    ]);


  const recoveryElement = function(objectedit,element){
    objectedit['name']=element.name?element.name:"";
    objectedit['link']=element.link?element.link:"";
    objectedit['active']=element.active?element.active:false;
    objectedit['seconds']=element.seconds?element.seconds:5;
    objectedit['cookie']=element.cookie?element.cookie:"";
    objectedit['always']=element.always?element.always:false;
    objectedit['content']=element.content?element.content:"";
    return objectedit;
  }



  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el titulo", {position:'top', duration: 10000})
        return false;
      }
      if(!object['link']){
        toast.notify("Debe indicar el link", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }

  return (
    <CrudForm
        objectform = {objectform}
        estructureform = {estructureform}
        titleEdit = {'Editar Popup'}
        titleNew = {'Nuevo Popup'}
        urledit={"/popup/"}
        urlCancel={"/popup"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />
  );
}
