import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Header from '../layout/Header';
import Menu from '../layout/Menu';
import Copyright from '../layout/Copyright';
import { useStyles,drawerWidth } from "../layout/styles";
import CustomPaginationActionsTable from "../table/Table"
import Filter from "../filter/Filter"
import { Link } from 'react-router-dom';

export default function CrudTable(props) {
  const classes = useStyles();
  const history = useHistory();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState(props.filter?props.filter:{});
  const fieldstable = props.fieldstable?props.fieldstable:[];
  const headersTable =  props.headersTable?props.headersTable:[];
  const service =  props.service?props.service:null;
  const estructureForm =  props.estructureForm?props.estructureForm:null;
  const urledit =  props.urledit?props.urledit:"";
  const urlnew =  props.urlnew?props.urlnew:"";
  const titlePage =  props.titlePage?props.titlePage:"";
  const fieldId =  props.fieldId?props.fieldId:"id";
  const titleButton =  props.titleButton?props.titleButton:"Nuevo";
  const hiddenFilter = props.hiddenFilter?props.hiddenFilter:false;
  const editable = props.editable != null?props.editable:true;

  const [open, setOpen] = useState(true);
  const [rows,setRows] = useState([{}]);
  const [rowsTotal,setRowsTotal] = useState(0);
  const [haveother,setHaveother]  = useState(props.haveother? props.haveother : false);
  const [othersform,setOthersform]  = useState(props.othersform? props.othersform : null);


  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const changePageTable = (page,size) => {
    search(page,size);
  };

  const cleanFilter = (filter) => {
    console.log(filter);
    setFilter(filter);
    search(0,rowsPerPage);

  }

  const searchFilter = (filter) => {
    console.log(filter);
    setFilter(filter);
    search(0,rowsPerPage);
  }


  const search = (page,size) => {
    setPage(page);
    setRowsPerPage(size);
    service.search(page,size,filter, (data, error) => {
        if(data){
          console.log(data);
          setRows(data.data);
          setRowsTotal(data.total);
        } else {
          setRows([{}]);
          setRowsTotal(0);
        }
    });
  }

  useEffect(() => {
    search(0,10);
  }, []);


  const deleteHandler = (id) => {
    service.removeLogic(id, (data, error) => {
        if(data){
          console.log(data);
            search(page,rowsPerPage);
        }
    });
  }

  const eventSelect = (id) => {
     if (editable){
       if (props.gotoEdit) {
          rows.forEach(element => {
              if(element[fieldId] == id){
                  props.gotoEdit(element);
              }
          });
       } else {
          history.push(urledit+id);
       }
     }
  }


  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title={titlePage} classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
            {!hiddenFilter && <Grid container style={{marginBottom: '50px'}}>
              <Filter cleanFilter={cleanFilter} searchFilter={searchFilter} filter={filter} titlebutton={titleButton} estructure={estructureForm}  urlnew={urlnew} hiddenButton={props.hiddenButton?props.hiddenButton:false}/>
            </Grid>}
            {hiddenFilter && <Grid item xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                  <Button component={Link} to={props.urlnew?props.urlnew:"#"} variant="contained" color="secondary" style={{marginLeft:'30px'}}> {titleButton} </Button>
              </Grid>
            }
            <Grid container spacing={3} className={classes.bodycontainer}>
              <CustomPaginationActionsTable rows={rows} fields={fieldstable} fieldId={fieldId} rowsPerPage={10} rowsTotal={rowsTotal} changePage={changePageTable} headers={headersTable} page={page} deleteaction={props.deleteaction != null?props.deleteaction:true} deleteactionHandler={deleteHandler} selectHandler={eventSelect} getDeleteButton={props.getDeleteButton?props.getDeleteButton:null} editable={editable} defaultValues={props.defaultValues?props.defaultValues:{}} paintCell={props.paintCell ? props.paintCell : {}} moreActions={props.moreActions}/>
              {haveother && othersform}
            </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
