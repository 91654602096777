import React, { useState, useEffect }   from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles,drawerWidth } from "../../layout/styles";
import CustomPaginationActionsTable from "../../table/Table"
import Filter from "../../filter/Filter"
import {rentallensService as service, typeLensService, markService, manufactureService, serieService, diameterService, focalService, tstopService, minfocusService, lengthService, weightService,rentalfamilyService} from "../../../services"


const initFilter =  {
    family: null,
    attrtype : "",
    attrmark: "",
    serie: "",
    manufacture: null,
    attrfocal: null,
    attrtstop: null,
    attrweight: null,
    attrfrontDimeter: null,
    attrminFocus: null,
    attrlength: null,
    coberture: null,
    mount: null,
    year: null,
};

export default function Lens() {
  const history = useHistory();
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filter, setFilter] = React.useState(initFilter);
  const fieldstable = ["family.name","lens.focal.name","lens.serie.name"];
  const headersTable =  ["Familia","Focal","Serie"];

  const [open, setOpen] = React.useState(true);
  const [rows,setRows] = React.useState([{}]);
  const [rowsTotal,setRowsTotal] = React.useState(0);


  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const changePageTable = (page,size) => {
    search(page,size);
  };

  const cleanFilter = (filter) => {
    console.log(filter);
    setFilter(filter);
    search(0,rowsPerPage);

  }

  const searchFilter = (filter) => {
    console.log(filter);
    setFilter(filter);
    search(0,rowsPerPage);
  }



  const createEstructureFilter =  [
      {
        type:"autocomplete",
        name: "family",
        label: "Familia",
        service: rentalfamilyService.combo,
        col: 4
       },
       {
         type:"autocomplete",
         name: "attrtype",
         label: "Tipo",
         service: typeLensService.combo,
         col: 4
        },
        {
          type:"autocomplete",
          name: "attrmark",
          label: "Familia",
          service: markService.combo,
          col: 4
         },
         {
           type:"autocomplete",
           name: "manufacture",
           label: "Fabricante",
           service: manufactureService.combo,
           col: 4
          },
         {
           type:"autocomplete",
           name: "serie",
           label: "Serie",
           service: serieService.combo,
           col: 4
          },
         {
           type:"autocomplete",
           name: "attrfocal",
           label: "Focal",
           service: focalService.combo,
           col: 4
          },
         {
           type:"autocomplete",
           name: "attrtstop",
           label: "T.Stop",
           service: tstopService.combo,
           col: 4
          },
         {
           type:"autocomplete",
           name: "attrweight",
           label: "Peso",
           service: weightService.combo,
           col: 4
          },
         {
           type:"autocomplete",
           name: "attrfrontDimeter",
           label: "Diam. Frontal",
           service: diameterService.combo,
           col: 4
          },
         {
           type:"autocomplete",
           name: "attrminFocus",
           label: "Min. Foc.",
           service: minfocusService.combo,
           col: 4
          },
         {
           type:"autocomplete",
           name: "attrlength",
           label: "Tamaño",
           service: lengthService.combo,
           col: 4
          },
         {
           type:"input",
           name: "coberture",
           label: "Cobertura",
           col: 4
          },
         {
           type:"input",
           name: "mount",
           label: "Montura",
           col: 4
          },
         {
           type:"input",
           name: "year",
           label: "Año",
           col: 4
          },
    ];

    const search = (page,size) => {
      setPage(page);
      setRowsPerPage(size);
      service.search(page,size,filter, (data, error) => {
          if(data){
            console.log(data);
            setRows(data.data);
            setRowsTotal(data.total);
          } else {
            setRows([{}]);
            setRowsTotal(0);
          }
      });
    }

  useEffect(() => {
    search(0,10);
  }, []);


  const deleteHandler = (id) => {
    service.remove(id, (data, error) => {
        if(data){
          console.log(data);
            search(page,rowsPerPage);
        }
    });
  }

  const eventSelect = (id) => {
      console.log("Selecciona el id " + id);
      var idfamily = -1
      for (var row of rows) {
        if (row["id"] == id){
          idfamily = row["family"]["id"];
          break;
        }
      }
      if(idfamily > 0){
        history.push('/rentals/lens/'+idfamily);
      }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title="Lentes de rentals" classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container style={{marginBottom: '50px'}}>
            <Filter cleanFilter={cleanFilter} searchFilter={searchFilter} filter={filter}  estructure={createEstructureFilter} titlebutton={'Añadir lente a rental'} urlnew={'/rentals/lens/new'}/>
          </Grid>
          <Grid container spacing={3} className={classes.bodycontainer}>
            <CustomPaginationActionsTable rows={rows} fields={fieldstable} fieldId={"id"} rowsPerPage={10} rowsTotal={rowsTotal} changePage={changePageTable} headers={headersTable} page={page}  deleteaction={true} deleteactionHandler={deleteHandler} selectHandler={eventSelect}/>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
