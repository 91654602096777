import BaseService from './_base.service';

export const forocomplaintService = {
    page,
    edit,
    search,
    create,
    one,
    remove,
    removeLogic
};

function page(page,size, callback) {
    return BaseService.get("/foro/complaint/page/"+page+"/"+size, callback, true)
}
function search(page,size,filter, callback) {
    return BaseService.post("/foro/complaint/page/"+page+"/"+size,filter, callback, true)
}
function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/foro/complaint/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/foro/complaint/", body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/foro/complaint/one/"+id, callback);
}

function remove(id,callback) {
    return BaseService.delete("/foro/complaint/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete("/foro/complaint/"+id+"/logic", callback);
}
