import React, { useState, useEffect }   from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudTable from "../../crud/CrudTable"
import {dealerService as service, countryService, cityService, dealerfamilyService, dealerViewService} from "../../../services"
import ExploitListData from '../../exploitdata/ExploitListData';

const initFilter =  {
    name : "",
    email: "",
    phone: "",
    web: "",
    country: null,
    city: null,
    family: null,
};


export default function Dealer() {
  const [filter, setFilter] = React.useState(initFilter);
  const fieldstable = ["name","family.name","city.name","city.state.country.name","address","mail","web","phone","numTotal", "numList","numView"];
  const headersTable =  ["Nombre","Familia","Ciudad","País","Dirección","Email","Web","Tlf.", "Vistas Totales", "Vis. Listado", "Vis. Perfil"];

  const createEstructureFilter =  [
       {
         type:"input",
         name: "name",
         label: "Nombre",
         col: 4
        },
        {
         type:"input",
         name: "email",
         label: "Email",
         col: 4
        },
        {
         type:"input",
         name: "phone",
         label: "Tlf.",
         col: 4
        },
        {
         type:"input",
         name: "web",
         label: "Web",
         col: 4
        },
        {
         type:"autocomplete",
         name: "family",
         label: "Familia",
         service: dealerfamilyService.combo,
         col: 4
        },
        {
         type:"autocomplete",
         name: "city",
         label: "Ciudad",
         service: cityService.combo,
         col: 4
        },
        {
         type:"autocomplete",
         name: "country",
         label: "País",
         service: countryService.combo,
         col: 4
        }
    ];


  return (
    <CrudTable
          service={service}
          titlePage={"Dealers"}
          titleButton={"Crear dealers"}
          urledit={"/dealers/"}
          urlnew={"/dealers/new/"}
          filter={initFilter}
          fieldstable={fieldstable}
          headersTable={headersTable}
          estructureForm={createEstructureFilter}
          haveother={true}
          othersform={<ExploitListData service={dealerViewService} />}
      />
  );
}
