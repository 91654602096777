import React, { useState, useEffect }   from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudTable from "../../crud/CrudTable"
import {lensService as service, typeLensService, markService, manufactureService, serieService, diameterService, focalService, tstopService, minfocusService, lengthService, weightService} from "../../../services"



const initFilter =  {
    attrtype : "",
    attrmark: "",
    serie: "",
    manufacture: null,
    attrfocal: null,
    attrtstop: null,
    attrweight: null,
    attrfrontDimeter: null,
    attrminFocus: null,
    attrlength: null,
    coberture: null,
    mount: null,
    year: null,
};

export default function Lens() {
  const [filter, setFilter] = React.useState(initFilter);
  const fieldstable = ["serie.typeLens.name","serie.mark.name","serie.manufacture.name","serie.name","focal.name","tStop.name","weight.name","frontDimeter.name","minFocus.name","length.name","coberture","mount","year"];
  const headersTable =  ["Tipo","Familia","Fabricante","Serie","Focal","T. Stop","Peso","Diametro Frontal","Min Focus","Largo","Cobertura","Montura","Año"];


  const createEstructureFilter =  [
       {
         type:"autocomplete",
         name: "attrtype",
         label: "Tipo",
         service: typeLensService.combo,
         col: 4
        },
        {
          type:"autocomplete",
          name: "attrmark",
          label: "Familia",
          service: markService.combo,
          col: 4
         },
         {
           type:"autocomplete",
           name: "manufacture",
           label: "Fabricante",
           service: manufactureService.combo,
           col: 4
          },
         {
           type:"autocomplete",
           name: "serie",
           label: "Serie",
           service: serieService.combo,
           col: 4
          },
         {
           type:"autocomplete",
           name: "attrfocal",
           label: "Focal",
           service: focalService.combo,
           col: 4
          },
         {
           type:"autocomplete",
           name: "attrtstop",
           label: "T.Stop",
           service: tstopService.combo,
           col: 4
          },
         {
           type:"autocomplete",
           name: "attrweight",
           label: "Peso",
           service: weightService.combo,
           col: 4
          },
         {
           type:"autocomplete",
           name: "attrfrontDimeter",
           label: "Diam. Frontal",
           service: diameterService.combo,
           col: 4
          },
         {
           type:"autocomplete",
           name: "attrminFocus",
           label: "Min. Foc.",
           service: minfocusService.combo,
           col: 4
          },
         {
           type:"autocomplete",
           name: "attrlength",
           label: "Tamaño",
           service: lengthService.combo,
           col: 4
          },
         {
           type:"input",
           name: "coberture",
           label: "Cobertura",
           col: 4
          },
         {
           type:"input",
           name: "mount",
           label: "Montura",
           col: 4
          },
         {
           type:"input",
           name: "year",
           label: "Año",
           col: 4
          },
    ];

  return (
      <CrudTable
            service={service}
            titlePage={"Lentes"}
            titleButton={"Crear Lente"}
            urledit={"/lens/"}
            urlnew={"/lens/new/"}
            filter={initFilter}
            fieldstable={fieldstable}
            headersTable={headersTable}
            estructureForm={createEstructureFilter}
        />
  );
}
