import React, { useState, useEffect }   from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudTable from "../../crud/CrudTable"
import {categoryblogService as service} from "../../../services"

const initFilter =  {
    name: "",
};


export default function Categoryblog() {
  const [filter, setFilter] = React.useState(initFilter);
  const fieldstable = ["name"];
  const headersTable =  ["Nombre"];

  const createEstructureFilter =  [
       {
         type:"input",
         name: "name",
         label: "Nombre",
         col: 3
        },
    ];


  return (
      <CrudTable
            service={service}
            titlePage={"Categoría blog"}
            titleButton={"Añadir catgeoria"}
            urledit={"/categoryblog/"}
            urlnew={"/categoryblog/new/"}
            filter={initFilter}
            fieldstable={fieldstable}
            headersTable={headersTable}
            estructureForm={createEstructureFilter}
        />
  
  );
}
