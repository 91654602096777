import React, { useState, useEffect }  from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles,drawerWidth } from "../../layout/styles";
import CustomPaginationActionsTable from "../../table/Table"
import Filter from "../../filter/Filter"
import {foroService as service,typeLensService, markService, manufactureService} from "../../../services"

const initFilter =  {
    title: "",
    nameuser : "",
    emailuser : "",
    nameserie : "",
    attrtype : "",
    attrmark: "",
    manufacture: null,
};


export default function Foro() {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [page, setPage] = React.useState(0);
  const [filter, setFilter] = React.useState(initFilter);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const fieldstable = ["title","message","user.email",];
  const headersTable =  ["Titulo","Mensaje","Usuario"];

  const [open, setOpen] = React.useState(true);
  const [rows,setRows] = React.useState([{}]);
  const [rowsTotal,setRowsTotal] = React.useState(0);


  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const changePageTable = (page,size) => {
    search(page,size);
  };

  const cleanFilter = (filter) => {
    console.log(filter);
    setFilter(filter);
    search(0,rowsPerPage);

  }

  const searchFilter = (filter) => {
    console.log(filter);
    setFilter(filter);
    search(0,rowsPerPage);
  }


  const createEstructureFilter =  [
       {
         type:"input",
         name: "title",
         label: "Titulo",
         col: 4
        },
       {
         type:"input",
         name: "nameuser",
         label: "Nombre usuario",
         col: 4
        },
       {
         type:"input",
         name: "emailuser",
         label: "Email usuario",
         col: 4
        },
       {
         type:"input",
         name: "nameserie",
         label: "Nombre Serie",
         col: 4
        },
       {
         type:"autocomplete",
         name: "attrtype",
         label: "Tipo",
         service: typeLensService.combo,
         col: 4
        },
        {
          type:"autocomplete",
          name: "attrmark",
          label: "Familia",
          service: markService.combo,
          col: 4
         },
         {
           type:"autocomplete",
           name: "manufacture",
           label: "Fabricante",
           service: manufactureService.combo,
           col: 4
          }
    ];

  const search = (page,size) => {
    setPage(page);
    setRowsPerPage(size);
    service.search(page,size,filter, (data, error) => {
        if(data){
          console.log(data);
          setRows(data.data);
          setRowsTotal(data.total);
        } else {
          setRows([{}]);
          setRowsTotal(0);
        }
    });
  }

  useEffect(() => {
    search(0,10);
  }, []);

  const deleteHandler = (id) => {
    service.remove(id, (data, error) => {
        if(data){
          console.log(data);
            search(page,rowsPerPage);
        }
    });
  }


  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title="Mensajes del foro" classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container style={{marginBottom: '50px'}}>
            <Filter cleanFilter={cleanFilter} searchFilter={searchFilter} filter={filter}  estructure={createEstructureFilter} titlebutton={'Añadir foro'} />
          </Grid>
          <Grid container spacing={3} className={classes.bodycontainer}>
            <CustomPaginationActionsTable rows={rows} fields={fieldstable} fieldId={"id"} rowsPerPage={10} rowsTotal={rowsTotal} changePage={changePageTable} headers={headersTable} page={page} deleteaction={true} deleteactionHandler={deleteHandler}/>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
