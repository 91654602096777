import BaseService from './_base.service';

export const tagService = {
    page,
    edit,
    create,
    one,
    remove,
    search,
    removeLogic,
    combo
};

function page(page,size, callback) {
    return BaseService.get("/tag/page/"+page+"/"+size, callback, true)
}

function search(page,size,filter, callback) {
    return BaseService.post("/tag/page/"+page+"/"+size,filter, callback, true)
}
function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/tag/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/tag/", body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/tag/one/"+id, callback);
}

function remove(id,callback) {
    return BaseService.delete("/tag/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete("/tag/"+id+"/logic", callback);
}

function combo(callback) {
    return BaseService.get("/tag/combo/", callback, true)
}