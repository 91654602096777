import React, { useState, useEffect }   from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudTable from "../../crud/CrudTable"
import {manufactureService as service, typeLensService, markService} from "../../../services"


const initFilter =  {
    name : "",
    description: "",
    web: "",
    email: ""
};


export default function Manufacturer() {
  const [filter, setFilter] = React.useState(initFilter);
  const fieldstable = ["image","name","email","web"];
  const headersTable =  ["#","Nombre","Email","Web"];

  const createEstructureFilter =  [
      {
        type:"autocomplete",
        name: "types",
        label: "Tipo",
        service: typeLensService.combo,
        col: 4
       },
       {
         type:"autocomplete",
         name: "mark",
         label: "Familia",
         service: markService.combo,
         col: 4
        },
       {
         type:"input",
         name: "name",
         label: "Nombre",
         col: 4
        },
       {
         type:"input",
         name: "description",
         label: "Descripción",
         col: 4
        },
       {
         type:"input",
         name: "web",
         label: "Web",
         col: 4
        },
       {
         type:"input",
         name: "email",
         label: "Email",
         col: 4
        },
    ];

  return (
      <CrudTable
            service={service}
            titlePage={"Fabricantes"}
            titleButton={"Crear Fabricante"}
            urledit={"/manufactures/"}
            urlnew={"/manufactures/new/"}
            filter={initFilter}
            fieldstable={fieldstable}
            headersTable={headersTable}
            estructureForm={createEstructureFilter}
        />
  );
}
