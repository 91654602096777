import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles, drawerWidth } from '../../layout/styles';
import CustomPaginationActionsTable from '../../table/Table';
import Filter from '../../filter/Filter';
import Form from '../../form/Form';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import { orderproductService as service, countryService } from '../../../services';
import ExploitIndividualData from '../../exploitdata/ExploitIndividualData';
import ExploitContactIndividualData from '../../exploitdata/ExploitContactIndividualData';

const initElement = {
      product: { id: null, title: null },
      from: { id: null, name: null, email: null },
      to: { id: null, name: null, email: null },
      pickupName: '',
      pickupStreet: '',
      pickupPostalcode: '',
      pickupCity: '',
      pickupState: '',
      pickupCountry: { id: null, name: null },
      pickupPhone: '',
      deliveryName: '',
      deliveryStreet: '',
      deliveryPostalcode: '',
      deliveryCity: '',
      deliveryState: '',
      deliveryCountry: { id: null, name: null },
      deliveryPhone: '',
      price: 0.0,
      priceDelivery: 0.0,
      totalDelivery: 0.0,
      state: '',
      isPay: false,
};

export default function FormOrder() {
      const history = useHistory();
      let { id } = useParams();
      const classes = useStyles();
      const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
      const [objectform, setObjectform] = React.useState({ ...initElement });
      const [titleWindow, setTitleWindow] = React.useState(id && id > 0 ? 'Editar producto' : 'Crear producto');
      const [idRow, setIdrow] = React.useState(id);
      const [idSerie, setIdSerie] = React.useState(-1);
      const [cities, setCities] = React.useState([]);
      const [reload, setReload] = React.useState(false);

      const [open, setOpen] = React.useState(true);
      const [rows, setRows] = React.useState([{}]);

      const handleDrawerOpen = () => {
            setOpen(true);
      };
      const handleDrawerClose = () => {
            setOpen(false);
      };

      const [estructureform, setEstructureform] = React.useState([
            {
                  type: 'input',
                  name: 'product.title',
                  label: 'Producto',
                  readonly: true,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'from.name',
                  label: 'Vendido por',
                  readonly: true,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'from.email',
                  label: 'Email del vendedor',
                  readonly: true,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'to.name',
                  label: 'Comprado por',
                  readonly: true,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'to.email',
                  label: 'Email del comprador',
                  readonly: true,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'totalDelivery',
                  label: 'Total',
                  readonly: true,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'price',
                  label: 'Precio producto',
                  readonly: true,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'priceDelivery',
                  label: 'Gastos de envio',
                  readonly: true,
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'state',
                  label: 'Estado',
                  options: [
                        { key: 'PENDING_PAY', name: 'Pendiente de pago' },
                        { key: 'PAY_COMPLETE', name: 'Pagado' },
                        { key: 'IN_ROUTE', name: 'Enviado' },
                        { key: 'DELIVERED', name: 'Entregado' },
                        { key: 'COMPLETE', name: 'Completado' },
                        { key: 'CANCEL', name: 'Cancelado' },
                        { key: 'WITHISSUE', name: 'Con incidencia' },
                  ],
                  col: 4,
            },
            {
                  type: 'title',
                  name: 'titlepick',
                  label: 'Dirección de recogida',
                  readonly: true,
                  col: 12,
            },
            {
                  type: 'input',
                  name: 'pickupStreet',
                  label: 'Calle',
                  col: 8,
            },
            {
                  type: 'input',
                  name: 'pickupPostalcode',
                  label: 'Código postal',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'pickupCity',
                  label: 'Ciudad',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'pickupState',
                  label: 'Provincia/Estado',
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'pickupCountry.id',
                  service: countryService.combo,
                  label: 'País',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'pickupSPhone',
                  label: 'Tlf.',
                  col: 4,
            },
            {
                  type: 'title',
                  name: 'titlepick',
                  label: 'Dirección de envio',
                  readonly: true,
                  col: 12,
            },
            {
                  type: 'input',
                  name: 'deliveryName',
                  label: 'Nombre del destinatario',
                  col: 12,
            },
            {
                  type: 'input',
                  name: 'deliveryStreet',
                  label: 'Calle',
                  col: 8,
            },
            {
                  type: 'input',
                  name: 'deliveryPostalcode',
                  label: 'Código postal',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'deliveryCity',
                  label: 'Ciudad',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'deliveryState',
                  label: 'Provincia/Estado',
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'deliveryCountry.id',
                  service: countryService.combo,
                  label: 'País',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'deliveryPhone',
                  label: 'Tlf.',
                  col: 4,
            },
      ]);

      useEffect(() => {
            if (idRow && idRow > 0) {
                  searchOne();
            }
      }, []);

      const searchOne = function () {
            service.one(idRow, (data, error) => {
                  if (data && data.data) {
                        var element = data.data;
                        let objectedit = { ...element };

                        if (objectedit.price) {
                              objectedit.price = objectedit.price.toFixed(2);
                        }

                        if (objectedit.totalDelivery) {
                              objectedit.totalDelivery = objectedit.totalDelivery.toFixed(2);
                        }

                        if (objectedit.priceDelivery) {
                              objectedit.priceDelivery = objectedit.priceDelivery.toFixed(2);
                        }

                        setObjectform(objectedit);
                        setReload(true);
                  }
            });
      };

      const cancelForm = (object) => {
            history.push('/marketplace');
      };

      const saveForm = (object) => {
            if (isValid(object)) {
                  var aux = { ...object };
                  if (idRow && idRow > 0) {
                        aux['id'] = id;
                        update(aux, false);
                  } else {
                        create(aux, false);
                  }
            }
      };

      const create = (object, cleanSerie) => {
            service.create(object, (data, error) => {
                  if (data) {
                        setIdrow(data.data.id);
                        history.push('/marketplace/' + data.data.id);
                  }
            });
      };

      const update = (object, cleanSerie) => {
            service.edit(id, object, (data, error) => {
                  if (data) {
                  }
            });
      };

      const isValid = function (object) {
            return true;
      };

      const cleanElementsBeforeSave = function (object) {
            let newObj = JSON.parse(JSON.stringify(object));
            return newObj;
      };

      return (
            <div className={classes.root}>
                  <CssBaseline />
                  <Header title={titleWindow} classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
                  <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
                  <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        <Container maxWidth="lg" className={classes.container}>
                              <Grid container spacing={3} className={classes.bodycontainer}>
                                    <Grid item sm={12} xs={12} style={{ textAlign: 'right', marginBottom: '10px' }}>
                                          <Form objectform={objectform} fields={estructureform} searchFilter={saveForm} cleanFilter={cancelForm} labelsave="Guardar" labelcancel="Cancelar" reload={reload} />
                                    </Grid>
                              </Grid>

                              <Box pt={4}>
                                    <Copyright />
                              </Box>
                        </Container>
                  </main>
            </div>
      );
}
