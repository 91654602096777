import BaseService from './_base.service';

export const confighomeService = {
    edit,
    one,
    home,
    edithome
};

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/confighome/"+id, body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/confighome/one/"+id, callback);
}

function home(id,callback) {
    return BaseService.get("/confighome/one/"+id, callback);
}

function edithome(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/confighome/"+id, body, callback, true)
}
