import BaseService from './_base.service';

export const couponService = {
    page,
    combo,
    search,
    edit,
    create,
    one,
    remove,
    removeLogic
};

function page(page,size, callback) {
    return BaseService.get("/coupon/page/"+page+"/"+size, callback, true)
}
function search(page,size,filter, callback) {
    return BaseService.post("/coupon/page/"+page+"/"+size,filter, callback, true)
}
function combo(callback) {
    return BaseService.get("/coupon/combo/", callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/coupon/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/coupon/", body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/coupon/one/"+id, callback);
}

function remove(id,callback) {
    return BaseService.delete("/coupon/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete("/coupon/"+id+"/logic", callback);
}
