import BaseService from './_base.service';

export const serieViewService = {
    exploitDataDay,
    exploitDataWeek,
    exploitDatayear,
    exploitdatacountry,
    exploitDataCountryWeek,
    exploitDataCountryMonth,
    exploitDataCountryyear,
    moreViewTodayGeneral,
    moreViewTodayProfile,
    moreViewWeekGeneral,
    moreViewWeekProfile,
    moreViewMonthGeneral,
    moreViewMonthProfile,
    moreViewCountryTodayGeneral,
    moreViewCountryTodayProfile,
    moreViewCountryWeekGeneral,
    moreViewCountryWeekProfile,
    moreViewCountryMonthGeneral,
    moreViewCountryMonthProfile,
    stadisticsUserDay,
    stadisticsUserWeek,
    stadisticsUserMonth,
    exploitdatausergeneralday,
    exploitdatausergeneralweek,
    exploitdatausergeneralmonth
};

const URL_BASE = "/serie"

function exploitDataDay(date,id, callback) {
    return BaseService.get(URL_BASE+"/exploitdata/day/"+date+"/"+id, callback, true)
}

function exploitDataWeek(month,year,id, callback) {
    return BaseService.get(URL_BASE+"/exploitdata/week/"+month+"/"+year+"/"+id, callback, true)
}

function exploitDatayear(year,id, callback) {
    return BaseService.get(URL_BASE+"/exploitdata/year/"+year+"/"+id, callback, true)
}

function exploitdatacountry(id, callback) {
    return BaseService.get(URL_BASE+"/exploitdatacountry/"+id, callback, true)
}

function exploitDataCountryWeek(date,id, callback) {
    return BaseService.get(URL_BASE+"/exploitdatacountry/day/"+date+"/"+id, callback, true)
}

function exploitDataCountryMonth(month,year,id, callback) {
    return BaseService.get(URL_BASE+"/exploitdatacountry/month/"+month+"/"+year+"/"+id, callback, true)
}


function exploitDataCountryyear(year,id, callback) {
    return BaseService.get(URL_BASE+"/exploitdatacountry/year/"+year+"/"+id, callback, true)
}

function stadisticsUserDay(date,id, callback) {
    return BaseService.get(URL_BASE+"/exploitdatauser/day/"+date+"/"+id, callback, true)
}

function stadisticsUserWeek(month,year,id, callback) {
    return BaseService.get(URL_BASE+"/exploitdatauser/week/"+month+"/"+year+"/"+id, callback, true)
}

function stadisticsUserMonth(year,id, callback) {
    return BaseService.get(URL_BASE+"/exploitdatauser/month/"+year+"/"+id, callback, true)
}



/** LIST */

function moreViewTodayGeneral(callback) {
    return BaseService.get(URL_BASE+"/exploitdatamorviewgeneral", callback, true)
}

function moreViewTodayProfile(callback) {
    return BaseService.get(URL_BASE+"/exploitdatamorviewprofile", callback, true)
}


function moreViewWeekGeneral(date,callback) {
    return BaseService.get(URL_BASE+"/exploitdatamorviewgeneral/week/"+date, callback, true)
}


function moreViewWeekProfile(date,callback) {
    return BaseService.get(URL_BASE+"/exploitdatamorviewprofile/week/"+date, callback, true)
}

function moreViewMonthGeneral(month,year, callback) {
    return BaseService.get(URL_BASE+"/exploitdatamorviewgeneral/month/"+month+"/"+year, callback, true)
}

function moreViewMonthProfile(month,year, callback) {
    return BaseService.get(URL_BASE+"/exploitdatamorviewgeneral/month/"+month+"/"+year, callback, true)
}
	


function moreViewCountryTodayGeneral(callback) {
    return BaseService.get(URL_BASE+"/exploitdatamorecountrygeneral", callback, true)
}

function moreViewCountryTodayProfile(callback) {
    return BaseService.get(URL_BASE+"/exploitdatamorecountryprofile", callback, true)
}


function moreViewCountryWeekGeneral(date,callback) {
    return BaseService.get(URL_BASE+"/exploitdatamorecountrygeneral/week/"+date, callback, true)
}


function moreViewCountryWeekProfile(date,callback) {
    return BaseService.get(URL_BASE+"/exploitdatamorecountryprofile/week/"+date, callback, true)
}

function moreViewCountryMonthGeneral(month,year, callback) {
    return BaseService.get(URL_BASE+"/exploitdatamorecountrygeneral/month/"+month+"/"+year, callback, true)
}

function moreViewCountryMonthProfile(month,year, callback) {
    return BaseService.get(URL_BASE+"/exploitdatamorecountryprofile/month/"+month+"/"+year, callback, true)
}
	
function exploitdatausergeneralday(date, callback) {
    return BaseService.get(URL_BASE+"/exploitdatausergeneral/day/"+date, callback, true)
}

function exploitdatausergeneralweek(month,year, callback) {
    return BaseService.get(URL_BASE+"/exploitdatausergeneral/week/"+month+"/"+year, callback, true)
}

function exploitdatausergeneralmonth(year, callback) {
    return BaseService.get(URL_BASE+"/exploitdatausergeneral/month/"+year, callback, true)
}




