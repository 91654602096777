import React, { useState, useEffect }   from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudTable from "../../crud/CrudTable"
import {dealeruserService as service, dealerfamilyService,suscriptionService} from "../../../services"

const initFilter =  {
    name : "",
    email: "",
    phone: "",
    suscription: null,
    familydealer: null
};


export default function Users() {
  const [filter, setFilter] = React.useState(initFilter);
  const fieldstable = ["name","email","suscriptionType","suscriptionActive","suscriptionToken","familyRental.name"];
  const headersTable =  ["Nombre","Email","Tipo Suscripcion","Activa","Token stripe","Familia"];


      const createEstructureFilter =  [
         {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 4

         },
         {
          type:"input",
          name: "email",
          label: "Email",
          col: 4

        },
        {
          type:"input",
          name: "phone",
          label: "Tlf.",
          col: 4
         },
         {
           type:"autocomplete",
           name: "suscription",
           label: "Tipos suscripción",
           service: suscriptionService.combo,
           col: 4
          },
          {
            type:"autocomplete",
            name: "familydealer",
            label: "Familias de Dealer",
            service: dealerfamilyService.combo,
            col: 4
           }
      ];



  return (
    <CrudTable
          service={service}
          titlePage={"Usuarios con acceso a dealers"}
          titleButton={"Añadir familia"}
          urledit={"/dealers/family/"}
          urlnew={"/dealers/family/new/"}
          filter={initFilter}
          editable={false}
          hiddenButton={true}
          fieldstable={fieldstable}
          headersTable={headersTable}
          estructureForm={createEstructureFilter}
      />
  );
}
