import React, { useState, useEffect }   from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudTable from "../../crud/CrudTable"
import {dealerfamilyService as service} from "../../../services"


const initFilter =  {
    name : ""
};

export default function Family() {
  const [filter, setFilter] = React.useState(initFilter);
  const fieldstable = ["id","name"];
  const headersTable =  ["#","Nombre"];


  const createEstructureFilter =  [
       {
         type:"input",
         name: "name",
         label: "Nombre",
         col: 4
        }
    ];



  return (
    <CrudTable
          service={service}
          titlePage={"Familias de dealers"}
          titleButton={"Añadir familia"}
          urledit={"/dealers/family/"}
          urlnew={"/dealers/family/new/"}
          filter={initFilter}
          fieldstable={fieldstable}
          headersTable={headersTable}
          estructureForm={createEstructureFilter}
      />
  );
}
