import React, { useState, useEffect }   from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudTable from "../../crud/CrudTable"
import {lengthService as service} from "../../../services"


const initFilter =  {
    name : "",
    lengthMM: "",
    lengthInches: null,
};


export default function Length() {

    const [filter, setFilter] = React.useState(initFilter);
    const fieldstable = ["name","lengthMM","lengthInches"];
    const headersTable =  ["Nombre"," mm","pulgadas"];

    const createEstructureFilter =  [
      {
        type:"input",
        name: "name",
        label: "Nombre",
        col: 4
       },
       {
         type:"input",
         name: "lengthMM",
         label: "Milimetros",
         inputtype:"number",
         step:0.1,
         col: 4
        },
       {
         type:"input",
         name: "lengthInches",
         label: "Pulgadas",
         inputtype:"number",
         step:0.1,
         col: 4
        }
      ];


  return (
      <CrudTable
            service={service}
            titlePage={"Tamaño"}
            titleButton={"Crear Tamaño"}
            urledit={"/lens/length/"}
            urlnew={"/lens/length/new/"}
            filter={initFilter}
            fieldstable={fieldstable}
            headersTable={headersTable}
            estructureForm={createEstructureFilter}
        />
  );
}
