import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudForm from "../../crud/CrudForm"
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {tstopService as service} from "../../../services"

const initElement = {
     name:"",
     min: null,
     max: null,
     minND: null,
     maxND: null,

  };



export default function FormTStop() {
  const classes = useStyles();
  const [objectform, setObjectform] = React.useState({...initElement});


  const estructureform =  [
       {
         type:"input",
         name: "name",
         label: "Nombre",
         col: 6
        },
        {
         type:"input",
         name: "min",
         label: "Min.",
         col: 6,
         inputtype:"number",
         step:0.01
        },
        {
         type:"input",
         name: "max",
         label: "Max.",
         col: 6,
         inputtype:"number",
         step:0.01
       },
        {
         type:"input",
         name: "minND",
         label: "Min. ND",
         col: 6,
         inputtype:"number",
         step:0.01
        },
        {
         type:"input",
         name: "maxND",
         label: "Max. ND",
         col: 6,
         inputtype:"number",
         step:0.01
        }
  ];

  const recoveryElement = function(objectedit,element){
    objectedit['name']=element.name?element.name:"";
    objectedit['min']=element.min?element.min:"";
    objectedit['max']=element.max?element.max:"";
    objectedit['minND']=element.minND?element.minND:"";
    objectedit['maxND']=element.maxND?element.maxND:"";
    return objectedit;
  }


  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      }
      if(!object['min']){
        toast.notify("Debe indicar el mínimo", {position:'top', duration: 10000})
        return false;
      }
      if(!object['max']){
        toast.notify("Debe indicar el máximo", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }


  return (
    <CrudForm
        objectform = {objectform}
        estructureform = {estructureform}
        titleEdit = {'Editar TStop'}
        titleNew = {'Nuevo TStop'}
        urledit={"/lens/tstop/"}
        urlCancel={"/lens/tstop"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />
  );
}
