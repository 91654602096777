import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles,drawerWidth } from "../../layout/styles";
import CustomPaginationActionsTable from "../../table/Table"
import Filter from "../../filter/Filter"
import Form from "../../form/Form"
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {rentalfamilyService as service, creditsRentalService} from "../../../services"

const initElement = {
     name:"",
  };


  const initCredit = {
      rental: null,
      imdb: {
        reference:"",
        title:"hola",
        description:"",
        image:"",
        year:"",
        link:"",
        director:"",
        writer:"",
      }
  }

export default function FormFamilyRental() {
  const history = useHistory();
  let { id } = useParams();
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [objectform, setObjectform] = React.useState({...initElement});
  const [titleWindow, setTitleWindow] = React.useState(id && id > 0? 'Editar familia':'Nueva familia');
  const [idRow, setIdrow] = React.useState(id);
  const [idSerie, setIdSerie] = React.useState(-1);
  const [cities,setCities] = React.useState([]);
  const [open, setOpen] = React.useState(true);
  const [rows,setRows] = React.useState([{}]);


  const [expCred,setExpCred] = React.useState(false);
  const [objectformCredits, setObjectformCredits] = React.useState(initCredit);
  const [rowsCred,setRowsCred] = React.useState([{}]);
  const [rowsTotalCred,setRowsTotalCred] = React.useState(0);
  const [pageCred, setPageCred] = React.useState(0);
  const [rowsPerPageCred, setRowsPerPageCred] = React.useState(10);
  const fieldstableCred = ["imdb.image","imdb.title"];
  const headersTableCred =  ["#","Credit"];


  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };


  const [estructureform,setEstructureform] = React.useState([
         {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 6
          },

    ]);

  useEffect(() => {
      if(idRow && idRow > 0){
        searchOne();
        searchCredits();
      };

  }, []);

  const searchOne = function(){
    service.one(idRow,(data, error) => {
      if(data && data.data){
        var element = data.data;
        let objectedit = JSON.parse(JSON.stringify(objectform));
        objectedit['name']=element.name?element.name:"";
        setObjectform(objectedit);

     }
    });
  }


  const cancelForm = (object) => {
      history.push('/rentals/family');
  }

  const saveForm = (object) => {
       if(isValid(object)) {
         var objToSave = cleanElementsBeforeSave(object);
         if (idRow && idRow > 0){
           objToSave['id'] = id;
           update(objToSave,false);
         } else {
           create(objToSave,false);
         }
       }

  }

  const create = (object,cleanSerie) => {
    service.create(object, (data, error) => {
        if(data){
          setIdrow(data.data.id)
          history.push('/rentals/family/'+data.data.id);
        }
    });
  }

  const update = (object,cleanSerie) => {
    service.edit(id,object, (data, error) => {
        if(data){

        }
    });
  }



  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }

  const cleanElementsBeforeSave = function(object){
    let newObj = JSON.parse(JSON.stringify(object));
    return newObj;
  }

  const handleChangeCredits = () => (event, newExpanded) => {
     setExpCred(!expCred);
   };


   const isValidCred = function(object){
       if(!object['imdb'] || !object['imdb']["reference"]){
         toast.notify("Debe indicar la peli", {position:'top', duration: 10000})
         return false;
       }

       return true;
   }


   const deleteHandlerCredit = (id) => {
     creditsRentalService.remove(id, (data, error) => {
         if(data){
             searchCredits();
         }
     });
   }

   const saveCred = (data) => {
     debugger;
     console.log(data);
     if( isValidCred(data)) {
       data["rental"] = {id:idRow}
       setObjectformCredits(data);
       creditsRentalService.create(data,(data, error) => {
           if(data){
             searchCredits();
           }
       });
     }

   }


   const [estructureformcred,setEstructureformcred] = React.useState([
           {
            type:"imdb",
            name: "imdb",
            label: "Pelicula",
            col: 3
           }
     ]);

   const searchCredits = (id = -1) => {
     if(id == -1){
       id = idRow;
     }
     creditsRentalService.rental(id,(data, error) => {
         if(data){
           setRowsCred(data.data);
           setRowsTotalCred(data.total);
         } else {
           setRowsCred([{}]);
           setRowsTotalCred(0);
         }
     });
   }

   const cleanForm = (data) => {
     console.log(data);
   }



  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title={titleWindow} classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} className={classes.bodycontainer}>
              <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                  <Form objectform={objectform} fields={estructureform}  searchFilter={saveForm} cleanFilter={cancelForm} labelsave="Guardar" labelcancel="Cancelar"/>
              </Grid>
              { idRow > 0 &&   <Grid container>
                  <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                      <h2>Credits </h2>
                  </Grid>
                  <Grid item sm={12} xs={12} >
                    <Accordion expanded={expCred}  onChange={handleChangeCredits()}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography> <label style={{fontSize:'1.3em',fontWeight:'bold'}}>Añadir Credit</label>  </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Form objectform={objectformCredits} fields={estructureformcred}  searchFilter={saveCred} cleanFilter={cleanForm} labelsave="Guardar" labelcancel="Limpiar" cleanInOK={true}/>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                      <CustomPaginationActionsTable rows={rowsCred} fields={fieldstableCred} fieldId={"id"} rowsPerPage={10} rowsTotal={rowsTotalCred} headers={headersTableCred} page={pageCred}  deleteaction={true}  deleteactionHandler={deleteHandlerCredit} />
                  </Grid>
                </Grid>}
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
