import BaseService from './_base.service';

export const productmarketplaceService = {
    page,
    edit,
    search,
    create,
    one,
    remove,
    suscription,
    removeLogic
};

function page(page,size, callback) {
    return BaseService.get("/productmarketplace/page/"+page+"/"+size, callback, true)
}

function search(page,size, filter, callback) {
    return BaseService.post("/productmarketplace/page/"+page+"/"+size,filter, callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/productmarketplace/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/productmarketplace/", body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/productmarketplace/one/"+id, callback);
}

function remove(id,callback) {
    return BaseService.delete("/productmarketplace/"+id, callback);
}

function suscription(page,size, callback) {
    return BaseService.get("/user/productmarketplace/page/"+page+"/"+size, callback, true)
}

function removeLogic(id,callback) {
    return BaseService.delete("/productmarketplace/"+id+"/logic", callback);
}
