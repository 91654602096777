import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useStyles } from '../../layout/styles';
import { Link } from 'react-router-dom';
import logo from '../../../assets/img/logoletras.png';

export default function DisableApp(props) {
      const classes = useStyles();
      return (
            <div className={classes.root}>
                  <CssBaseline />
                  <main className={classes.content}>
                        <img src={logo} style={{ width: '400px', maxWidth: '80%' }} />
                        <p>Estamos realziando cambios en la plataforma, por lo que estarra innacesible durante unas horas. Por favor disculpa la molestias.</p>
                  </main>
            </div>
      );
}
