import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudForm from "../../crud/CrudForm"
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {confighomeService as service} from "../../../services"
import ExploitIndividualData from '../../exploitdata/ExploitIndividualData';

const initElement = {
     image:"",
     haveButton:false,
     haveTitle:false,
     textButton:"",
     textTitle:"",
     alignButton:null,
     alignTitle:null,
     linkButton:"",
     ccsButton:"",
     ccsTitle:"",
     ccsImage:""
  };


export default function FormConfig() {
  const classes = useStyles();
  const [objectform, setObjectform] = React.useState({...initElement});


  const estructureform =  [
          {
            type:"image",
            name: "image",
            label: "Imagen",
            col: 6
          },
          {
            type:"textarea",
            name: "ccsImage",
            label: "CSS para imagen",
            col: 12
          },
          {
            type:"title",
            name: "titlebtn",
            label: "Boton",
            col: 12
          },
          {
            type:"checkbox",
            name: "haveButton",
            label: "Incluir boton",
            col: 4
           },
         {
           type:"input",
           name: "textButton",
           label: "Texto boton",
           col: 4
          },
          {
            type:"input",
            name: "linkButton",
            label: "Enlace boton",
            col: 4
           },
           {
            type:"autocomplete",
            name: "alignButton",
            label: "Alinear boton",
            options:[{key:"TOPCENTER", name:"ARRIBA CENTRADO"},{key:"CENTER", name:"CENTRADO"},{key:"BOTTOMCENTER", name:"ABAJO CENTRADO"},{key:"TOPLEFT", name:"ARRIBA IZQUIERDA"},{key:"TOPRIGHT", name:"ARRIBA IZQUIERDA"},{key:"BOTTOMLEFT", name:"ABAJO IZQUIERDA"},{key:"TOPLEFT", name:"ARRIBA DERECHA"},{key:"BOTTOMRIGTH", name:"ABAJO DERECHA"}],
            col: 4,
          },
          {
            type:"textarea",
            name: "ccsButton",
            label: "CSS para boton",
            col: 12
          },
          {
            type:"title",
            name: "titleTitle",
            label: "Titulo",
            col: 12
          },
          {
            type:"checkbox",
            name: "haveTitle",
            label: "Incluir titulo",
            col: 4
           },
         {
           type:"input",
           name: "textTitle",
           label: "Texto Titulo",
           col: 4
          },
           {
            type:"autocomplete",
            name: "alignTitle",
            label: "Alinear titulo",
            options:[{key:"TOPCENTER", name:"ARRIBA CENTRADO"},{key:"CENTER", name:"CENTRADO"},{key:"BOTTOMCENTER", name:"ABAJO CENTRADO"},{key:"TOPLEFT", name:"ARRIBA IZQUIERDA"},{key:"TOPRIGHT", name:"ARRIBA IZQUIERDA"},{key:"BOTTOMLEFT", name:"ABAJO IZQUIERDA"},{key:"TOPLEFT", name:"ARRIBA DERECHA"},{key:"BOTTOMRIGTH", name:"ABAJO DERECHA"}],
            col: 4,
          },
          {
            type:"textarea",
            name: "ccsTitle",
            label: "CSS para titulo",
            col: 12
          },
           
    ];



  
    const recoveryElement = function(objectedit,element){
      objectedit = {...objectedit, element};
      return objectedit;
    }


  const isValid = function(object){

      return true;
  }

  return (
    <CrudForm
        objectform = {objectform}
        estructureform = {estructureform}
        titleEdit = {'Editar configuración'}
        titleNew = {'Nueva configuración'}
        urledit={"/confighome/1"}
        urlCancel={"/confighome/1"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
        />
      
  );
}
