import React, { useState, useEffect }   from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudTable from "../../crud/CrudTable"
import {cameraService as service, manufactureService} from "../../../services"



const initFilter =  {
    name : "",
    manufacture: null,
};


export default function Camera() {
  const [filter, setFilter] = React.useState(initFilter);
  const fieldstable = ["name","manufacture.name"];
  const headersTable =  ["Nombre","Fabricante"];

    const createEstructureFilter =  [
         {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 4
          },
           {
             type:"autocomplete",
             name: "manufacture",
             label: "Fabricante",
             service: manufactureService.combo,
             col: 4
            }
      ];


  return (
    <CrudTable
          service={service}
          titlePage={"Cámaras"}
          titleButton={"Añadir cámara"}
          urledit={"/cameras/"}
          urlnew={"/cameras/new/"}
          filter={initFilter}
          fieldstable={fieldstable}
          headersTable={headersTable}
          estructureForm={createEstructureFilter}
      />

  );
}
