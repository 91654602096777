import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import CrudForm from "../../crud/CrudForm"
import { useStyles,drawerWidth } from "../../layout/styles";
import {focalService as service} from "../../../services"
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';

const initElement = {
     name:"",
     minSize: null,
     maxSize: null,
     zoom: null,
     zoomNd: null,

  };


export default function FormFocal() {
  const classes = useStyles();
  const [objectform, setObjectform] = React.useState({...initElement});


  const estructureform =  [
       {
         type:"input",
         name: "name",
         label: "Nombre",
         col: 6
        },
        {
         type:"input",
         name: "minSize",
         label: "Tam. Min.",
         col: 6,
         inputtype:"number",
         step:0.01
        },
        {
         type:"input",
         name: "maxSize",
         label: "Tam. Max.",
         col: 6,
         inputtype:"number",
         step:0.01
       },
        {
         type:"input",
         name: "zoom",
         label: "Zoom",
         col: 6,
         inputtype:"number",
         step:0.01
        },
        {
         type:"input",
         name: "zoomNd",
         label: "Zoom ND",
         col: 6,
         inputtype:"number",
         step:0.01
        }
  ];

  const recoveryElement = function(objectedit,element){
    objectedit['name']=element.name?element.name:"";
    objectedit['minSize']=element.minSize?element.minSize:"";
    objectedit['maxSize']=element.maxSize?element.maxSize:"";
    objectedit['zoom']=element.zoom?element.zoom:"";
    objectedit['zoomNd']=element.zoomNd?element.zoomNd:"";
    return objectedit;
  }

  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      }
      if(!object['minSize']){
        toast.notify("Debe indicar el tamaño mínimo", {position:'top', duration: 10000})
        return false;
      }
      if(!object['maxSize']){
        toast.notify("Debe indicar el tamaño máximo", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }


  return (
    <CrudForm
        objectform = {objectform}
        estructureform = {estructureform}
        titleEdit = {'Editar focal'}
        titleNew = {'Nueva focal'}
        urledit={"/lens/focal/"}
        urlCancel={"/lens/focal"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />
  );
}
