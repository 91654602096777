import React, { useState, useEffect }   from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles,drawerWidth } from "../../layout/styles";
import CustomPaginationActionsTable from "../../table/Table"
import Filter from "../../filter/Filter"
import {productmarketplaceService as service,typeLensService, markService, manufactureService, serieService, diameterService, focalService, cameraService, cameraresolutionService, productViewService, productContactService} from "../../../services"
import ExploitListData from '../../exploitdata/ExploitListData';
import ExploitListContactData from '../../exploitdata/ExploitListContactData';

const initFilter =  {
    attrtype: null,
    attrmark : null,
    manufacture: null,
    serie: null,
    attrfocal: null,
    camera: null,
    cameraresolution: null,
    state: null,
    emailuser: "",
    nameuser: "",
    title: "",
    description: ""
};


export default function Products() {
  const history = useHistory();
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [page, setPage] = React.useState(0);
  const [filter, setFilter] = React.useState(initFilter);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const fieldstable = ["user.email","title","productType","camera.name","serie.name","lens.focal.name","price","state","numTotal", "numList","numView","numContact"];
  const headersTable =  ["Usuario","Nombre","Tipo de producto","Camara","Serie","Focals","Precio","Estado", "Vistas Totales", "Vis. Listado", "Vis. Perfil","Nº Contactos"];

  const [open, setOpen] = React.useState(true);
  const [rows,setRows] = React.useState([{}]);
  const [rowsTotal,setRowsTotal] = React.useState(0);


  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const changePageTable = (page,size) => {
    search(page,size);
  };

  const cleanFilter = () => {
    search(0,rowsPerPage);
  }

  const searchFilter = (filter) => {
    search(0,rowsPerPage);
  }


    const createEstructureFilter =  [
         {
           type:"input",
           name: "title",
           label: "Titulo",
           col: 4
          },
         {
           type:"input",
           name: "description",
           label: "Descripción",
           col: 4
          },
         {
           type:"input",
           name: "nameuser",
           label: "Nombre usuario",
           col: 4
          },
         {
           type:"input",
           name: "emailuser",
           label: "Email usuario",
           col: 4
          },

          {
            type:"autocomplete",
            name: "productType",
            label: "Tipo producto",
            options:[{key:"LENSES",name:"Lentes"},{key:"CAMERA",name:"Camaras"},{key:"OTHER",name:"Otros"}],
            col: 4,
          },
         {
           type:"autocomplete",
           name: "attrtype",
           label: "Tipo lente",
           service: typeLensService.combo,
           col: 4
          },
          {
            type:"autocomplete",
            name: "attrmark",
            label: "Familia",
            service: markService.combo,
            col: 4
           },
           {
             type:"autocomplete",
             name: "manufacture",
             label: "Fabricante",
             service: manufactureService.combo,
             col: 4
            },
           {
             type:"autocomplete",
             name: "serie",
             label: "Serie",
             service: serieService.combo,
             col: 4
            },
           {
             type:"autocomplete",
             name: "attrfocal",
             label: "Focal",
             service: focalService.combo,
             col: 4
            },
           {
             type:"autocomplete",
             name: "camera",
             label: "Camara",
             service: cameraService.combo,
             col: 4
            },
           {
             type:"autocomplete",
             name: "cameraresolution",
             label: "Resolución camara",
             service: cameraresolutionService.combo,
             col: 4
            },
           {
             type:"autocomplete",
             name: "state",
             label: "Estado",
             options: [{key:"DRAFT",name:"Borrador"},{key:"RETIRED", name:"Retirado"},{key:"ACTIVE",name:"Activo"},{key:"RESERVED",name:"Reservado"},{key:"SOLD",name:"Vendido"}],
             col: 4
           }
      ];



    const search = (page,size) => {
      if (!filter["state"] || filter["state"] == ""){
         filter["state"] = null;
      }
      setPage(page);
      setRowsPerPage(size);
      service.search(page,size,filter, (data, error) => {
          if(data){
            console.log(data);
            setRows(data.data);
            setRowsTotal(data.total);
          } else {
            setRows([{}]);
            setRowsTotal(0);
          }
      });
    }

  useEffect(() => {
    search(0,10);
  }, []);



  const deleteHandler = (id) => {
    service.remove(id, (data, error) => {
        if(data){
          console.log(data);
            search(page,rowsPerPage);
        }
    });
  }

  const eventSelect = (id) => {
      console.log("Selecciona el id " + id);
      history.push('/marketplace/'+id);
  }


  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title="Lentes y camaras en venta" classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container style={{marginBottom: '50px'}}>
            <Filter cleanFilter={cleanFilter} searchFilter={searchFilter} filter={filter}  estructure={createEstructureFilter} titlebutton={'Añadir Producto'}  urlnew={'/marketplace/new'}/>
          </Grid>
          <Grid container spacing={3} className={classes.bodycontainer}>
            <CustomPaginationActionsTable rows={rows} fields={fieldstable} fieldId={"id"} rowsPerPage={10} rowsTotal={rowsTotal} changePage={changePageTable} headers={headersTable} page={page}  deleteaction={true} deleteactionHandler={deleteHandler}  selectHandler={eventSelect}/>
          
            <ExploitListData service={productViewService} />
            <ExploitListContactData service={productContactService} />
          
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
