import BaseService from './_base.service';

export const manufacturereportService = {
    visitTotalSerie,
    visitTotalSerieByDay,
    visitTotalDay,
    visitTotalSerieCountry,
    rankingManufacture,
    rankingSeriesVisita,
    rankingSeriesBusquedas,
    rankingSeriesBusquedasMarket,
    rankingManufactureMarket,
    excel,
    visitTotalCountry

};

function visitTotalSerie(year,month, serie, callback) {
    return BaseService.get("/manufacturereport/visit/total/serie/"+month+"/"+year+"/"+serie, callback)
}


function visitTotalSerieByDay(year,month, serie, callback) {
    return BaseService.get("/manufacturereport/visit/total/serie/day/"+month+"/"+year+"/"+serie, callback)
}


function visitTotalDay(year,month, serie, callback) {
    return BaseService.get("/manufacturereport/visit/total/day/"+month+"/"+year+"/"+serie, callback)
}


function visitTotalCountry(year,month, serie, callback) {
    return BaseService.get("/manufacturereport/visit/total/country/"+month+"/"+year+"/"+serie, callback)
}

function visitTotalSerieCountry(year,month, serie, callback) {
    return BaseService.get("/manufacturereport/visit/total/serie/country/"+month+"/"+year+"/"+serie, callback)
}

function rankingManufacture(year,month, callback) {
    return BaseService.get("/manufacturereport/ranking/manufacture/"+month+"/"+year, callback)
}


function rankingSeriesVisita(year,month, callback) {
    return BaseService.get("/manufacturereport/ranking/series/visitas/"+month+"/"+year, callback)
}


function rankingSeriesBusquedas(year,month, callback) {
    return BaseService.get("/manufacturereport/ranking/series/busquedas/"+month+"/"+year, callback)
}


function rankingSeriesBusquedasMarket(year,month, callback) {
    return BaseService.get("/manufacturereport/ranking/market/series/busquedas/"+month+"/"+year, callback)
}
function rankingManufactureMarket(year,month, callback) {
    return BaseService.get("/manufacturereport/ranking/market/manufacture/"+month+"/"+year, callback)
}

function excel(year,month, serie,name, callback) {
    return BaseService.getFile("/manufacturereport/excel/"+month+"/"+year+"/"+serie, name, callback)
}

