import React, { useState, useEffect }   from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudTable from "../../crud/CrudTable"
import {creditService as service,typeLensService, markService, manufactureService, serieService, focalService} from "../../../services"


const initFilter =  {
    attrtype : "",
    attrmark: "",
    serie: "",
    manufacture: null,
    attrfocal: null,
    titlefilm: "",
    nameuser: "",
    emailuser: "",
};


export default function Credits() {
  const [filter, setFilter] = React.useState(initFilter);
  const fieldstable = ["user.email","imdb.title","lens.lens.serie.name","lens.lens.focal.name"];
  const headersTable =  ["Usuario","Proyecto","Series","Lentes"];



    const createEstructureFilter =  [
         {
           type:"autocomplete",
           name: "attrtype",
           label: "Tipo",
           service: typeLensService.combo,
           col: 4
          },
          {
            type:"autocomplete",
            name: "attrmark",
            label: "Familia",
            service: markService.combo,
            col: 4
           },
           {
             type:"autocomplete",
             name: "manufacture",
             label: "Fabricante",
             service: manufactureService.combo,
             col: 4
            },
           {
             type:"autocomplete",
             name: "serie",
             label: "Serie",
             service: serieService.combo,
             col: 4
            },
           {
             type:"autocomplete",
             name: "attrfocal",
             label: "Focal",
             service: focalService.combo,
             col: 4
            },
           {
             type:"input",
             name: "titlefilm",
             label: "Titulo",
             col: 4
            },
           {
             type:"input",
             name: "nameuser",
             label: "Nombre usuario",
             col: 4
            },
           {
             type:"input",
             name: "emailuser",
             label: "Email usuario",
             col: 4
            }
      ];


  return (
    <CrudTable
          service={service}
          titlePage={"Credits"}
          titleButton={"Añadir Credits"}
          urledit={"/credits/"}
          urlnew={"/credits/new/"}
          editable={false}
          hiddenButton={true}
          filter={initFilter}
          fieldstable={fieldstable}
          headersTable={headersTable}
          estructureForm={createEstructureFilter}
      />
  );
}
