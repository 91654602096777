
import React, {useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles,drawerWidth } from "../../layout/styles";
import {stadisticsService as service} from "../../../services"
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'


export default function Panelgrah(props) {
  const classes = useStyles();
  const sizecg = "80%"
  const [value1,setValue1] = React.useState(props.value1)
  const [value2,setValue2] = React.useState(props.value2)
  const [valueg,setValueg] = React.useState(props.valueg)



  const circularGraph = (title, title1,title2,value1,value2,valueg,color) => {
    return
  }

  useEffect(() => {
    setValue1(props.value1)
  }, [props.value1]);

  useEffect(() => {
    setValue2(props.value2)
  }, [props.value2]);

  useEffect(() => {
    setValueg(props.valueg)
  }, [props.valueg]);

 return (<Grid item sm={4} xs={12}>
         <Typography  style={{textAlign:"left"}}> <b>{props.title}</b> </Typography>
         <Typography  style={{textAlign:"left"}}> {props.title1} <b>{value1}</b></Typography>
         <Typography  style={{textAlign:"left"}}> {props.title2} <b>{value2}</b></Typography>

         <CircularProgress
               size={sizecg}
               value={valueg}
               thickness={10}
               variant="static"
               color={props.color}
               style={{marginTop:"2em"}}/>
   </Grid>)


}
