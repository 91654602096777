import React, { useState, useEffect }   from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudTable from "../../crud/CrudTable"
import {cameraresolutionService as service, cameraService, manufactureService} from "../../../services"

const initFilter =  {
    name : "",
    manufacture: null,
    camera: null,
    coberture: null
};


export default function Models() {
  const [filter, setFilter] = React.useState(initFilter);
  const fieldstable = ["name","camera.name","camera.manufacture.name","coberture"];
  const headersTable =  ["Resolucion","Camera","Fabricante","Cobertura"];


  const createEstructureFilter =  [
       {
         type:"input",
         name: "name",
         label: "Nombre",
         col: 4
        },
        {
         type:"autocomplete",
         name: "manufacture",
         label: "Fabricante",
         service: manufactureService.combo,
         col: 4
        },
        {
         type:"autocomplete",
         name: "camera",
         label: "Camera",
         service: cameraService.combo,
         col: 4
       },
       {
         type:"input",
         name: "coberture",
         label: "Cobertura",
         inputtype:"number",
         step:0.1,
         col: 4
        },
    ];


  return (
    <CrudTable
          service={service}
          titlePage={"Resolución de camara"}
          titleButton={"Crear resolución"}
          urledit={"/cameras/resolution/"}
          urlnew={"/cameras/resolution/new/"}
          filter={initFilter}
          fieldstable={fieldstable}
          headersTable={headersTable}
          estructureForm={createEstructureFilter}
      />
  
  );
}
