import React, {useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles,drawerWidth } from "../../layout/styles";
import {stadisticsService as service} from "../../../services"
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import Panelgrah from './Panelgrah'



export default function Dashboard() {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const  [crewdataobj, setCrewdataobj] = React.useState({
    today: 5,
    week: 25,
    month: 100,
    valuetoday: 10,
    valueweek: 0,
    valuemonth: 0,

  });

  const  [userdataobj, setUserdataobj] = React.useState({
    today: 5,
    week: 25,
    month: 100,
    valuetoday: 0,
    valueweek: 0,
    valuemonth: 0,
  });

  const  [rentaldataobj, setRentaldataobj] = React.useState({
    today: 1,
    week: 2,
    month: 7,
    valuetoday: 0,
    valueweek: 0,
    valuemonth: 0,
  });

  const  [marketdataobj, setMarketdataobj] = React.useState({
    today: 1,
    week: 2,
    month: 7,
    valuetoday: 0,
    valueweek: 0,
    valuemonth: 0,
  });

  const  [productdataobj, setProductdataobj] = React.useState({
    today: 2,
    week: 4,
    month: 14,
    valuetoday: 0,
    valueweek: 0,
    valuemonth: 0,
  });

  const  [productdatasoldobj, setProductdatasoldobj] = React.useState({
    today: 1,
    week: 3,
    month: 5,
    valuetoday: 0,
    valueweek: 0,
    valuemonth: 0,
  });


  const  [crewdata, setCrewdata] = React.useState({
    today: 0,
    week: 0,
    month: 0,
    total: 0,
  });

  const  [userdata, setUserdata] = React.useState({
    today: 0,
    week: 0,
    month: 0,
    total: 0,
  });

  const  [rentaldata, setRentaldata] = React.useState({
    today: 0,
    week: 0,
    month: 0,
    total: 0,
  });

  const  [marketdata, setMarketdata] = React.useState({
    today: 0,
    week: 0,
    month: 0,
    total: 0,
  });

  const  [productdata, setProductdata] = React.useState({
    today: 0,
    week: 0,
    month: 0,
    total: 0,
  });

  const  [productdatasold, setProductdatasold] = React.useState({
    today: 0,
    week: 0,
    month: 0,
    total: 0,
  });


  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };


  const linearGraph = (title,value) => {
    return <>
        <Grid item sm={4} xs={4}>
              <Typography  style={{textAlign:"left"}}> {title}</Typography>
        </Grid>

        <Grid item sm={8} xs={8}>
            <LinearProgress
                variant="determinate"
                value={value}
                classes={{
                  root: classes.root,
                }}
              />

        </Grid>
    </>
  }




 const panel = (stadistics,stadisticsobj,title, subtitle) => {

 }

 const searchCrew = () => {
   service.crew((data, error) => {
       if(data){
         setCrewdata(data.data)
         var dataObj = {... crewdataobj}
         dataObj.valuetoday =  data.data.today * 100 / dataObj.today;
         if(dataObj.valuetoday > 100){
           dataObj.valuetoday = 100
         } else if(dataObj.valuetoday < 0){
           dataObj.valuetoday = 0
         }
         dataObj.valueweek =  data.data.week * 100 / dataObj.week;
         if(dataObj.valueweek > 100){
           dataObj.valueweek = 100
         } else if(dataObj.valueweek < 0){
           dataObj.valueweek = 0
         }
         dataObj.valuemonth =  data.data.month * 100 / dataObj.month;
         if(dataObj.valuemonth > 100){
           dataObj.valuemonth = 100
         } else if(dataObj.valuemonth < 0){
           dataObj.valuemonth = 0
         }

         setCrewdataobj(dataObj)
       }
   });
 }

 const searchUser= () => {
   service.user((data, error) => {
       if(data){
         setUserdata(currentValueform => ({...currentValueform, ...data.data }))
         var dataObj = {... userdataobj}
         dataObj.valuetoday =  data.data.today * 100 / dataObj.today;
         if(dataObj.valuetoday > 100){
           dataObj.valuetoday = 100
         } else if(dataObj.valuetoday < 0){
           dataObj.valuetoday = 0
         }
         dataObj.valueweek =  data.data.week * 100 / dataObj.week;
         if(dataObj.valueweek > 100){
           dataObj.valueweek = 100
         } else if(dataObj.valueweek < 0){
           dataObj.valueweek = 0
         }
         dataObj.valuemonth =  data.data.month * 100 / dataObj.month;
         if(dataObj.valuemonth > 100){
           dataObj.valuemonth = 100
         } else if(dataObj.valuemonth < 0){
           dataObj.valuemonth = 0
         }

         setUserdataobj(dataObj)
       }
   });
 }


  const searchRental= () => {
    service.rental((data, error) => {
        if(data){
          setRentaldata(currentValueform => ({...currentValueform, ...data.data }))
          var dataObj = {... rentaldataobj}
          dataObj.valuetoday =  data.data.today * 100 / dataObj.today;
          if(dataObj.valuetoday > 100){
            dataObj.valuetoday = 100
          } else if(dataObj.valuetoday < 0){
            dataObj.valuetoday = 0
          }
          dataObj.valueweek =  data.data.week * 100 / dataObj.week;
          if(dataObj.valueweek > 100){
            dataObj.valueweek = 100
          } else if(dataObj.valueweek < 0){
            dataObj.valueweek = 0
          }
          dataObj.valuemonth =  data.data.month * 100 / dataObj.month;
          if(dataObj.valuemonth > 100){
            dataObj.valuemonth = 100
          } else if(dataObj.valuemonth < 0){
            dataObj.valuemonth = 0
          }

          setRentaldataobj(dataObj)
        }
    });
  }

   const searchMarket= () => {
     service.market((data, error) => {
         if(data){
           setMarketdata(currentValueform => ({...currentValueform, ...data.data }))
           var dataObj = {... marketdataobj}
           dataObj.valuetoday =  data.data.today * 100 / dataObj.today;
           if(dataObj.valuetoday > 100){
             dataObj.valuetoday = 100
           } else if(dataObj.valuetoday < 0){
             dataObj.valuetoday = 0
           }
           dataObj.valueweek =  data.data.week * 100 / dataObj.week;
           if(dataObj.valueweek > 100){
             dataObj.valueweek = 100
           } else if(dataObj.valueweek < 0){
             dataObj.valueweek = 0
           }
           dataObj.valuemonth =  data.data.month * 100 / dataObj.month;
           if(dataObj.valuemonth > 100){
             dataObj.valuemonth = 100
           } else if(dataObj.valuemonth < 0){
             dataObj.valuemonth = 0
           }

           setMarketdataobj(dataObj)
         }
     });
   }

    const searchProductSold= () => {
      service.productsold((data, error) => {
          if(data){
            setProductdatasold(currentValueform => ({...currentValueform, ...data.data }))
            var dataObj = {... productdatasoldobj}
            dataObj.valuetoday =  data.data.today * 100 / dataObj.today;
            if(dataObj.valuetoday > 100){
              dataObj.valuetoday = 100
            } else if(dataObj.valuetoday < 0){
              dataObj.valuetoday = 0
            }
            dataObj.valueweek =  data.data.week * 100 / dataObj.week;
            if(dataObj.valueweek > 100){
              dataObj.valueweek = 100
            } else if(dataObj.valueweek < 0){
              dataObj.valueweek = 0
            }
            dataObj.valuemonth =  data.data.month * 100 / dataObj.month;
            if(dataObj.valuemonth > 100){
              dataObj.valuemonth = 100
            } else if(dataObj.valuemonth < 0){
              dataObj.valuemonth = 0
            }

          setProductdatasoldobj(dataObj)
          }
      });
    }

     const searchProductAc= () => {
       service.productactive((data, error) => {
           if(data){
             setProductdata(currentValueform => ({...currentValueform, ...data.data }))
             var dataObj = {... productdataobj}
             dataObj.valuetoday =  data.data.today * 100 / dataObj.today;
             if(dataObj.valuetoday > 100){
               dataObj.valuetoday = 100
             } else if(dataObj.valuetoday < 0){
               dataObj.valuetoday = 0
             }
             dataObj.valueweek =  data.data.week * 100 / dataObj.week;
             if(dataObj.valueweek > 100){
               dataObj.valueweek = 100
             } else if(dataObj.valueweek < 0){
               dataObj.valueweek = 0
             }
             dataObj.valuemonth =  data.data.month * 100 / dataObj.month;
             if(dataObj.valuemonth > 100){
               dataObj.valuemonth = 100
             } else if(dataObj.valuemonth < 0){
               dataObj.valuemonth = 0
             }

              setProductdataobj(dataObj)
           }
       });
     }

 useEffect(() => {
   searchCrew();
   searchUser();
   searchRental();
   searchMarket();
   searchProductAc();
   searchProductAc();
 }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title="Dashboard" classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
                  <Panelgrah
                    stadisticsobj={crewdataobj}
                    stadistics={crewdata}
                    title={"CREW"}
                    subtitle={"Datos y objetivos de CREW"} />


                    <Panelgrah
                      stadisticsobj={userdataobj}
                      stadistics={userdata}
                      title={"Usuarios"}
                      subtitle={"Datos y objetivos de USUARIOS"} />


                    <Panelgrah
                      stadisticsobj={rentaldataobj}
                      stadistics={rentaldata}
                      title={"RENTAL"}
                      subtitle={"Suscripciones activas. Conseguidas o renovadas"} />


                    <Panelgrah
                      stadisticsobj={marketdataobj}
                      stadistics={marketdata}
                      title={"MARKETPLACE"}
                      subtitle={"Suscripciones activas. Conseguidas o renovadas"} />


                    <Panelgrah
                      stadisticsobj={productdataobj}
                      stadistics={productdata}
                      title={"PRODUCTOS ACTIVOS"}
                      subtitle={"Productos activos, dados de alta en ese periodo (Los vendidos ya no aparecen aqui)"} />


                    <Panelgrah
                      stadisticsobj={productdatasoldobj}
                      stadistics={productdatasold}
                      title={"PRODUCTOS VENDIDOS"}
                      subtitle={"Productos vendidos en ese periodo. Marcados por el usuario"} />

          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
