import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CrudForm from "../../crud/CrudForm"
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {reelsService as service, userService, serieService} from "../../../services"

const initElement = {
     title:"",
     message: "",
     user: {id:null, name:""},
     serie: {id:null, name:""},
     youtube: null,
     vimeo: null,
     videopath: null,
     blocked: false,
     deleted: false,
     likes:0
  };




export default function FormReels() {
  const [objectform, setObjectform] = React.useState({...initElement});


  const [estructureform,setEstructureform] = React.useState([
         {
           type:"input",
           name: "title",
           label: "Titulo",
           col: 4
          },
         {
           type:"autocomplete",
           name: "user.id",
           label: "Usuario",
           service: userService.combo,
           readonly: true,
           col: 4
          },
          {
            type:"autocomplete",
            name: "serie.id",
            label: "Serie",
            service: serieService.combo,
            col: 4
           },
          {
           type:"textarea",
           name: "message",
           label: "Descripcion breve",
           col: 12
          },
          {
            type:"autocomplete",
            name: "userAssign.id",
            label: "Usuario asignado",
            service: userService.combo,
            col: 4
           },
          {
           type:"input",
           name: "youtube",
           label: "URL Youtube",
           col: 4
          },
          {
           type:"input",
           name: "vimeo",
           label: "URL Vimeo",
           col: 4
          },
          {
           type:"video",
           name: "youtube|vimeo",
           label: "Video",
           col: 4
          },
          {
           type:"checkbox",
           name: "blocked",
           label: "Bloqueado",
           col: 4,
          },
          {
           type:"checkbox",
           name: "deleted",
           label: "Borrado",
           col: 4,
          },
          {
           type:"input",
           name: "legend",
           label: "Likes",
           inputtype:"number",
           readonly:true,
           col: 4,
          },

    ]);


  const recoveryElement = function(objectedit,element){
    objectedit['title']=element.title?element.title:"";
    objectedit['message']=element.message?element.message:"";
    objectedit['youtube']=element.youtube?element.youtube:null;
    objectedit['vimeo']=element.vimeo?element.vimeo:null;
    objectedit['videopath']=element.videopath?element.videopath:null;
    objectedit['blocked']=element.blocked?element.blocked:false;
    objectedit['blocked']=element.deleted?element.deleted:false;
    objectedit['likes']=element.likes?element.likes:0;
    objectedit['serie']= {id:element.serie && element.serie.id?element.serie.id:null, name: element.serie && element.serie.name?element.serie.name:null};
    objectedit['user']= {id:element.user && element.user.id?element.user.id:null, name: element.user && element.user.name?element.user.name:null};
    return objectedit;
  }

  const isValid = function(object){
      if(!object['title']){
        toast.notify("Debe indicar el titulo", {position:'top', duration: 10000})
        return false;
      }
      if(!object['vimeo'] && !object['youtube'] && !object['videopath']){
        toast.notify("Debe indicar el video", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }


  return (
    <CrudForm
        objectform = {objectform}
        estructureform = {estructureform}
        titleEdit = {'Editar Reels'}
        titleNew = {'Nuevo Reels'}
        urledit={"/reels/"}
        urlCancel={"/reels"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />
  );
}
