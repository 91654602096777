import BaseService from './_base.service';

export const blogService = {
    page,
    edit,
    create,
    one,
    remove,
    search,
    removeLogic
};

function page(page,size, callback) {
    return BaseService.get("/blog/page/"+page+"/"+size, callback, true)
}

function search(page,size,filter, callback) {
    return BaseService.post("/blog/page/"+page+"/"+size,filter, callback, true)
}
function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/blog/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/blog/", body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/blog/one/"+id, callback);
}

function remove(id,callback) {
    return BaseService.delete("/blog/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete("/blog/"+id+"/logic", callback);
}
