import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles,drawerWidth } from "../../layout/styles";
import CustomPaginationActionsTable from "../../table/Table"
import Filter from "../../filter/Filter"
import Form from "../../form/Form"
import toast from 'toasted-notes'
import CheckIcon from '@material-ui/icons/Check';
import 'toasted-notes/src/styles.css';
import Modal from '@material-ui/core/Modal';
import {dealermanufactureService as service, dealerfamilyService , manufactureService} from "../../../services"


  const initFamilyElement = {
     family: {id: -1, name: ""},
  };

  const initSerieElement = {
       nseries:"",
       id:""
    };


  const initFilter =  {
      family: null,
      attrtype : "",
      attrmark: "",
      serie: "",
      manufacture: null,
      attrfocal: null,
      attrtstop: null,
      attrweight: null,
      attrfrontDimeter: null,
      attrminFocus: null,
      attrlength: null,
      coberture: null,
      mount: null,
      year: null,
};


const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
  tablecontainer: {
    boxShadow: "none",
  },
  bodymodal: {
    margin: "auto",
    width: "500px",
    maxWidth: "calc(100vh - 40px)",
    background: "white",
    padding: "5em",
    marginTop: "100px",
    textAlign: "center"
  }
});


export default function FormFamilyManufactureDealer() {
  const classes2 = useStyles2();
  const history = useHistory();
  let { id } = useParams();
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [objectfamilyform,setObjectfamilyform] = React.useState({...initFamilyElement} );


  const [objectserieform,setObjectserieform] = React.useState({...initSerieElement});
  const [titleWindow, setTitleWindow] = React.useState(id && id > 0? 'Editar fabricantes de familia':'');
  const [idRow, setIdrow] = React.useState(id);



  const [filter, setFilter] = React.useState({...initFilter});
  const fieldstable = ["manufacture.name"];
  const headersTable =  ["Fabicante"];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows,setRows] = React.useState([{}]);
  const [rowsTotal,setRowsTotal] = React.useState(0);
  const [selectLens,setSelectLens] = React.useState({});
  const [openSerie, setOpenSerie] = React.useState(false);


  const [filterLens, setFilterLens] = React.useState({...initFilter});
  const fieldstableLens = ["name"];
  const headersTableLens =  ["Fabicante"];
  const [pageLens, setPageLens] = React.useState(0);
  const [rowsPerPageLens, setRowsPerPageLens] = React.useState(10);
  const [rowsLens,setRowsLens] = React.useState([{}]);
  const [rowsTotalLens,setRowsTotalLens] = React.useState(0);

  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };


  const [estructurefamilyform,setEstructurefamilyform] = React.useState([
        {
         type:"autocomplete",
         name: "family.id",
         label: "Familia",
         service: dealerfamilyService.combo,
         col: 12
        }
    ]);


    useEffect(() => {
        if(idRow && idRow > 0){

          //searchOne();
        };

    }, []);


  const isValid = function(object){
      if(!object['family']){
        toast.notify("Debe indicar una familia", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }

  const cleanElementsBeforeSave = function(object){
    let newObj = JSON.parse(JSON.stringify(object));
    return newObj;
  }

  const changePageTable = (page,size) => {
    search(page,size);
  };



  const cleanFilter = (filter) => {
    console.log(filter);
    setFilter(filter);
    search(0,rowsPerPage);

  }

  const searchFilter = (filter) => {
    console.log(filter);
    setFilter(filter);
    search(0,rowsPerPage);
  }




  const createEstructureFilter =  [
         {
           type:"autocomplete",
           name: "manufacture",
           label: "Fabricante",
           service: manufactureService.combo,
           col: 4
          },
    ];


    const createEstructureFilterNone =  [
        {
          type:"input",
          name: "name",
          label: "Nombre",
          col: 4
         }
      ];


    const deleteHandler = (id) => {
      service.remove(id, (data, error) => {
          if(data){
            console.log(data);
              search(page,rowsPerPage);
              searchLens(pageLens,rowsPerPageLens);
          }
      });
    }

    const selectLensHandler = (id) => {
          var lf = {
            family:{
              id:objectfamilyform["family"]["id"]
            },
            manufacture:{
              id:id
            }
          };

          service.create(lf, (data, error) => {
              if(data){
                search(page,rowsPerPage);
                searchLens(pageLens,rowsPerPageLens);
              }
          });
    }

    const search = (page,size,id = -1) => {
      setPage(page);
      setRowsPerPage(size);
      if (objectfamilyform != null && objectfamilyform["family"]['id'] != null){
        service.searchfamily(page,size,objectfamilyform["family"]['id'],filter, (data, error) => {
            if(data){
              console.log(data);
              setRows(data.data);
              setRowsTotal(data.total);
            } else {
              setRows([{}]);
              setRowsTotal(0);
            }
        });
      } else if(id > 0){
        service.searchfamily(page,size,id,filter, (data, error) => {
            if(data){
              console.log(data);
              setRows(data.data);
              setRowsTotal(data.total);
            } else {
              setRows([{}]);
              setRowsTotal(0);
            }
        });
      }

    }
    const changeFamily = (object) => {
         if(isValid(object)) {
           //var objToSave = cleanElementsBeforeSave(object);
           setObjectfamilyform(object);
           console.log(filter);
           setFilter(filter);
           search(0,rowsPerPage);
           searchLens(0,rowsPerPageLens);
         }

    }


    const changePageTableLens = (page,size) => {
      searchLens(page,size);
    };



    const cleanFilterLens = (filter) => {
      console.log(filter);
      setFilterLens(filter);
      searchLens(0,rowsPerPage);

    }

    const searchFilterLens = (filter) => {
      console.log(filter);
      setFilterLens(filter);
      searchLens(0,rowsPerPage);
    }


    const searchLens = (page,size,id = -1) => {
      setPageLens(page);
      setRowsPerPageLens(size);
      if (objectfamilyform != null && objectfamilyform["family"] != null && objectfamilyform["family"]['id'] != null && objectfamilyform["family"]['id'] > 0){
        service.searchNotfamily(page,size,objectfamilyform["family"]['id'],filterLens, (data, error) => {
            if(data){
              console.log(data);
              setRowsLens(data.data);
              setRowsTotalLens(data.total);
            } else {
              setRowsLens([{}]);
              setRowsTotalLens(0);
            }
        });
      } else if(id > 0){
        service.searchNotfamily(page,size,id,filterLens, (data, error) => {
            if(data){
              console.log(data);
              setRowsLens(data.data);
              setRowsTotalLens(data.total);
            } else {
              setRowsLens([{}]);
              setRowsTotalLens(0);
            }
        });
      }

    }

    const eventSelect = (id) => {
        console.log("Selecciona el id " + id);

    }

    const handleCloseSerie = () => {
       setOpenSerie(false);
     };

     const cancelSerie = () => {
        setOpenSerie(false);
     }


   useEffect(() => {
       if(idRow && idRow > 0){
         searchFamily(idRow)
         search(0,rowsPerPage,idRow);
         searchLens(0,rowsPerPageLens,idRow);
       };

   }, []);

   const searchFamily = (id) => {
     dealerfamilyService.one(id,(data, error) => {
         if(data){
           let objectedit = JSON.parse(JSON.stringify(objectfamilyform));
           objectedit["family"] = data.data
           setObjectfamilyform(objectedit)
         }
     })
   }



  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title={titleWindow} classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} className={classes.bodycontainer}>
              <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                  <Form objectform={objectfamilyform} fields={estructurefamilyform} searchFilter={changeFamily} isHiddenCancel={true} labelsave="Seleccionar familia"/>
              </Grid>
              <Grid item sm={6} xs={6} style={{textAlign:'right', marginBottom: '10px'}}>
                <Grid container>
                    <h2>Fabricantes seleccionadas</h2>
                </Grid>
                <Grid container style={{marginBottom: '50px'}}>
                  <Filter cleanFilter={cleanFilter} searchFilter={searchFilter} filter={filter}  estructure={createEstructureFilter} hiddenButton={true} />
                </Grid>
                <Grid container spacing={3} className={classes.bodycontainer}>
                  <CustomPaginationActionsTable rows={rows} fields={fieldstable} fieldId={"id"} rowsPerPage={10} rowsTotal={rowsTotal} changePage={changePageTable} headers={headersTable} page={page}  deleteaction={true} deleteactionHandler={deleteHandler} selectHandler={eventSelect}/>
                </Grid>
              </Grid>
              <Grid item sm={6} xs={6} style={{textAlign:'right', marginBottom: '10px'}}>
                    <Grid container>
                        <h2>Fabricantes no seleccionadas</h2>
                    </Grid>
                    <Grid container style={{marginBottom: '50px'}}>
                      <Filter cleanFilter={cleanFilter} searchFilter={searchFilterLens} filter={filterLens}  estructure={createEstructureFilterNone} hiddenButton={true}/>
                    </Grid>
                    <Grid container spacing={3} className={classes.bodycontainer}>
                      <CustomPaginationActionsTable rows={rowsLens} fields={fieldstableLens} fieldId={"id"} rowsPerPage={10} rowsTotal={rowsTotalLens} changePage={changePageTableLens} headers={headersTableLens} page={pageLens}  deleteaction={true} colorIconDeleteAction={"green"} deleteactionHandler={selectLensHandler} iconDeleteAction={<CheckIcon />} actionQuestion={"¿Deseas añadir este fabricante?"}/>
                    </Grid>
              </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
