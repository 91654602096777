import BaseService from './_base.service';

export const rentallensService = {
    page,
    search,
    searchfamily,
    searchNotfamily,
    edit,
    create,
    one,
    remove,
    removeLogic
};


function page(page,size, callback) {
    return BaseService.get("/rental/family/lens/page/"+page+"/"+size, callback, true)
}
function search(page,size,filter, callback) {
    return BaseService.post("/rental/family/lens/page/"+page+"/"+size,filter, callback, true)
}

function searchfamily(page,size,id,filter, callback) {
    return BaseService.post("/rental/family/lens/"+id+"/page/"+page+"/"+size,filter, callback, true)
}

function searchNotfamily(page,size,id,filter, callback) {
    return BaseService.post("/rental/family/lens/"+id+"/not/page/"+page+"/"+size,filter, callback, true)
}


function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/rental/family/lens/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/rental/family/lens/", body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/rental/family/lens/one/"+id, callback);
}

function remove(id,callback) {
    return BaseService.delete("/rental/family/lens/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete("/rental/family/lens/"+id+"/logic", callback);
}
