import BaseService from './_base.service';

const PATH = "/partners/";

export const partnersService = {
    page,
    edit,
    create,
    one,
    remove,
    search,
    removeLogic,
    combo
};

function page(page,size, callback) {
    return BaseService.get(PATH+"page/"+page+"/"+size, callback, true)
}

function combo(callback) {
    return BaseService.get(PATH+"combo/", callback, true)
}
function search(page,size,filter, callback) {
    return BaseService.post(PATH+"page/"+page+"/"+size,filter, callback, true)
}
function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put(PATH+""+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post(PATH+"", body, callback, true)
}

function one(id,callback) {
    return BaseService.get(PATH+"one/"+id, callback);
}

function remove(id,callback) {
    return BaseService.delete(PATH+""+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete(PATH+""+id+"/logic", callback);
}
