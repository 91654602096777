import BaseService from './_base.service';

export const usersuscriptionService = {
    all,
    edit,
    create,
    one,
    remove,
    search,
    removeLogic
};


function search(page,size,filter, callback) {
    return BaseService.post("/usersuscriptions/page/"+page+"/"+size,filter, callback, true)
}


function all(id, callback) {
    return BaseService.get("/usersuscriptions/user/"+id+"/all/", callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/usersuscriptions/"+id, body, callback, true)
}
function create(iduser,bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/usersuscriptions/user/"+iduser, body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/usersuscriptions/one/"+id, callback);
}

function remove(id,callback) {
    return BaseService.delete("/usersuscriptions/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete("/usersuscriptions/"+id+"/logic", callback);
}
