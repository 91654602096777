import BaseService from './_base.service';

export const productmarketplaceComplaintService = {
    page,
    edit,
    search,
    create,
    one,
    remove,
    removeLogic
};

function page(page,size, callback) {
    return BaseService.get("/productmarketplace/complaint/page/"+page+"/"+size, callback, true)
}
function search(page,size, filter, callback) {
    return BaseService.post("/productmarketplace/complaint/page/"+page+"/"+size,filter, callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/productmarketplace/complaint/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/productmarketplace/complaint/", body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/productmarketplace/complaint/one/"+id, callback);
}

function remove(id,callback) {
    return BaseService.delete("/productmarketplace/complaint/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete("/productmarketplace/complaint/"+id+"/logic", callback);
}
