import React, { useState, useEffect }   from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudTable from "../../crud/CrudTable"
import {serieService as service, typeLensService, markService, manufactureService, serieViewService} from "../../../services"
import ExploitListData from '../../exploitdata/ExploitListData';


const initFilter =  {
    name : "",
    attrtype : "",
    attrmark: "",
    manufacture: null,
};


export default function Serie() {
  const [filter, setFilter] = React.useState(initFilter);
  const fieldstable = ["image","typeLens.name","mark.name","manufacture.name","name"];
  const headersTable =  ["#","Tipo","Familia","Fabricante","Nombre"];

    const createEstructureFilter =  [
         {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 4
          },
         {
           type:"autocomplete",
           name: "attrtype",
           label: "Tipo",
           service: typeLensService.combo,
           col: 4
          },
          {
            type:"autocomplete",
            name: "attrmark",
            label: "Familia",
            service: markService.combo,
            col: 4
           },
           {
             type:"autocomplete",
             name: "manufacture",
             label: "Fabricante",
             service: manufactureService.combo,
             col: 4
            }
      ];



  return (
    <CrudTable
          service={service}
          titlePage={"Serie"}
          titleButton={"Crear Serie"}
          urledit={"/series/"}
          urlnew={"/series/new/"}
          filter={initFilter}
          fieldstable={fieldstable}
          headersTable={headersTable}
          estructureForm={createEstructureFilter}
          haveother={true}
          othersform={<ExploitListData service={serieViewService} />}
      />
  );
}
