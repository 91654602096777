import BaseService from './_base.service';

export const tstopService = {
    page,
    edit,
    search,
    combo,
    create,
    one,
    remove,
    removeLogic
};

function page(page,size, callback) {
    return BaseService.get("/lens/attr/tstop/page/"+page+"/"+size, callback, true)
}
function search(page,size,filter, callback) {
    return BaseService.post("/lens/attr/tstop/page/"+page+"/"+size,filter, callback, true)
}
function combo(callback) {
    return BaseService.get("/lens/attr/tstop/combo/", callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/lens/attr/tstop/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/lens/attr/tstop/", body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/lens/attr/tstop/one/"+id, callback);
}

function remove(id,callback) {
    return BaseService.delete("/lens/attr/tstop/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete("/lens/attr/tstop/"+id+"/logic", callback);
}
