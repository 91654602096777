
import React, {useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles,drawerWidth } from "../../layout/styles";
import PanelgrahGraph from './PanelgrahGraph'


export default function Panelgrah(props) {
  const classes = useStyles();
  const sizecg = "80%"



  useEffect(() => {
     console.log("Entra 11!");
     console.log(props.stadisticsobj);
  }, [props.stadisticsobj]);

  useEffect(() => {
     console.log("Entra 22!");
     console.log(props.stadistics);
  }, [props.stadistics]);


 return (<Grid item sm={6} xs={12}  style={{marginBottom: "4em"}}>
       <Typography  component={"h3"} variant={"h3"} style={{textAlign:"left", borderBottom:"1px solid #ccc", fontSize:"2em", fontWeight:"bold"}}> {props.title}  - ( {props.stadistics.total} )</Typography>
       <Typography  component={"p"} style={{textAlign:"left", fontSize:"1em", marginBottom:"2em"}}> {props.subtitle} </Typography>
         <Grid container spacing={3}>

         <PanelgrahGraph
           title={"Objetivo diario"}
           title1={"Objetivo:"}
           title2={"Conseguido:"}
           value1={props.stadisticsobj.today}
           value2={props.stadistics.today}
           valueg={props.stadisticsobj.valuetoday}
           color={"secondary"} />

          <PanelgrahGraph
            title={"Objetivo Semanal"}
            title1={"Objetivo:"}
            title2={"Conseguido:"}
            value1={props.stadisticsobj.week}
            value2={props.stadistics.week}
            valueg={props.stadisticsobj.valueweek}
            color={"secondary"} />

           <PanelgrahGraph
             title={"Objetivo Mensual"}
             title1={"Objetivo:"}
             title2={"Conseguido:"}
             value1={props.stadisticsobj.month}
             value2={props.stadistics.month}
             valueg={props.stadisticsobj.valuemonth}
             color={"secondary"} />
       </Grid>
 </Grid>)


}
