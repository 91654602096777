import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudForm from "../../crud/CrudForm"
import { useStyles,drawerWidth } from "../../layout/styles";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {partnersService as service, partnerscategoryService} from "../../../services"

const initElement = {
    name:"",
    image:null,
    url: "#",
    order: null,
    category: {id:null,name:""}
  };



export default function FormPartner() {
  let { id } = useParams();
  const classes = useStyles();
  const [objectform, setObjectform] = React.useState({...initElement});


  const [estructureform,setEstructureform] = React.useState([
         {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 4
          },
          {
           type:"autocomplete",
           name: "category.id",
           label: "Categoría",
           service: partnerscategoryService.combo,
           col: 4
          },
          {
           type:"input",
           name: "url",
           label: "Link",
           col: 4,
          },
          {
           type:"input",
           name: "order",
           label: "Orden",
           inputtype:"number",
           col: 4,
          },
          {
          type:"image",
          name: "image",
          label: "Imagen",
          col: 2
          },


    ]);



  const recoveryElement = function(objectedit,element){
    objectedit['name']=element.name?element.name:"";
    objectedit['image']=element.image?element.image:"";
    objectedit['url']=element.url?element.url:"";
    objectedit['order']=element.order?element.order:null;
    objectedit['category']=element.category?element.category:{id:null,name:""};

    return objectedit;
  }



  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      }
      if(!object['category'] || !object['category']['id']){
        toast.notify("Debe indicar la categoría", {position:'top', duration: 10000})
        return false;
      }
      if(!object['image']){
        toast.notify("Debe indicar la imagen", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }

  return (
    <CrudForm
        objectform = {objectform}
        estructureform = {estructureform}
        titleEdit = {'Editar partner'}
        titleNew = {'Nuevo partner'}
        urledit={"/partners/"}
        urlCancel={"/partners"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid} />
  );
}
