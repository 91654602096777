import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudForm from "../../crud/CrudForm"
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {manufactureService as service} from "../../../services"

const initElement = {
     name:"",
     description: "",
     web: "",
     email: "",
     image: "",
     active: true
  };


export default function FormManufacture() {
  const classes = useStyles();
  const [objectform, setObjectform] = React.useState({...initElement});


  const estructureform =  [
         {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 6
          },
          {
           type:"input",
           name: "web",
           label: "Web",
           col: 6
          },
          {
           type:"input",
           name: "email",
           label: "Email",
           col: 6,
           inputtype:"mail"
          },
         {
           type:"textarea",
           name: "description",
           label: "Descripción",
           col: 12
          },
         {
           type:"image",
           name: "image",
           label: "Imagen",
           col: 6
          }
    ];


  const recoveryElement = function(objectedit,element){
    objectedit['name']=element.name?element.name:"";
    objectedit['image']=element.image?element.image:"";
    objectedit['web']=element.web?element.web:"";
    objectedit['email']=element.email?element.email:"";
    objectedit['description']=element.description?element.description:"";
    return objectedit;
  }

  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }


  return (
      <CrudForm
          objectform = {objectform}
          estructureform = {estructureform}
          titleEdit = {'Editar Fabricante'}
          titleNew = {'Nueva Fabricante'}
          urledit={"/manufactures/"}
          urlCancel={"/manufactures"}
          service = {service}
          recoveryElement = {recoveryElement}
          valid = {isValid}
        />
  );
}
