import React, { useState, useEffect }   from 'react';
import { useStyles,drawerWidth } from "../../layout/styles";
import {bannersService as service, bannerpositionService} from "../../../services"
import CrudTable from '../../crud/CrudTable';

const initFilter =  {
    title: "",
    name : ""
};

export default function Banners() {
  const classes = useStyles();
  const fieldstable = ["name","title","url","image","start","finish","position.name"];
  const headersTable =  ["Nombre","Titulo","URL","#","Desde","Hasta","Posición"];


  const createEstructureFilter =  [

   {
       type:"input",
       name: "name",
       label: "Nombre",
       col: 4
    },
    {
       type:"input",
       name: "title",
       label: "Titulo",
       col: 4
      },
      {
         type:"autocomplete",
         name: "idposition",
         label: "Posición",
         service: bannerpositionService.combo,
         col: 4
        }
    ];


  return (
     <CrudTable
     service={service}
     titlePage={"Banners"}
     titleButton={"Añadir banner"}
     urledit={"/banners/"}
     urlnew={"/banners/new/"}
     filter={initFilter}
     fieldstable={fieldstable}
     headersTable={headersTable}
     estructureForm={createEstructureFilter}
 />
  );
}
