import React, { useState, useEffect }   from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudTable from "../../crud/CrudTable"
import {minfocusService as service} from "../../../services"

const initFilter =  {
    name : "",
    sizeInches: "",
    sizeMM: null,
};


export default function MinFocus() {
    const [filter, setFilter] = React.useState(initFilter);
    const fieldstable = ["name","sizeMM","sizeInches"];
    const headersTable =  ["Nombre"," mm","pulgadas"];


    const createEstructureFilter =  [
      {
        type:"input",
        name: "name",
        label: "Nombre",
        col: 4
       },
       {
         type:"input",
         name: "sizeMM",
         label: "Milimetros",
         inputtype:"number",
         step:0.1,
         col: 4
        },
       {
         type:"input",
         name: "sizeInches",
         label: "Pulgadas",
         inputtype:"number",
         step:0.1,
         col: 4
        }
      ];


  return (
    <CrudTable
          service={service}
          titlePage={"Enfoque min."}
          titleButton={"Crear Enfoque min."}
          urledit={"/lens/minfocus/"}
          urlnew={"/lens/minfocus/new/"}
          filter={initFilter}
          fieldstable={fieldstable}
          headersTable={headersTable}
          estructureForm={createEstructureFilter}
      />
  );
}
