import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudForm from "../../crud/CrudForm"
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {serieService as service, typeLensService, markService, manufactureService, serieViewService} from "../../../services"
import ExploitIndividualData from '../../exploitdata/ExploitIndividualData';

const initElement = {
     name:"",
     typeLens:{
        id:null,
        name:""
     },
     mark:{
        id:null,
        name:""
     },
     manufacture:[{
       id: null,
       name:""
     }],
     isRehaousing: false,
     notSeller: false,
     specialManufactureBuy: {
      id: null,
      name:""
    },
     orderserie: 0
  };


export default function FormSerie() {
  let { id } = useParams();
  const classes = useStyles();
  const [objectform, setObjectform] = React.useState({...initElement});


  const estructureform =  [
         {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 4
          },
         {
           type:"autocomplete",
           name: "typeLens.code",
           label: "Tipo",
           service: typeLensService.combo,
           col: 4
          },
         {
           type:"autocomplete",
           name: "mark.code",
           label: "Familia",
           service: markService.combo,
           col: 4
          },
         {
           multiple:true,
           type:"autocomplete",
           name: "manufacture",
           label: "Fabricantes",
           service: manufactureService.combo,
           col: 6
          },
         {
           multiple:true,
           type:"input",
           name: "orderserie",
           label: "Orden",
           inputtype:"number",
           col: 6
          },
          {
            type:"checkbox",
            name: "isRehaousing",
            label: "Es rehaousing",
            col: 4
           },
          {
            multiple:false,
            type:"autocomplete",
            name: "specialManufactureBuy.id",
            label: "Fabricante distribudior",
            service: manufactureService.combo,
            col: 4
           },
           {
             type:"checkbox",
             name: "notSeller",
             label: "No se encuentra en distirbuidores",
             col: 4
            },
         {
           type:"image",
           name: "image",
           label: "Imagen",
           col: 6
          },
          {
            type:"editor",
            name: "note",
            label: "Descripción",
            col: 12
           }
    ];



  
    const recoveryElement = function(objectedit,element){
      objectedit['name']=element.name?element.name:"";
      objectedit['image']=element.image?element.image:"";
      objectedit['typeLens']= {code:element.typeLens && element.typeLens.code?element.typeLens.code:null, name: element.typeLens && element.typeLens.name?element.typeLens.name:null};
      objectedit['mark']= {code:element.mark && element.mark.code?element.mark.code:null, name: element.mark && element.mark.name?element.mark.name:null};
      objectedit['orderserie']=element.orderserie?element.orderserie:0;
      objectedit['manufacture'] = Array();
      if(element.manufacture){
        for (let index = 0; index < element.manufacture.length; index++) {
          const manu = element.manufacture[index];
          objectedit['manufacture'].push({id: manu.id, name: manu.name});
        }
      }
      objectedit['isRehaousing']=element.isRehaousing?element.isRehaousing:false;
      objectedit['specialManufactureBuy']=element.specialManufactureBuy && element.specialManufactureBuy.id ?{id: element.specialManufactureBuy.id, name: element.specialManufactureBuy.name}:{id: null, name:""};
      objectedit['notSeller']=element.notSeller?element.notSeller:false;
      return objectedit;
    }


  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      } else if(!object['typeLens']['code']){
        toast.notify("Debe indicar el tipo", {position:'top', duration: 10000})
        return false;
      } else if(!object['mark']['code']){
        toast.notify("Debe indicar la familia", {position:'top', duration: 10000})
        return false;
      } else if(!object['manufacture'][0]['id']){
        toast.notify("Debe indicar la familia", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }

  return (
    <CrudForm
        objectform = {objectform}
        estructureform = {estructureform}
        titleEdit = {'Editar Serie'}
        titleNew = {'Nueva Serie'}
        urledit={"/series/"}
        urlCancel={"/series"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
        haveother={id && id > 0}
        othersform={<ExploitIndividualData id={id} service={serieViewService} /> }
        />
      
  );
}
