import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudForm from "../../../crud/CrudForm"
import { useStyles,drawerWidth } from "../../../layout/styles";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {linkshomeService as service} from "../../../../services"

const initElement = {
     name:"",
     link: "",
     active: true,
  };


export default function FormLink() {
  let { id } = useParams();
  const classes = useStyles();
  const [objectform, setObjectform] = React.useState({...initElement});


  const [estructureform,setEstructureform] = React.useState([
         {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 4
          },
          {
            type:"input",
            name: "link",
            label: "Link",
            col: 4
           },
           {
             type:"checkbox",
             name: "active",
             label: "Activo",
             col: 4
            },


    ]);


  const recoveryElement = function(objectedit,element){
    objectedit['name']=element.name?element.name:"";
    objectedit['link']=element.link?element.link:"";
    objectedit['active']=element.active?element.active:false;
    return objectedit;
  }



  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el titulo", {position:'top', duration: 10000})
        return false;
      }
      if(!object['link']){
        toast.notify("Debe indicar el link", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }

  return (
    <CrudForm
        objectform = {objectform}
        estructureform = {estructureform}
        titleEdit = {'Editar Link home'}
        titleNew = {'Nuevo Link home'}
        urledit={"/linkshome/"}
        urlCancel={"/linkshome"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />
  );
}
