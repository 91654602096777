import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';
import Moment from 'react-moment';
import moment from 'moment';
import { ArrowBack } from '@material-ui/icons';

export default function ExploitContactIndividualData(props) {

    
    const [day,setDay] = React.useState([]);
    const [month,setMonth] = React.useState([]);
    const [year,setYear] = React.useState([]);
    const [countryToday, setCountryToday] = React.useState([]);
    const [countryWeek, setCountryWeek] = React.useState([]);
    const [countryMonth, setCountryMonth] = React.useState([]);
    const [countryYear, setCountryYear] = React.useState([]);
    const [daySelected, setDaySelected] = React.useState(moment());
    const [monthSelected, setMonthSelected] = React.useState(moment());
    const [yearSelected, setYearSelected] = React.useState(moment());
    const [dayCountrySelected, setDayCountrySelected] = React.useState(moment());
    const [monthCountrySelected, setMonthCountrySelected] = React.useState(moment());
    const [yearCountrySelected, setYearCountrySelected] = React.useState(moment());


    

    let id = props.id;
    let service = props.service;


    const exploitDataDay = (date) => {
        service.exploitDataDay(date, id, (data,error) => {
             if(data && data.data){
                setDay(data.data);
             }
        });
    }

    const exploitDataWeek = (month,year) => {
        service.exploitDataWeek(month,year, id, (data,error) => {
             if(data && data.data){
                setMonth(data.data);
             }
        });
    }

    const exploitDatayear = (year) => {
        service.exploitDatayear(year, id, (data,error) => {
             if(data && data.data){
                setYear(data.data);
             }
        });
    }

    const exploitdatacountry = () => {
        service.exploitdatacountry(id, (data,error) => {
             if(data && data.data){
                setCountryToday(data.data);
             }
        });
    }

    const exploitDataCountryWeek = (date) => {
        service.exploitDataCountryWeek(date, id, (data,error) => {
             if(data && data.data){
                setCountryWeek(data.data);
             }
        });
    }

    const exploitDataCountryMonth = (month,year) => {
        service.exploitDataCountryMonth(month,year, id, (data,error) => {
             if(data && data.data){
                setCountryMonth(data.data);
             }
        });
    }
    
    const exploitDataCountryyear = (year) => {
        service.exploitDataCountryyear(year, id, (data,error) => {
             if(data && data.data){
                setCountryYear(data.data);
             }
        });
    }

    
    

    

    useEffect(() => {

        exploitDataDay(daySelected.format("YYYYMMDD"));
        exploitDataWeek(monthSelected.format("MM"), monthSelected.format("YYYY"));
        exploitDatayear(yearSelected.format("YYYY"));
        exploitdatacountry();
        exploitDataCountryWeek(dayCountrySelected.format("YYYYMMDD"));
        exploitDataCountryMonth(monthCountrySelected.format("MM"), monthCountrySelected.format("YYYY"));
        exploitDataCountryyear(yearCountrySelected.format("YYYY"));
    }, []);

    const prevWeek = () => {
        var prev = daySelected.subtract(1,'week');
        setDaySelected(prev);
        exploitDataDay(prev.format("YYYYMMDD"));
    }


    const nextWeek = () => {
        var prev = daySelected.add(1,'week');
        setDaySelected(prev);
        exploitDataDay(prev.format("YYYYMMDD"));
    }


    const prevMonth = () => {
        var prev = monthSelected.subtract(1,'month');
        setMonthSelected(prev);
        exploitDataWeek(prev.format("MM"), prev.format("YYYY"));
    }


    const nextMonth = () => {
        var prev = monthSelected.add(1,'month');
        setMonthSelected(prev);
        exploitDataWeek(prev.format("MM"), prev.format("YYYY"));
    }

    const prevYear = () => {
        var prev = yearSelected.subtract(1,'year');
        setYearSelected(prev);
        exploitDatayear(prev.format("YYYY"));
    }


    const nextYear = () => {
        var prev = yearSelected.add(1,'year');
        setYearSelected(prev);
        exploitDatayear( prev.format("YYYY"));
    }


    const prevCountryWeek = () => {
        var prev = dayCountrySelected.subtract(1,'week');
        setDayCountrySelected(prev);
        exploitDataDay(prev.format("YYYYMMDD"));
    }


    const nextCountryWeek = () => {
        var prev = dayCountrySelected.add(1,'week');
        setDayCountrySelected(prev);
        exploitDataDay(prev.format("YYYYMMDD"));
    }


    const prevCountryMonth = () => {
        var prev = monthCountrySelected.subtract(1,'month');
        setMonthCountrySelected(prev);
        exploitDataCountryMonth(prev.format("MM"), prev.format("YYYY"));
    }


    const nextCountryMonth = () => {
        var prev = monthCountrySelected.add(1,'month');
        setMonthCountrySelected(prev);
        exploitDataCountryMonth(prev.format("MM"), prev.format("YYYY"));
    }


    const prevCountryYear = () => {
        var prev = yearCountrySelected.subtract(1,'year');
        setYearCountrySelected(prev);
        exploitDataCountryyear(prev.format("YYYY"));
    }


    const nextCountryYear = () => {
        var prev = yearCountrySelected.add(1,'year');
        setYearCountrySelected(prev);
        exploitDataCountryyear( prev.format("YYYY"));
    }



    return (
        <Grid container spacing={3}>

            <Grid item sm={12} xs={12} style={{textAlign:'center', marginBottom: '10px'}}>
                <h2>Contactos</h2>
            </Grid>
            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>Contactos esta semana</h3>
                    </Grid>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginRight: "10px", cursor: "pointer", marginBottom: "-5px"}} onClick={prevWeek} /> 
                        <label>Semana {daySelected.format("W")} ( {daySelected.startOf('week').format("DD-MM-YYYY")} - {daySelected.endOf('week').format("DD-MM-YYYY")} )</label>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginLeft: "10px", cursor: "pointer", transform: "rotate(180deg)", marginBottom: "-5px"}} onClick={nextWeek} /> 
                    </Grid>
                    {day.length > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={day}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Nº veces"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>

            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>Contactos este Mes</h3>
                    </Grid>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginRight: "10px", cursor: "pointer", marginBottom: "-5px"}} onClick={prevMonth} /> 
                        <label>Més {monthSelected.format("MM")} - {monthSelected.format('YYYY')}</label>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginLeft: "10px", cursor: "pointer", transform: "rotate(180deg)", marginBottom: "-5px"}} onClick={nextMonth} /> 
                    </Grid>
                    {month.length  > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={month}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Nº veces"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>
            

            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>Contactos este Año</h3>
                    </Grid>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginRight: "10px", cursor: "pointer", marginBottom: "-5px"}} onClick={prevYear} /> 
                        <label>AÑO {yearSelected.format('YYYY')}</label>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginLeft: "10px", cursor: "pointer", transform: "rotate(180deg)", marginBottom: "-5px"}} onClick={nextYear} /> 
                    </Grid>
                    {year.length  > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={year}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Nº veces"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>

            <Grid item sm={12} xs={12} style={{textAlign:'center', marginBottom: '10px'}}>
                <h2>Contactos por pais</h2>
            </Grid>

            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>Contacto paises HOY</h3>
                    </Grid>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', minHeight: '3.2em'}}>
                    </Grid>
                    {countryToday.length > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={countryToday}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Nº veces"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>

            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>Contactos por país de la semana</h3>
                    </Grid>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginRight: "10px", cursor: "pointer", marginBottom: "-5px"}} onClick={prevCountryWeek} /> 
                        <label>Semana {dayCountrySelected.format("W")} ( {dayCountrySelected.startOf('week').format("DD-MM-YYYY")} - {dayCountrySelected.endOf('week').format("DD-MM-YYYY")} )</label>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginLeft: "10px", cursor: "pointer", transform: "rotate(180deg)", marginBottom: "-5px"}} onClick={nextCountryWeek} /> 
                    </Grid>
                    {countryWeek.length > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={countryWeek}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Nº veces"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>

            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>Contactos por país del Mes</h3>
                    </Grid>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginRight: "10px", cursor: "pointer", marginBottom: "-5px"}} onClick={prevCountryMonth} /> 
                        <label>Més {monthCountrySelected.format("MM")} - {monthCountrySelected.format('YYYY')}</label>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginLeft: "10px", cursor: "pointer", transform: "rotate(180deg)", marginBottom: "-5px"}} onClick={nextCountryMonth} /> 
                    </Grid>
                    {countryMonth.length > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={countryMonth}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Nº veces"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>

            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>Contactos por pais del Año</h3>
                    </Grid>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginRight: "10px", cursor: "pointer", marginBottom: "-5px"}} onClick={prevCountryYear} /> 
                        <label>AÑO {yearCountrySelected.format('YYYY')}</label>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginLeft: "10px", cursor: "pointer", transform: "rotate(180deg)", marginBottom: "-5px"}} onClick={nextCountryYear} /> 
                    </Grid>
                    {countryYear.length > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={countryYear}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Nº veces"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>



        </Grid>
    );

}