import BaseService from './_base.service';

export const creditsRentalService = {
    search,
    edit,
    create,
    combo,
    one,
    remove,
    rental,
    removeLogic
};

function search(offset,callback) {
    return BaseService.get("/creditsrental/all/", callback)
}

function combo(callback) {
    return BaseService.get("/creditsrental/combo/", callback, true)
}
function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/creditsrental/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/creditsrental/", body, callback, true)
}


function one(id,callback) {
    return BaseService.get("/creditsrental/one/"+id, callback);
}

function rental(id,callback) {
    return BaseService.get("/creditsrental/rental/"+id, callback)
}
function remove(id,callback) {
    return BaseService.delete("/creditsrental/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete("/creditsrental/"+id+"/logic", callback);
}
