import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudForm from "../../crud/CrudForm"
import { useStyles,drawerWidth } from "../../layout/styles";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {manufactureSusService as service, manufactureService} from "../../../services"

const initElement = {
     manufacture: {id:null, name:""},
     endSuscription: null
  };


export default function FormManufactureSus() {
  let { id } = useParams();
  const classes = useStyles();
  const [objectform, setObjectform] = React.useState({...initElement});


  const [estructureform,setEstructureform] = React.useState([
         {
           type:"autocomplete",
           name: "manufacture.id",
           label: "Fabricante",
           service: manufactureService.combo,
           col: 4
          },
          {
            type:"input",
            name: "endSuscription",
            label: "Fecha fin de susceripción",
            inputtype:"date",
            col: 4
           },
          

             

    ]);


  const recoveryElement = function(objectedit,element){
    objectedit = {...objectedit, ...element};
    return objectedit;
  }



  const isValid = function(object){
      if(!object['manufacture']['id']){
        toast.notify("Debe indicar el fabricante", {position:'top', duration: 10000})
        return false;
      }
      if(!object['endSuscription']){
        toast.notify("Debe indicar la fecha", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }

  return (
    <CrudForm
        objectform = {objectform}
        estructureform = {estructureform}
        titleEdit = {'Editar suscripcion fabricante'}
        titleNew = {'Nueva suscripcion fabricante'}
        urledit={"/manufacturesus/"}
        urlCancel={"/manufacturesus"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />
  );
}
