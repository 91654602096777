import React from 'react';
import { useParams,useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import logo from '../../assets/img/logoletras.png'

export default function Header(props) {
  const history = useHistory();

  const closeSession = function(){
    localStorage.clear();
    history.push('/');
  }

  return (
    <AppBar position="absolute" className={clsx(props.classes.appBar, props.open && props.classes.appBarShift)}>
        <Toolbar className={props.classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerOpen}
            className={clsx(props.classes.menuButton, props.open && props.classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
          <img src={logo} className={props.classes.logoheader} />
          <Typography component="h1" variant="h6" color="inherit" noWrap className={props.classes.title}>
            {props.title}
          </Typography>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton style={{backgroundColor: "white", marginLeft: "1em", height: "30px", width: "30px" }} onClick={closeSession}>
            <Badge color="primary">
              <PowerSettingsNewIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
  );
}
