import React, { useState, useEffect }   from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudTable from "../../crud/CrudTable"
import {focalService as service} from "../../../services"


const initFilter =  {
    name : "",
    minSize: "",
    maxSize: null,
};



export default function Focal() {
  const [filter, setFilter] = React.useState(initFilter);
  const fieldstable = ["name","minSize","maxSize"];
  const headersTable =  ["Nombre","Tamaño Min","Tamnaño Max."];


    const createEstructureFilter =  [
      {
        type:"input",
        name: "name",
        label: "Nombre",
        col: 4
       },
       {
         type:"input",
         name: "minSize",
         label: "Min",
         inputtype:"number",
         step:0.1,
         col: 4
        },
        {
          type:"input",
          name: "maxSize",
          label: "Max",
          inputtype:"number",
          step:0.1,
          col: 4
         }
      ];



  return (
      <CrudTable
            service={service}
            titlePage={"Focal"}
            titleButton={"Crear Focal"}
            urledit={"/lens/focal/"}
            urlnew={"/lens/focal/new/"}
            filter={initFilter}
            fieldstable={fieldstable}
            headersTable={headersTable}
            estructureForm={createEstructureFilter}
        />
  );
}
