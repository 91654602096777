import React, { useState, useEffect }   from 'react';
import { useStyles,drawerWidth } from "../../../layout/styles";
import {modalService as service} from "../../../../services"
import CrudTable from '../../../crud/CrudTable';

const initFilter =  {
    title: "",
    name : ""
};

export default function Popup() {
  const classes = useStyles();
  const fieldstable = ["name","link","active"];
  const headersTable =  ["Nombre","URL","Activo"];


  const createEstructureFilter =  [

   {
       type:"input",
       name: "name",
       label: "Nombre",
       col: 4
    },
      {
         type:"autocomplete",
         name: "active",
         label: "Activos o no activos",
         options:[{key:-1, name:"Todos"},{key:1, name:"Activos"},{key:2, name:"No activos"} ],
         col: 4
        }
    ];


  return (
     <CrudTable
     service={service}
     titlePage={"Pop ups"}
     titleButton={"Añadir Popup"}
     urledit={"/popup/"}
     urlnew={"/popup/new/"}
     filter={initFilter}
     fieldstable={fieldstable}
     headersTable={headersTable}
     estructureForm={createEstructureFilter}
 />
  );
}
