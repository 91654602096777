import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles,drawerWidth } from "../../layout/styles";
import CustomPaginationActionsTable from "../../table/Table"
import Filter from "../../filter/Filter"
import Form from "../../form/Form"
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {productmarketplaceComplaintService as service} from "../../../services"

const initElement = {
     suject:"",
     resolution:"",
     cause:"",
     user:{
       id: null,
       email:""
     },
     productMarketplace:{
       id: null,
       title:""
     },
     resolveBool:false
  };



export default function FormComplaint() {
  const history = useHistory();
  let { id } = useParams();
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [objectform, setObjectform] = React.useState({...initElement});
  const [titleWindow, setTitleWindow] = React.useState('Editar denuncia');
  const [idRow, setIdrow] = React.useState(id);
  const [idSerie, setIdSerie] = React.useState(-1);

  const [open, setOpen] = React.useState(true);
  const [rows,setRows] = React.useState([{}]);


  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };


  const estructureform =  [
         {
           type:"input",
           name: "user.email",
           label: "Usuario",
           col: 6,
           readonly:true
          },
         {
           type:"input",
           name: "productMarketplace.title",
           label: "Producto",
           col: 6,
           readonly:true
          },
         {
           type:"input",
           name: "suject",
           label: "Asunto",
           col: 12,
           readonly:true
          },
         {
           type:"textarea",
           name: "cause",
           label: "Causa",
           col: 12,
           readonly:true
          },
         {
           type:"textarea",
           name: "resolution",
           label: "Resolución",
           col: 12
          },
          {
            type:"checkbox",
            name: "resolveBool",
            label: "Resuelto",
            col: 2
           },
    ];




  useEffect(() => {
      if(idRow && idRow > 0){
        searchOne();
      };

  }, []);

  const searchOne = function(){
    service.one(idRow,(data, error) => {
      if(data && data.data){
        var element = data.data;
        let objectedit = JSON.parse(JSON.stringify(objectform));
        objectedit['suject']=element.suject?element.suject:"";
        objectedit['resolution']=element.resolution?element.resolution:"";
        objectedit['cause']=element.cause?element.cause:"";
        objectedit['resolveBool']=element.resolveBool?element.resolveBool:false;
        objectedit['user']= {id:element.user && element.user.id?element.user.id:null, email: element.user && element.user.email?element.user.email:null};
        objectedit['productMarketplace']= {id:element.productMarketplace && element.productMarketplace.id?element.productMarketplace.id:null, title: element.productMarketplace && element.productMarketplace.title?element.productMarketplace.title:null};
        setObjectform(objectedit);
     }
    });
  }

  const cancelForm = (object) => {
      history.push('/marketplace/complaint');
  }

  const saveForm = (object) => {
       if(isValid(object)) {
         var objToSave = cleanElementsBeforeSave(object);
         if (idRow && idRow > 0){
           objToSave['id'] = id;
           update(objToSave,false);
         } else {
           create(objToSave,false);
         }
       }

  }

  const create = (object,cleanSerie) => {
    service.create(object, (data, error) => {
        if(data){
          setIdrow(data.data.id)
          history.push('/marketplace/complaint/'+data.data.id);
        }
    });
  }

  const update = (object,cleanSerie) => {
    service.edit(id,object, (data, error) => {
        if(data){

        }
    });
  }



  const isValid = function(object){

      return true;
  }

  const cleanElementsBeforeSave = function(object){
    let newObj = JSON.parse(JSON.stringify(object));
    return newObj;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title={titleWindow} classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} className={classes.bodycontainer}>
              <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                  <Form objectform={objectform} fields={estructureform}  searchFilter={saveForm} cleanFilter={cancelForm} labelsave="Guardar" labelcancel="Cancelar"/>
              </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
