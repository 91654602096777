import BaseService from './_base.service';

export const linkshomeService = {
    page,
    edit,
    create,
    one,
    remove,
    search,
    removeLogic
};

function page(page,size, callback) {
    return BaseService.get("/linkshome/page/"+page+"/"+size, callback, true)
}

function search(page,size,filter, callback) {
    return BaseService.post("/linkshome/page/"+page+"/"+size,filter, callback, true)
}
function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/linkshome/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/linkshome/", body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/linkshome/one/"+id, callback);
}

function remove(id,callback) {
    return BaseService.delete("/linkshome/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete("/linkshome/"+id+"/logic", callback);
}
