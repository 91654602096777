import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudForm from "../../crud/CrudForm"
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {weightService as service} from "../../../services"

const initElement = {
     name:"",
     weightKg: null,
     weightLbs: null,
     weightKgND: null,
     weightLbsNd: null,

  };



export default function FormWeigth() {
  const classes = useStyles();
  const [objectform, setObjectform] = React.useState({...initElement});

  const estructureform =  [
       {
         type:"input",
         name: "name",
         label: "Nombre",
         col: 6
        },
        {
         type:"input",
         name: "weightKg",
         label: "KG.",
         col: 6,
         inputtype:"number",
         step:0.01
        },
        {
         type:"input",
         name: "weightLbs",
         label: "Libras.",
         col: 6,
         inputtype:"number",
         step:0.01
       },
        {
         type:"input",
         name: "weightKgND",
         label: "Kg. ND",
         col: 6,
         inputtype:"number",
         step:0.01
        },
        {
         type:"input",
         name: "weightLbsNd",
         label: "Libras ND",
         col: 6,
         inputtype:"number",
         step:0.01
        }
  ];



    const recoveryElement = function(objectedit,element){
      objectedit['name']=element.name?element.name:"";
      objectedit['weightKg']=element.weightKg?element.weightKg:"";
      objectedit['weightLbs']=element.weightLbs?element.weightLbs:"";
      objectedit['minND']=element.minND?element.minND:"";
      objectedit['maxND']=element.maxND?element.maxND:"";
      return objectedit;
    }


  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      }
      if(!object['weightLbs']){
        toast.notify("Debe indicar las libras", {position:'top', duration: 10000})
        return false;
      }
      if(!object['weightKg']){
        toast.notify("Debe indicar los kg", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }


  return (
    <CrudForm
        objectform = {objectform}
        estructureform = {estructureform}
        titleEdit = {'Editar peso'}
        titleNew = {'Nuevo peso'}
        urledit={"/lens/weigth/"}
        urlCancel={"/lens/weigth"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />
  );
}
