import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles, drawerWidth } from '../../layout/styles';
import CustomPaginationActionsTable from '../../table/Table';
import Filter from '../../filter/Filter';
import Form from '../../form/Form';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import { suscriptionService as service } from '../../../services';

const initElement = {
      name: '',
      description: '',
      allowRentalManagen: false,
      allowDealerManagen: false,
      allowMarketplace: false,
      allowSearchLens: false,
      allowStadistics: false,
      isPRO: false,
      isVisible: false,
      crew: false,
      maxProductInMaretPlace: 0,
      price: 0.0,
      stripeId: '',
      period: 'YEAR',
};

export default function FormSuscription() {
      const history = useHistory();
      let { id } = useParams();
      const classes = useStyles();
      const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
      const [objectform, setObjectform] = React.useState({ ...initElement });
      const [titleWindow, setTitleWindow] = React.useState(id && id > 0 ? 'Editar suscripción' : 'Nueva suscripción');
      const [idRow, setIdrow] = React.useState(id);
      const [idSerie, setIdSerie] = React.useState(-1);
      const [cities, setCities] = React.useState([]);

      const [open, setOpen] = React.useState(true);
      const [rows, setRows] = React.useState([{}]);

      const handleDrawerOpen = () => {
            setOpen(true);
      };
      const handleDrawerClose = () => {
            setOpen(false);
      };

      const [estructureform, setEstructureform] = React.useState([
            {
                  type: 'input',
                  name: 'name',
                  label: 'Nombre',
                  col: 4,
            },
            {
                  type: 'checkbox',
                  name: 'allowRentalManagen',
                  label: 'Rental',
                  col: 2,
            },
            {
                  type: 'checkbox',
                  name: 'allowDealerManagen',
                  label: 'Distribuidores',
                  col: 2,
            },
            {
                  type: 'checkbox',
                  name: 'allowMarketplace',
                  label: 'Market place',
                  col: 2,
            },
            {
                  type: 'checkbox',
                  name: 'crew',
                  label: 'Crew',
                  col: 2,
            },
            {
                  type: 'checkbox',
                  name: 'allowSearchLens',
                  label: 'Buscador rentals',
                  col: 2,
            },
            {
                  type: 'checkbox',
                  name: 'allowStadistics',
                  label: 'Estadísticas',
                  col: 2,
            },
            {
                  type: 'checkbox',
                  name: 'isPRO',
                  label: 'Es Usuario PRO',
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'maxProductInMaretPlace',
                  label: 'Max. Productos',
                  inputtype: 'number',
                  col: 6,
            },
            {
                  type: 'input',
                  name: 'price',
                  label: 'Precio',
                  inputtype: 'number',
                  step: 0.01,
                  col: 6,
            },
            {
                  type: 'checkbox',
                  name: 'haveContact',
                  label: 'Necesario Contactar',
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'stripeId',
                  label: 'StripeID',
                  col: 6,
            },
            {
                  type: 'autocomplete',
                  name: 'period',
                  label: 'Periodo',
                  options: [
                        { key: 'MONTH', name: 'MONTH' },
                        { key: 'MONTH6', name: '6 MONTH' },
                        { key: 'YEAR', name: 'YEAR' },
                  ],
                  col: 6,
            },
            {
                  type: 'checkbox',
                  name: 'isVisible',
                  label: 'Es visible',
                  col: 2,
            },
            {
                  type: 'editor',
                  name: 'description',
                  label: 'Descripción',
                  col: 12,
            },
      ]);

      useEffect(() => {
            if (idRow && idRow > 0) {
                  searchOne();
                  setTitleWindow('Editar suscripción');
            }
      }, []);

      const searchOne = function () {
            service.one(idRow, (data, error) => {
                  if (data && data.data) {
                        var element = data.data;
                        let objectedit = JSON.parse(JSON.stringify(objectform));
                        objectedit['name'] = element.name ? element.name : '';
                        objectedit['allowRentalManagen'] = element.allowRentalManagen ? element.allowRentalManagen : false;
                        objectedit['allowDealerManagen'] = element.allowDealerManagen ? element.allowDealerManagen : false;
                        objectedit['allowMarketplace'] = element.allowMarketplace ? element.allowMarketplace : false;
                        objectedit['allowSearchLens'] = element.allowSearchLens ? element.allowSearchLens : false;
                        objectedit['allowStadistics'] = element.allowStadistics ? element.allowStadistics : false;
                        objectedit['isPRO'] = element.isPRO ? element.isPRO : false;
                        objectedit['isVisible'] = element.isVisible ? element.isVisible : false;
                        objectedit['crew'] = element.crew ? element.crew : false;
                        objectedit['maxProductInMaretPlace'] = element.maxProductInMaretPlace ? element.maxProductInMaretPlace : 0;
                        objectedit['price'] = element.price ? element.price : 0.0;
                        objectedit['stripeId'] = element.stripeId ? element.stripeId : '';
                        objectedit['period'] = element.period ? element.period : null;
                        objectedit['description'] = element.description ? element.description : null;

                        setObjectform(objectedit);
                  }
            });
      };

      const cancelForm = (object) => {
            history.push('/suscriptions');
      };

      const saveForm = (object) => {
            if (isValid(object)) {
                  var objToSave = cleanElementsBeforeSave(object);
                  if (idRow && idRow > 0) {
                        objToSave['id'] = id;
                        update(objToSave, false);
                  } else {
                        create(objToSave, false);
                  }
            }
      };

      const create = (object, cleanSerie) => {
            service.create(object, (data, error) => {
                  if (data) {
                        setIdrow(data.data.id);
                        history.push('/suscriptions/' + data.data.id);
                  }
            });
      };

      const update = (object, cleanSerie) => {
            service.edit(id, object, (data, error) => {
                  if (data) {
                  }
            });
      };

      const isValid = function (object) {
            if (!object['name']) {
                  toast.notify('Debe indicar el nombre', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['stripeId']) {
                  toast.notify('Debe el identificador de stripe', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['price']) {
                  toast.notify('Debe indicar el precio', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['period']) {
                  toast.notify('Debe indicar el periodo', { position: 'top', duration: 10000 });
                  return false;
            }

            return true;
      };

      const cleanElementsBeforeSave = function (object) {
            let newObj = JSON.parse(JSON.stringify(object));
            return newObj;
      };

      return (
            <div className={classes.root}>
                  <CssBaseline />
                  <Header title={titleWindow} classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
                  <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
                  <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        <Container maxWidth="lg" className={classes.container}>
                              <Grid container spacing={3} className={classes.bodycontainer}>
                                    <Grid item sm={12} xs={12} style={{ textAlign: 'right', marginBottom: '10px' }}>
                                          <Form objectform={objectform} fields={estructureform} searchFilter={saveForm} cleanFilter={cancelForm} labelsave="Guardar" labelcancel="Cancelar" />
                                    </Grid>
                              </Grid>
                              <Box pt={4}>
                                    <Copyright />
                              </Box>
                        </Container>
                  </main>
            </div>
      );
}
