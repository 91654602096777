import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import PrivateRoute from './PrivateRoute';
import LoginRoute from './LoginRoute';
import Login from './Login';
import Dashboard from './component/page/dashboard/Dashboard';
import Lens from './component/page/lens/Lens';
import FormLens from './component/page/lens/FormLens';
import Serie from './component/page/lens/Serie';
import FormSerie from './component/page/lens/FormSerie';
import Manufacturer from './component/page/lens/Manufacturer';
import FormManufacture from './component/page/lens/FormManufacture';
import FormType from './component/page/lens/FormType';
import TypesLens from './component/page/lens/TypesLens';
import Mark from './component/page/lens/Mark';
import FormMark from './component/page/lens/FormMark';
import FormFocal from './component/page/lens/FormFocal';
import Focal from './component/page/lens/Focal';
import FormTStop from './component/page/lens/FormTStop';
import TStop from './component/page/lens/TSTop';
import FormLength from './component/page/lens/FormLength';
import Length from './component/page/lens/Length';
import FormWeigth from './component/page/lens/FormWeigth';
import Weigth from './component/page/lens/Weigth';
import FormMinFocus from './component/page/lens/FormMinFocus';
import MinFocus from './component/page/lens/MinFocus';
import FormDiameter from './component/page/lens/FormDiameter';
import Diameter from './component/page/lens/Diameter';
import FormCamera from './component/page/camera/FormCamera';
import Camera from './component/page/camera/Camera';
import FormModel from './component/page/camera/FormModel';
import Models from './component/page/camera/Models';
import FormRental from './component/page/rental/FormRental';
import FormFamilyRental from './component/page/rental/FormFamilyRental';
import Rental from './component/page/rental/Rental';
import FamilyRental from './component/page/rental/Family';
import FormFamilyLensRental from './component/page/rental/FormFamilyLensRental';
import LensRental from './component/page/rental/Lens';
import UsersRental from './component/page/rental/Users';
import FormDealer from './component/page/dealer/FormDealer';
import Dealer from './component/page/dealer/Dealer';
import FormFamilyDealer from './component/page/dealer/FormFamilyDealer';
import FamilyDealer from './component/page/dealer/Family';
import FormFamilyManufactureDealer from './component/page/dealer/FormFamilyManufactureDealer';
import ManufactureDealer from './component/page/dealer/Manufacture';
import UsersDealers from './component/page/dealer/Users';
import FormUser from './component/page/user/FormUser';
import Users from './component/page/user/Users';
import Crew from './component/page/user/Crew';
import FormJob from './component/page/user/FormJob';
import Job from './component/page/user/Job';
import FormLanguage from './component/page/user/FormLanguage';
import Language from './component/page/user/Language';
import FormSuscription from './component/page/user/FormSuscription';
import Suscriptions from './component/page/user/Suscriptions';
import StateSuscriptions from './component/page/user/State';
import Coupon from './component/page/user/Coupon';
import FormCoupon from './component/page/user/FormCoupon';
import Foro from './component/page/foro/Foro';
import Complaint from './component/page/foro/Complaint';
import Banners from './component/page/web/Banners';
import Web from './component/page/web/Web';
import Credits from './component/page/credits/Credits';
import Notifications from './component/page/notifications/Notifications';
import FormProduct from './component/page/marketplace/FormProduct';
import Products from './component/page/marketplace/Products';
import FormComplaint from './component/page/marketplace/FormComplaint';
import ComplaintMarket from './component/page/marketplace/Complaint';
import Blog from './component/page/blog/Blog';
import FormBlog from './component/page/blog/FormBlog';
import Reels from './component/page/reels/Reels';
import FormReels from './component/page/reels/FormReels';
import Categoryblog from './component/page/categoryblog/Categoryblog';
import FormCategoryblog from './component/page/categoryblog/FormCategoryblog';
import FormBanner from './component/page/web/FormBanner';
import PartnersCategory from './component/page/partnerscategory/PartnersCategory';
import FormPartnersCategory from './component/page/partnerscategory/FormPartnersCategory';
import Partner from './component/page/partner/Partner';
import FormPartner from './component/page/partner/FormPartner';
import Tag from './component/page/tag/Tag';
import FormTag from './component/page/tag/FormTag';
import ManufactureReport from './component/page/staditics/ManufactureReport';
import SerieReport from './component/page/staditics/SerieReport';
import WishProduct from './component/page/marketplace/WishProduct';
import FormConfig from './component/page/confighome/Formconfig';
import Linkshome from './component/page/web/linkshome/Linkshome';
import FormLink from './component/page/web/linkshome/FormLink';
import Popup from './component/page/web/modal/Popup';
import FormPopup from './component/page/web/modal/FormPopup';
import ManufactureSus from './component/page/manufacturesus/ManufactureSus';
import FormManufactureSus from './component/page/manufacturesus/FormManufactureSus';
import FormManufactureSusSerie from './component/page/manufacturesus/FormManufactureSerie';
import Orders from './component/page/marketplace/Orders';
import FormOrder from './component/page/marketplace/FormOrder';
import DisableApp from './component/page/migration/DisableApp';

import jwtDecode from 'jwt-decode';
import { Category } from '@material-ui/icons';

function Copyright() {
      return (
            <Typography variant="body2" color="textSecondary" align="center">
                  {'Copyright © '}
                  <Link color="inherit" href="https://material-ui.com/">
                        Your Website
                  </Link>{' '}
                  {new Date().getFullYear()}
                  {'.'}
            </Typography>
      );
}

interface TokenInfo {
      exp: number;
}

function isLogin() {
      var expisold = false;
      if (localStorage.getItem('token') != null && localStorage.getItem('token') != '' && localStorage.getItem('token') != undefined && localStorage.getItem('token') != 'undefined') {
            const token: string = localStorage.getItem('token') + '';
            const tokende = jwtDecode<TokenInfo>(token);
            if (Date.now() >= tokende.exp * 1000) {
                  return true;
            } else {
                  return false;
            }
      } else {
            return true;
      }
}

export default function App() {
      const isLoggedNotIn = isLogin();

      return (
            <>
                  <Router>
                        <Switch>
                              <LoginRoute path="/login" component={Login} />
                              <PrivateRoute path="/dashboard/" component={Dashboard} />
                              <PrivateRoute path="/lens/types/new" component={FormType} />
                              <PrivateRoute path="/lens/types/:id" component={FormType} />
                              <PrivateRoute path="/lens/types/" component={TypesLens} />
                              <PrivateRoute path="/lens/focal/new" component={FormFocal} />
                              <PrivateRoute path="/lens/focal/:id" component={FormFocal} />
                              <PrivateRoute path="/lens/focal/" component={Focal} />
                              <PrivateRoute path="/lens/tstop/new" component={FormTStop} />
                              <PrivateRoute path="/lens/tstop/:id" component={FormTStop} />
                              <PrivateRoute path="/lens/tstop/" component={TStop} />
                              <PrivateRoute path="/lens/length/new" component={FormLength} />
                              <PrivateRoute path="/lens/length/:id" component={FormLength} />
                              <PrivateRoute path="/lens/length/" component={Length} />
                              <PrivateRoute path="/lens/weigth/new" component={FormWeigth} />
                              <PrivateRoute path="/lens/weigth/:id" component={FormWeigth} />
                              <PrivateRoute path="/lens/weigth/" component={Weigth} />
                              <PrivateRoute path="/lens/mark/new" component={FormMark} />
                              <PrivateRoute path="/lens/mark/:id" component={FormMark} />
                              <PrivateRoute path="/lens/mark/" component={Mark} />
                              <PrivateRoute path="/lens/minfocus/new" component={FormMinFocus} />
                              <PrivateRoute path="/lens/minfocus/:id" component={FormMinFocus} />
                              <PrivateRoute path="/lens/minfocus/" component={MinFocus} />
                              <PrivateRoute path="/lens/frontdiameter/new" component={FormDiameter} />
                              <PrivateRoute path="/lens/frontdiameter/:id" component={FormDiameter} />
                              <PrivateRoute path="/lens/frontdiameter/" component={Diameter} />
                              <PrivateRoute path="/lens/new" component={FormLens} />
                              <PrivateRoute path="/lens/:id" component={FormLens} />
                              <PrivateRoute path="/lens/" component={Lens} />
                              <PrivateRoute path="/cameras/resolution/new" component={FormModel} />
                              <PrivateRoute path="/cameras/resolution/:id" component={FormModel} />
                              <PrivateRoute path="/cameras/resolution" component={Models} />
                              <PrivateRoute path="/cameras/new" component={FormCamera} />
                              <PrivateRoute path="/cameras/:id" component={FormCamera} />
                              <PrivateRoute path="/cameras/" component={Camera} />
                              <PrivateRoute path="/series/new" component={FormSerie} />
                              <PrivateRoute path="/series/:id" component={FormSerie} />
                              <PrivateRoute path="/series/" component={Serie} />
                              <PrivateRoute path="/manufactures/new" component={FormManufacture} />
                              <PrivateRoute path="/manufactures/:id" component={FormManufacture} />
                              <PrivateRoute path="/manufactures/" component={Manufacturer} />
                              <PrivateRoute path="/rentals/family/new" component={FormFamilyRental} />
                              <PrivateRoute path="/rentals/family/:id" component={FormFamilyRental} />
                              <PrivateRoute path="/rentals/family" component={FamilyRental} />
                              <PrivateRoute path="/rentals/lens/new" component={FormFamilyLensRental} />
                              <PrivateRoute path="/rentals/lens/:id" component={FormFamilyLensRental} />
                              <PrivateRoute path="/rentals/lens" component={LensRental} />
                              <PrivateRoute path="/rentals/users" component={UsersRental} />
                              <PrivateRoute path="/rentals/new" component={FormRental} />
                              <PrivateRoute path="/rentals/:id" component={FormRental} />
                              <PrivateRoute path="/rentals/" component={Rental} />
                              <PrivateRoute path="/dealers/family/new" component={FormFamilyDealer} />
                              <PrivateRoute path="/dealers/family/:id" component={FormFamilyDealer} />
                              <PrivateRoute path="/dealers/family" component={FamilyDealer} />
                              <PrivateRoute path="/dealers/manufacture/new" component={FormFamilyManufactureDealer} />
                              <PrivateRoute path="/dealers/manufacture/:id" component={FormFamilyManufactureDealer} />
                              <PrivateRoute path="/dealers/manufacture" component={ManufactureDealer} />
                              <PrivateRoute path="/dealers/users" component={UsersDealers} />
                              <PrivateRoute path="/dealers/new" component={FormDealer} />
                              <PrivateRoute path="/dealers/:id" component={FormDealer} />
                              <PrivateRoute path="/dealers/" component={Dealer} />
                              <PrivateRoute path="/suscriptions/new" component={FormSuscription} />
                              <PrivateRoute path="/suscriptions/:id" component={FormSuscription} />
                              <PrivateRoute path="/suscriptions/" component={Suscriptions} />
                              <PrivateRoute path="/suscription/state/" component={StateSuscriptions} />
                              <PrivateRoute path="/coupon/new" component={FormCoupon} />
                              <PrivateRoute path="/coupon/:id" component={FormCoupon} />
                              <PrivateRoute path="/coupon/" component={Coupon} />
                              <PrivateRoute path="/users/new" component={FormUser} />
                              <PrivateRoute path="/users/:id" component={FormUser} />
                              <PrivateRoute path="/users/" component={Users} />
                              <PrivateRoute path="/crew/" component={Crew} />
                              <PrivateRoute path="/job/new" component={FormJob} />
                              <PrivateRoute path="/job/:id" component={FormJob} />
                              <PrivateRoute path="/job/" component={Job} />
                              <PrivateRoute path="/language/new" component={FormLanguage} />
                              <PrivateRoute path="/language/:id" component={FormLanguage} />
                              <PrivateRoute path="/language/" component={Language} />
                              <PrivateRoute path="/foro/complaint/" component={Complaint} />
                              <PrivateRoute path="/foro/" component={Foro} />
                              <PrivateRoute path="/banners/new" component={FormBanner} />
                              <PrivateRoute path="/banners/:id" component={FormBanner} />
                              <PrivateRoute path="/banners/" component={Banners} />
                              <PrivateRoute path="/page/" component={Web} />
                              <PrivateRoute path="/credits/" component={Credits} />
                              <PrivateRoute path="/notifications/" component={Notifications} />
                              <PrivateRoute path="/marketplace/new" component={FormProduct} />
                              <PrivateRoute path="/marketplace/complaint/:id" component={FormComplaint} />
                              <PrivateRoute path="/marketplace/complaint" component={ComplaintMarket} />
                              <PrivateRoute path="/marketplace/:id" component={FormProduct} />
                              <PrivateRoute path="/marketplace/" component={Products} />
                              <PrivateRoute path="/categoryblog/new" component={FormCategoryblog} />
                              <PrivateRoute path="/categoryblog/:id" component={FormCategoryblog} />
                              <PrivateRoute path="/categoryblog/" component={Categoryblog} />
                              <PrivateRoute path="/blog/new" component={FormBlog} />
                              <PrivateRoute path="/blog/:id" component={FormBlog} />
                              <PrivateRoute path="/blog/" component={Blog} />
                              <PrivateRoute path="/reels/new" component={FormReels} />
                              <PrivateRoute path="/reels/:id" component={FormReels} />
                              <PrivateRoute path="/reels/" component={Reels} />
                              <PrivateRoute path="/partnerscategory/new" component={FormPartnersCategory} />
                              <PrivateRoute path="/partnerscategory/:id" component={FormPartnersCategory} />
                              <PrivateRoute path="/partnerscategory/" component={PartnersCategory} />
                              <PrivateRoute path="/partners/new" component={FormPartner} />
                              <PrivateRoute path="/partners/:id" component={FormPartner} />
                              <PrivateRoute path="/partners/" component={Partner} />
                              <PrivateRoute path="/tag/new" component={FormTag} />
                              <PrivateRoute path="/tag/:id" component={FormTag} />
                              <PrivateRoute path="/tag/" component={Tag} />
                              <PrivateRoute path="/report/manufacture" component={ManufactureReport} />
                              <PrivateRoute path="/report/serie" component={SerieReport} />
                              <PrivateRoute path="/wishproduct/" component={WishProduct} />
                              <PrivateRoute path="/confighome/:id" component={FormConfig} />
                              <PrivateRoute path="/linkshome/new" component={FormLink} />
                              <PrivateRoute path="/linkshome/:id" component={FormLink} />
                              <PrivateRoute path="/linkshome" component={Linkshome} />
                              <PrivateRoute path="/popup/new" component={FormPopup} />
                              <PrivateRoute path="/popup/:id" component={FormPopup} />
                              <PrivateRoute path="/popup" component={Popup} />
                              <PrivateRoute path="/manufacturesus/serie/:id/:manu" component={FormManufactureSusSerie} />
                              <PrivateRoute path="/manufacturesus/new" component={FormManufactureSus} />
                              <PrivateRoute path="/manufacturesus/:id" component={FormManufactureSus} />
                              <PrivateRoute path="/manufacturesus" component={ManufactureSus} />
                              <PrivateRoute path="/orders/:id" component={FormOrder} />
                              <PrivateRoute path="/orders" component={Orders} />
                              <Route path="/disableapp" component={DisableApp} />

                              {isLoggedNotIn ? <Route path="/" component={Login} /> : <PrivateRoute path="/" component={Dashboard} />}
                        </Switch>
                  </Router>
            </>
      );
}
