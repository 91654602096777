import BaseService from './_base.service';

export const creditsUserService = {
    search,
    edit,
    create,
    combo,
    one,
    remove,
    user,
    removeLogic
};

function search(offset,callback) {
    return BaseService.get("/creditsuser/all/", callback)
}

function combo(callback) {
    return BaseService.get("/creditsuser/combo/", callback, true)
}
function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/creditsuser/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/creditsuser/", body, callback, true)
}


function one(id,callback) {
    return BaseService.get("/creditsuser/one/"+id, callback);
}

function user(id,callback) {
    return BaseService.get("/creditsuser/user/"+id, callback)
}
function remove(id,callback) {
    return BaseService.delete("/creditsuser/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete("/creditsuser/"+id+"/logic", callback);
}
