import BaseService from './_base.service';

export const stadisticsService = {
    crew,
    user,
    rental,
    market,
    productactive,
    productsold,
    removeLogic
};

function crew(callback) {
    return BaseService.get("/stadistics/crew", callback, true)
}

function user(callback) {
    return BaseService.get("/stadistics/user", callback, true)
}

function rental(callback) {
    return BaseService.get("/stadistics/suscription/rental", callback, true)
}

function market(callback) {
    return BaseService.get("/stadistics/suscription/market", callback, true)
}

function productactive(callback) {
    return BaseService.get("/stadistics/product/active", callback, true)
}

function productsold(callback) {
    return BaseService.get("/stadistics/product/sold", callback, true)
}

function removeLogic(id,callback) {
    return BaseService.delete("/stadistics/product/"+id+"/logic", callback);
}
