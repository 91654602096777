import React, { useState, useEffect }    from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useStyles,drawerWidth } from "../../layout/styles";
import CustomPaginationActionsTable from "../../table/Table"
import Filter from "../../filter/Filter"
import {manufacturereportService as service, manufactureService} from "../../../services"
import CrudEmpty from '../../crud/CrudEmpty';
import { Button } from '@material-ui/core';
const moment = require("moment");



  const initFilter =  {
      year : moment().year(),
      month: moment().month() + 1,
      manufacture: "", 
  };


export default function ManufactureReport() {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const [page, setPage] = React.useState(0);
    const [filter, setFilter] = React.useState(initFilter);
    const [tab, setTab] = React.useState(1);

    const [rowsVisitTotalSerie,setRowsVisitTotalSerie] = React.useState<any>([]);
    const [rowsVisitTotalSerieDay,setRowsVisitTotalSerieDay] = React.useState<any>([]);
    const [rowsVisitTotalDay,setRowsVisitTotalDay] = React.useState<any>([]);
    const [rowsVisitTotalSerieCountry,setRowsVisitTotalSerieCountry] = React.useState<any>([]);
    const [rowsVisitTotalCountry,setRowsVisitTotalCountry] = React.useState<any>([]);
    const [rowsRankingVisitasSerie,setRowsRankingVisitasSerie] = React.useState<any>([]);
    const [rowsRankingFabricantes,setRowsRankingFabricantes] = React.useState<any>([]);
    const [rowsRankingBusquedaSerie,setRowsRankingBusquedaSerie] = React.useState<any>([]);
    const [rowsRankingFabricantesMarket,setRowsRankingFabricantesMarket] = React.useState<any>([]);
    const [rowsRankingBusquedaSerieMarket,setRowsRankingBusquedaSerieMarket] = React.useState<any>([]);

    

    const cleanFilter = (filter) => {
      console.log(filter);
      setFilter(filter);
      search(filter);

    }

    const searchFilter = (filter) => {
      console.log(filter);
      setFilter(filter);
      search(filter);
    }


    const createEstructureFilter =  [
         {
           type:"input",
           name: "year",
           label: "Año",
           col: 4

         },
         {
          type:"autocomplete",
          name: "month",
          label: "Mes",
          options:[{key:1,name:"Enero"},{key:2,name:"Febrero"},{key:3,name:"Marzo"},{key:4,name:"Abril"},{key:5,name:"Mayo"},{key:6,name:"Junio"},{key:7,name:"Julio"},{key:8,name:"Agosto"},{key:9,name:"Septiembre"},{key:10,name:"Octubre"},{key:11,name:"Noviembre"},{key:12,name:"Diciembre"}],
          col: 4

        },
         {
           type:"autocomplete",
           name: "manufacture",
           label: "Fabricante",
           service: manufactureService.combo,
           col: 4
          }
      ];



    const search = (filter) => {
        if(filter.year && filter.month && filter.manufacture){
            setRowsVisitTotalSerie([]);
            setRowsVisitTotalSerieDay([]);
            setRowsVisitTotalDay([]);
            setRowsVisitTotalSerieCountry([]);
            setRowsRankingVisitasSerie([]);
            setRowsRankingFabricantes([]);
            setRowsRankingBusquedaSerie([]);
            setRowsRankingBusquedaSerieMarket([]);
            setRowsRankingFabricantesMarket([]);

            service.visitTotalSerie(filter.year, filter.month, filter.manufacture, (data, error) => {
                if(data){
                  console.log(data);
                  setRowsVisitTotalSerie(data.data);
                } else {
                  setRowsVisitTotalSerie([]);
                }
            });

            service.visitTotalSerieByDay(filter.year, filter.month, filter.manufacture, (data, error) => {
                if(data){
                  console.log(data);
                  setRowsVisitTotalSerieDay(data.data);
                } else {
                    setRowsVisitTotalSerieDay([]);
                }
            });

            service.visitTotalDay(filter.year, filter.month, filter.manufacture, (data, error) => {
                if(data){
                  console.log(data);
                  setRowsVisitTotalDay(data.data);
                } else {
                    setRowsVisitTotalDay([]);
                }
            });



            service.visitTotalCountry(filter.year, filter.month, filter.manufacture, (data, error) => {
                if(data){
                  console.log(data);
                  setRowsVisitTotalCountry(data.data);
                } else {
                    setRowsVisitTotalCountry([]);
                }
            });

            service.visitTotalSerieCountry(filter.year, filter.month, filter.manufacture, (data, error) => {
                if(data){
                  console.log(data);
                  setRowsVisitTotalSerieCountry(data.data);
                } else {
                    setRowsVisitTotalSerieCountry([]);
                }
            });

            service.rankingSeriesVisita(filter.year, filter.month, (data, error) => {
                if(data){
                  console.log(data);
                  setRowsRankingVisitasSerie(data.data);
                } else {
                    setRowsRankingVisitasSerie([]);
                }
            });


            service.rankingManufacture(filter.year, filter.month, (data, error) => {
                if(data){
                  console.log(data);
                  setRowsRankingFabricantes(data.data);
                } else {
                    setRowsRankingFabricantes([]);
                }
            });


            service.rankingSeriesBusquedas(filter.year, filter.month, (data, error) => {
                if(data){
                  console.log(data);
                  setRowsRankingBusquedaSerie(data.data);
                } else {
                    setRowsRankingBusquedaSerie([]);
                }
            });

            service.rankingSeriesBusquedasMarket(filter.year, filter.month, (data, error) => {
                if(data){
                  console.log(data);
                  setRowsRankingBusquedaSerieMarket(data.data);
                } else {
                    setRowsRankingBusquedaSerieMarket([]);
                }
            });

            service.rankingManufactureMarket(filter.year, filter.month, (data, error) => {
                if(data){
                  console.log(data);
                  setRowsRankingFabricantesMarket(data.data);
                } else {
                    setRowsRankingFabricantesMarket([]);
                }
            });

            
        }
    }

    const exportExcel = () => {

        service.excel(filter.year, filter.month, filter.manufacture, "StatisticsManufacturer-"+filter.manufacture+"-"+filter.year+filter.month+".xlsx", (data, error) => {
    
        });

    }

    useEffect(() => {
      search(filter);
    }, []);



  const paintCell = {
    total: (value,object) => {
      if (value) {
        return <>{value.toFixed(0)}</>
      } else {
        return <> -- </>
      }
    }
  }

  return (
    <CrudEmpty title="Estadisticas fabricante">
            <div>
                <Grid container style={{marginBottom: '50px'}}>
                    <Filter cleanFilter={cleanFilter} searchFilter={searchFilter} filter={filter} estructure={createEstructureFilter}  hiddenButton={true} expanded={true}/>
                </Grid>

                {rowsVisitTotalSerie && rowsVisitTotalSerie.length > 0 && <Grid container style={{marginBottom: '50px'}}>
                    <Button variant="contained" color="primary" onClick={(evt) => exportExcel()}> Exportar</Button>
                </Grid> }
                <Grid container style={{marginBottom: '50px'}}>
                        <Grid item sm={2} style={{textAlign:'left', marginTop: '50px', borderRight: "1px solid #ccc"}}>
                                <Button variant="outlined" color="primary" style={{marginLeft:'30px', width: "90%", marginBottom:"1em"}} onClick={(evt) => setTab(1)}> Visitas cada serie</Button>
                                <Button variant="outlined" color="primary" style={{marginLeft:'30px', width: "90%", marginBottom:"1em"}} onClick={(evt) => setTab(2)}> Visitas cada serie por día</Button>
                                <Button variant="outlined" color="primary" style={{marginLeft:'30px', width: "90%", marginBottom:"1em"}} onClick={(evt) => setTab(3)}> Visitas por día</Button>
                                <Button variant="outlined" color="primary" style={{marginLeft:'30px', width: "90%", marginBottom:"1em"}} onClick={(evt) => setTab(10)}> Visitas pais</Button>
                                <Button variant="outlined" color="primary" style={{marginLeft:'30px', width: "90%", marginBottom:"1em"}} onClick={(evt) => setTab(4)}> Visitas por serie / pais</Button>
                                <Button variant="outlined" color="primary" style={{marginLeft:'30px', width: "90%", marginBottom:"1em"}} onClick={(evt) => setTab(5)}> Ranking visitas serie</Button>
                                <Button variant="outlined" color="primary" style={{marginLeft:'30px', width: "90%", marginBottom:"1em"}} onClick={(evt) => setTab(6)}> Ranking Búsqueda por Fabricante</Button>
                                <Button variant="outlined" color="primary" style={{marginLeft:'30px', width: "90%", marginBottom:"1em"}} onClick={(evt) => setTab(7)}> Ranking Búsqueda por Serie</Button>
                                <Button variant="outlined" color="primary" style={{marginLeft:'30px', width: "90%", marginBottom:"1em"}} onClick={(evt) => setTab(8)}> Ranking Búsqueda por Fabricante en Marketplace</Button>
                                <Button variant="outlined" color="primary" style={{marginLeft:'30px', width: "90%", marginBottom:"1em"}} onClick={(evt) => setTab(9)}> Ranking Búsqueda por Serie en Marketplace</Button>
                        </Grid>
                        <Grid item sm={10} style={{textAlign:'left', marginTop: '50px'}}>
                             {tab == 1 && 
                                <Grid container style={{marginBottom: '50px', padding:"1em"}}>
                                        <h2>Visitas por serie</h2>
                                        <CustomPaginationActionsTable rows={rowsVisitTotalSerie} fields={["name","total"]} fieldId={"name"} headers={["Serie","Total"]} deleteaction={false} editable={false} hiddenpagination={true} paintCell={paintCell}/>
                                </Grid>
                             }
                             {tab == 2 && 
                                <Grid container style={{marginBottom: '50px', padding:"1em"}}>
                                        <h2>Visitas por serie y día</h2>
                                        <CustomPaginationActionsTable rows={rowsVisitTotalSerieDay} fields={["dayofmonth","name","total"]} fieldId={"name"} headers={["Día","Serie","Total"]} deleteaction={false} editable={false} hiddenpagination={true} paintCell={paintCell}/>
                                </Grid>
                             }
                             {tab == 3 && 
                                <Grid container style={{marginBottom: '50px', padding:"1em"}}>
                                        <h2>Visitas totales por día</h2>
                                        <CustomPaginationActionsTable rows={rowsVisitTotalDay} fields={["dayofmonth","total"]} fieldId={"name"} headers={["Día","Total"]} deleteaction={false} editable={false} hiddenpagination={true} paintCell={paintCell}/>
                                </Grid>
                             }
                             {tab == 4 && 
                                <Grid container style={{marginBottom: '50px', padding:"1em"}}>
                                        <h2>Visitas totales seire y pais</h2>
                                        <CustomPaginationActionsTable rows={rowsVisitTotalSerieCountry} fields={["name","country","total"]} fieldId={"name"} headers={["Serie","País","Total"]} deleteaction={false} editable={false} hiddenpagination={true} paintCell={paintCell}/>
                                </Grid>
                             }
                             {tab == 5 && 
                                <Grid container style={{marginBottom: '50px', padding:"1em"}}>
                                        <Grid item sm={12} style={{textAlign:'left'}}>
                                            <h2>Ranking Visitas Serie</h2>
                                        </Grid>
                                        <CustomPaginationActionsTable rows={rowsRankingVisitasSerie} fields={["name","country","total"]} fieldId={"name"} headers={["Serie","Fabricante","Total"]} deleteaction={false} editable={false} hiddenpagination={true} paintCell={paintCell}/>
                                </Grid>
                             }
                             {tab == 6 && 
                                <Grid container style={{marginBottom: '50px', padding:"1em"}}>
                                        <Grid item sm={12} style={{textAlign:'left'}}>
                                            <h2>Ranking Búsqueda por Fabricante</h2>
                                            <p><i>Se entiende como búsqueda pro fabricante, alguien que busvo directamente la marca o alguien que busco una serie dircetamente de este</i></p>
                                        </Grid>
                                        <CustomPaginationActionsTable rows={rowsRankingFabricantes} fields={["name","total"]} fieldId={"name"} headers={["Fabricante","Total"]} deleteaction={false} editable={false} hiddenpagination={true} paintCell={paintCell}/>
                                </Grid>
                             }
                             {tab == 7 && 
                                <Grid container style={{marginBottom: '50px', padding:"1em"}}>
                                        <Grid item sm={12} style={{textAlign:'left'}}>
                                            <h2>Ranking Búsquedas Serie</h2>
                                        </Grid>
                                        <CustomPaginationActionsTable rows={rowsRankingBusquedaSerie} fields={["name","country","total"]} fieldId={"name"} headers={["Serie","Fabricante","Total"]} deleteaction={false} editable={false} hiddenpagination={true} paintCell={paintCell}/>
                                </Grid>
                             }
                             {tab == 8 && 
                                <Grid container style={{marginBottom: '50px', padding:"1em"}}>
                                        <Grid item sm={12} style={{textAlign:'left'}}>
                                            <h2>Ranking Búsqueda por Fabricante en marketplace</h2>
                                            <p><i>Se entiende como búsqueda pro fabricante, alguien que busvo directamente la marca o alguien que busco una serie dircetamente de este</i></p>
                                        </Grid>
                                        <CustomPaginationActionsTable rows={rowsRankingFabricantesMarket} fields={["name","total"]} fieldId={"name"} headers={["Fabricante","Total"]} deleteaction={false} editable={false} hiddenpagination={true} paintCell={paintCell}/>
                                </Grid>
                             }
                             {tab == 9 && 
                                <Grid container style={{marginBottom: '50px', padding:"1em"}}>
                                        <Grid item sm={12} style={{textAlign:'left'}}>
                                            <h2>Ranking Búsquedas Serie en marketplace</h2>
                                        </Grid>
                                        <CustomPaginationActionsTable rows={rowsRankingBusquedaSerieMarket} fields={["name","country","total"]} fieldId={"name"} headers={["Serie","Fabricante","Total"]} deleteaction={false} editable={false} hiddenpagination={true} paintCell={paintCell}/>
                                </Grid>
                             }
                             {tab == 10 && 
                                <Grid container style={{marginBottom: '50px', padding:"1em"}}>
                                        <Grid item sm={12} style={{textAlign:'left'}}>
                                            <h2>Visitas totales pais</h2>
                                        </Grid>
                                        <CustomPaginationActionsTable rows={rowsVisitTotalCountry} fields={["name","total"]} fieldId={"name"} headers={["País","Total"]} deleteaction={false} editable={false} hiddenpagination={true} paintCell={paintCell}/>
                                </Grid>
                             }



                        </Grid>
                </Grid>
            </div>
    </CrudEmpty>
  );
}
