import React, { useState, useEffect }   from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles,drawerWidth } from "../../layout/styles";
import CustomPaginationActionsTable from "../../table/Table"
import Filter from "../../filter/Filter"
import {wishproductService as service, serieService,  cameraService} from "../../../services"
import ExploitListData from '../../exploitdata/ExploitListData';
import ExploitListContactData from '../../exploitdata/ExploitListContactData';

const initFilter =  {
    email: null,
    camera: null,
    serie: null,
    from: null,
    to: null
};


export default function WishProduct() {
  const history = useHistory();
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [page, setPage] = React.useState(0);
  const [filter, setFilter] = React.useState(initFilter);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const fieldstable = ["createDate","user","serie.name","camera.name","attrtype.name","attrmark.name","manufacture.name","attrfocal.name","priceFrom","priceTo"];
  const headersTable =  ["Fecha Crea.","Usuario","Serie","Camara","Tipo Lente","Familia","Fabricante","Focal","Precio desde", "Precio hasta"];
  const [open, setOpen] = React.useState(true);
  const [rows,setRows] = React.useState([{}]);
  const [rowsTotal,setRowsTotal] = React.useState(0);


  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const changePageTable = (page,size) => {
    search(page,size);
  };

  const cleanFilter = () => {
    search(0,rowsPerPage);
  }

  const searchFilter = (filter) => {
    search(0,rowsPerPage);
  }


    const createEstructureFilter =  [
         {
           type:"input",
           name: "email",
           label: "Email",
           col: 4
          },
           {
             type:"autocomplete",
             name: "serie",
             label: "Serie",
             service: serieService.combo,
             col: 4
            },
           {
             type:"autocomplete",
             name: "camera",
             label: "Camara",
             service: cameraService.combo,
             col: 4
            },
            {
              type:"input",
              name: "from",
              label: "Fecha Desde",
              inputtype:"date",
              col: 4
             },
             {
               type:"input",
               name: "to",
               label: "Fecha Hasta",
               inputtype:"date",
               col: 4
              },
      ];



    const search = (page,size) => {
      if (!filter["state"] || filter["state"] == ""){
         filter["state"] = null;
      }
      setPage(page);
      setRowsPerPage(size);
      service.search(page,size,filter, (data, error) => {
          if(data){
            console.log(data);
            setRows(data.data);
            setRowsTotal(data.total);
          } else {
            setRows([{}]);
            setRowsTotal(0);
          }
      });
    }

  useEffect(() => {
    search(0,10);
  }, []);



  const deleteHandler = (id) => {
    service.remove(id, (data, error) => {
        if(data){
          console.log(data);
            search(page,rowsPerPage);
        }
    });
  }

  const eventSelect = (id) => {
  }


  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title="Productos deseados" classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container style={{marginBottom: '50px'}}>
            <Filter cleanFilter={cleanFilter} searchFilter={searchFilter} filter={filter}  estructure={createEstructureFilter} titlebutton={'Añadir Producto'}  urlnew={'/marketplace/new'}/>
          </Grid>
          <Grid container spacing={3} className={classes.bodycontainer}>
            <CustomPaginationActionsTable rows={rows} fields={fieldstable} fieldId={"id"} rowsPerPage={10} rowsTotal={rowsTotal} changePage={changePageTable} headers={headersTable} page={page}  deleteaction={false} deleteactionHandler={deleteHandler}  selectHandler={eventSelect}/>  
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
