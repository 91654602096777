import BaseService from './_base.service';

export const rolesService = {
    search,
    edit,
    create,
    combo,
    one,
    remove,
    permissions,
    removeLogic
};

function search(offset,callback) {
    return BaseService.get("/rol/all/", callback)
}

function combo(callback) {
    return BaseService.get("/rol/combo/", callback, true)
}
function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/rol/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/rol/", body, callback, true)
}


function one(id,callback) {
    return BaseService.get("/rol/one/"+id, callback);
}

function permissions(callback) {
    return BaseService.get("/permission/all/", callback)
}
function remove(id,callback) {
    return BaseService.delete("/rol/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete("/rol/"+id+"/logic", callback);
}
