import BaseService from './_base.service';

export const cityService = {
    page,
    edit,
    combo,
    comboCountry,
    create,
    one,
    remove,
    removeLogic
};

function page(page,size, callback) {
    return BaseService.get("/city/page/"+page+"/"+size, callback, true)
}
function comboCountry(idCountry,callback) {
    return BaseService.get("/city/country/"+idCountry+"/combo/", callback, true)
}
function combo(callback) {
    return BaseService.get("/city/combo/", callback, true)
}
function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/city/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/city/", body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/city/one/"+id, callback);
}

function remove(id,callback) {
    return BaseService.delete("/city/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete("/city/"+id+"/logic", callback);
}
