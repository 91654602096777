import BaseService from './_base.service';

export const bannerpositionService = {
    page,
    edit,
    create,
    one,
    remove,
    search,
    removeLogic,
    combo
};

function page(page,size, callback) {
    return BaseService.get("/bannerposition/page/"+page+"/"+size, callback, true)
}

function combo(callback) {
    return BaseService.get("/bannerposition/combo/", callback, true)
}
function search(page,size,filter, callback) {
    return BaseService.post("/bannerposition/page/"+page+"/"+size,filter, callback, true)
}
function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/bannerposition/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/bannerposition/", body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/bannerposition/one/"+id, callback);
}

function remove(id,callback) {
    return BaseService.delete("/bannerposition/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete("/bannerposition/"+id+"/logic", callback);
}
