import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles,drawerWidth } from "../../layout/styles";
import CustomPaginationActionsTable from "../../table/Table"
import Filter from "../../filter/Filter"
import Form from "../../form/Form"
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {userService as service,rentalfamilyService, dealerfamilyService, rolesService, suscriptionService, usersuscriptionService, jobService, countryService, cityService,languageService, creditsUserService, crewViewService, manufactureService} from "../../../services";
import ExploitIndividualData from '../../exploitdata/ExploitIndividualData';

const initElement = {
     email:"",
     emailcontact:"",
     pass: "",
     name: "",
     lastname: "",
     picture: "",
     street: "",
     phone: "",
     number:"",
     door: "",
     floor: "",
     stairs: "",
     city: {id: null, name:""},
     country: {id: null, name:""},
     postalcode: "",
     instagramAccount: "",
     isClient: true,
     dateCreate: "",
     acceptTerms: "",
     acceptPoli: "",
     rol: {code:null,name:""},
     enable:true,
     familyRental:{
       id:null, name:""
     },
     familyDealer: {
       id:null, name:""
     },
     job:{
       id:null, name:""
     },
     manufacture:{
      id:null, name:""
    },
     job2:{
       id:null, name:""
     },
     imdb: "",
     vimeo: "",
     web: "",
     facebook: "",
     linkedin: "",
     accpetCrew: false,
	   curriculumVitae:"",
	   instagram:"",
     available: false,
	   finisheDate:"",
     languages:[]
  };


const initSus = {
    user: null,
    suscriptionType: {id:null,name:""},
    suscriptionActive: false,
    suscriptionToken: "",
    finishSuscription: ""
}

const initCredit = {
    user: null,
    imdb: {
      reference:"",
      title:"hola",
      description:"",
      image:"",
      year:"",
      link:"",
      director:"",
      writer:"",
    },
    job: {id: null, name:""}
}

const useStyles2 = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 300,
  },
}));

export default function FormUser() {
  const history = useHistory();
  let { id } = useParams();
  const classes2 = useStyles2();
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [objectform, setObjectform] = React.useState({...initElement});
  const [objectformSus, setObjectformSus] = React.useState(initSus);
  const [titleWindow, setTitleWindow] = React.useState(id && id > 0? 'Editar usuario':'Nuevo usuario');
  const [idRow, setIdrow] = React.useState(id);
  const [idSerie, setIdSerie] = React.useState(-1);
  const [cities,setCities] = React.useState([]);
  const [sus,setSus] = React.useState(false);
  const [expSus,setExpSus] = React.useState(false);
  const [idSus,setIdSus] = React.useState(-1);
  const [titleSus,setTitleSus] = React.useState("Añadir suscripción");

  const fieldstable = ["suscriptionType.name","suscriptionActive","suscriptionToken","finishSuscription"];
  const headersTable =  ["Tipo","Activa","Token Stripe","Fecha caducidad"];

  const [open, setOpen] = React.useState(true);
  const [rows,setRows] = React.useState([{}]);
  const [rowsTotal,setRowsTotal] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);


  const [expCred,setExpCred] = React.useState(false);
  const [objectformCredits, setObjectformCredits] = React.useState(initCredit);
  const [rowsCred,setRowsCred] = React.useState([{}]);
  const [rowsTotalCred,setRowsTotalCred] = React.useState(0);
  const [pageCred, setPageCred] = React.useState(0);
  const [rowsPerPageCred, setRowsPerPageCred] = React.useState(10);

  const fieldstableCred = ["imdb.image","imdb.title","job.name"];
  const headersTableCred =  ["#","Credit","Puesto"];


  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };


  const [estructureform,setEstructureform] = React.useState([
         {
           type:"input",
           name: "email",
           label: "Email",
           col: 6
          },
         {
           type:"input",
           name: "emailcontact",
           label: "Email de contacto",
           col: 6
          },
          {
            type:"input",
            name: "pass",
            label: "Contraseña",
            inputtype: "password",
            col: 6
          },
         {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 4
          },
         {
           type:"input",
           name: "lastname",
           label: "Apellido",
           col: 4
          },
          {
            type:"input",
            name: "street",
            label: "Calle",
            col: 4
           },
          {
            type:"input",
            name: "number",
            label: "Nº",
            col: 1
           },
          {
            type:"input",
            name: "floor",
            label: "Piso",
            col: 1
           },
          {
            type:"input",
            name: "stairs",
            label: "Escalera",
            col: 2
           },
           {
            type:"autocomplete",
            name: "country.id",
            label: "País",
            service: countryService.combo,
            relationfield:'city.id',
            servicerelation:cityService.comboCountry,
            col: 4
          },
           {
            type:"autocomplete",
            name: "city.id",
            label: "Ciudad",
            options: Array(),
            col: 4
          },
          {
            type:"input",
            name: "postalcode",
            label: "C.P",
            col: 4
           },
           {
            type:"autocomplete",
            name: "job.id",
            label: "Puesto 1",
            service: jobService.combo,
            col: 4
           },
           {
            type:"autocomplete",
            name: "job2.id",
            label: "Puesto 2",
            service: jobService.combo,
            col: 4
           },
          {
            multiple:true,
            type:"autocomplete",
            name: "languages",
            label: "Idiomas",
            service: languageService.combo,
            col: 4
           },
          {
            type:"input",
            name: "instagramAccount",
            label: "Instagram",
            col: 4
           },
          {
            type:"input",
            name: "facebook",
            label: "Facebook",
            col: 4
           },
          {
            type:"input",
            name: "linkedin",
            label: "LinkedIn",
            col: 4
           },
          {
            type:"input",
            name: "instagram",
            label: "Instagram",
            col: 4
           },
          {
            type:"input",
            name: "web",
            label: "Web/Portofolio",
            col: 4
           },
          {
            type:"input",
            name: "imdb",
            label: "IMBD",
            col: 4
           },
          {
            type:"input",
            name: "vimeo",
            label: "Vimeo",
            col: 4
           },
          {
            type:"input",
            name: "videoProfileYoutube",
            label: "Video Perfil Youtube",
            col: 4
           },
          {
            type:"input",
            name: "videoProfileVimeo",
            label: "Video Perfil Vimeo",
            col: 4
           },
         {
          type:"autocomplete",
          name: "familyRental.id",
          label: "Familia Rental",
          service: rentalfamilyService.combo,
          col: 4
         },
         {
          type:"autocomplete",
          name: "familyDealer.id",
          label: "Familia Dealer",
          service: dealerfamilyService.combo,
          col: 4
         },
         {
           type:"autocomplete",
           name: "manufacture.id",
           label: "Fabricante",
           service: manufactureService.combo,
           col: 4
          },
         {
          type:"autocomplete",
          name: "rol.code",
          label: "Rol",
          service: rolesService.combo,
          col: 4
         },
        {
          type:"input",
          name: "dateCreate",
          label: "F. Creación",
          col: 4
         },
        {
          type:"input",
          name: "acceptTerms",
          label: "F. temrinos",
          col: 4
         },
        {
          type:"input",
          name: "acceptPoli",
          label: "F. Politica",
          col: 4
        },
       {
          type:"checkbox",
          name: "isClient",
          label: "Es cliente/usuario",
          col: 2
       },
        {
           type:"checkbox",
           name: "enable",
           label: "Habilitado",
           col: 2
        },
         {
            type:"checkbox",
            name: "accpetCrew",
            label: "Crew activo",
            col: 2
         },
        {
           type:"checkbox",
           name: "available",
           label: "Disponible para trabajar",
           col: 2
        },
       {
         type:"input",
         name: "finisheDate",
         label: "F. Fin Ocupado",
         col: 4
        },
         {
           type:"image",
           name: "picture",
           label: "Imagen",
           col: 6
         },
         {
           type:"textarea",
           name: "curriculumVitae",
           label: "Curriculum Vitae",
           col: 12
          }
    ]);




  useEffect(() => {
      if(idRow && idRow > 0){
        searchOne();
        setTitleWindow('Editar usuario');
        setSus(true);
        search(page,rowsPerPage);
        searchCredits(page,rowsPerPage);
      };

  }, []);

  const searchOne = function(id = -1){
    if(id == -1){
      id = idRow;
    }
    service.one(id,(data, error) => {
      if(data && data.data){
        var element = data.data;
        let objectedit = JSON.parse(JSON.stringify(objectform));
        objectedit['email']=element.email?element.email:"";
        objectedit['emailcontact']=element.emailcontact?element.emailcontact:"";
        objectedit['name']=element.name?element.name:"";
        objectedit['lastname']=element.lastname?element.lastname:"";
        objectedit['picture']=element.picture?element.picture:"";
        objectedit['street']=element.street?element.street:"";
        objectedit['phone']=element.phone?element.phone:"";
        objectedit['number']=element.number?element.number:"";
        objectedit['door']=element.door?element.door:"";
        objectedit['floor']=element.floor?element.floor:"";
        objectedit['stairs']=element.stairs?element.stairs:"";
        objectedit['city']={id:element.city && element.city.id?element.city.id:null, name: element.city && element.city.name?element.city.name:null};
        objectedit['country']={id:element.country && element.country.id?element.country.id:null, name: element.country && element.country.name?element.country.name:null};
        objectedit['postalcode']=element.postalcode?element.postalcode:"";
        objectedit['instagramAccount']=element.instagramAccount?element.instagramAccount:"";
        objectedit['isClient']=element.isClient?element.isClient:false;
        objectedit['dateCreate']=element.dateCreate?element.dateCreate:"";
        objectedit['acceptTerms']=element.acceptTerms?element.acceptTerms:"";
        objectedit['acceptPoli']=element.acceptPoli?element.acceptPoli:"";
        objectedit['imdb']=element.imdb?element.imdb:"";
        objectedit['vimeo']=element.vimeo?element.vimeo:"";
        objectedit['linkedin']=element.linkedin?element.linkedin:"";
        objectedit['instagram']=element.instagram?element.instagram:"";
        objectedit['web']=element.web?element.web:"";
        objectedit['facebook']=element.facebook?element.facebook:"";

        objectedit['state']=element.state?element.state:"";
        objectedit['familyRental']= {id:element.familyRental && element.familyRental.id?element.familyRental.id:null, name: element.familyRental && element.familyRental.name?element.familyRental.name:null};
        objectedit['familyDealer']= {id:element.familyDealer && element.familyDealer.id?element.familyDealer.id:null, name: element.familyDealer && element.familyDealer.name?element.familyDealer.name:null};
        objectedit['manufacture']= {id:element.manufacture && element.manufacture.id?element.manufacture.id:null, name: element.manufacture && element.manufacture.name?element.manufacture.name:null};
        objectedit['rol']= {code:element.rol && element.rol.code?element.rol.code:null, name: element.rol && element.rol.name?element.rol.name:null};
        objectedit['curriculumVitae']=element.curriculumVitae?element.curriculumVitae:"";
        objectedit['available']=element.available?element.available:false;
        objectedit['accpetCrew']=element.accpetCrew?element.accpetCrew:false;
        objectedit['finisheDate']=element.finisheDate?element.finisheDate:"";
        objectedit['videoProfileVimeo']=element.videoProfileVimeo?element.videoProfileVimeo:"";
        objectedit['videoProfileYoutube']=element.videoProfileYoutube?element.videoProfileYoutube:"";
        objectedit['job']={id:element.job && element.job.id?element.job.id:null, name: element.job && element.job.name?element.job.name:null};
        objectedit['job2']={id:element.job2 && element.job2.id?element.job2.id:null, name: element.job2 && element.job2.name?element.job2.name:null};
        objectedit['languages'] = Array();
        if(element.languages){
          for (let index = 0; index < element.languages.length; index++) {
            const lang = element.languages[index];
            objectedit['languages'].push({id: lang.id, name: lang.name});
          }
        }

        
        setObjectform(objectedit);

     }
    });
  }


  const cancelForm = (object) => {
      history.push('/users');
  }

  const saveForm = (object) => {
       if(isValid(object)) {
         if(!object["familyDealer"] || !object["familyDealer"]["id"]){
           object["familyDealer"] = null;
         }
         if(!object["familyRental"] || !object["familyRental"]["id"]){
           object["familyRental"] = null;
         }
         if(!object["job"] || !object["job"]["id"]){
           object["job"] = null;
         }
         if(!object["rol"] || !object["rol"]["code"]){
           object["rol"] = null;
         }
         if(!object["city"] || !object["city"]["id"]){
           object["city"] = null;
         }
         if(!object["country"] || !object["country"]["id"]){
           object["country"] = null;
         }
         var objToSave = cleanElementsBeforeSave(object);
         if (idRow && idRow > 0){
           objToSave['id'] = id;
           update(objToSave,false);
         } else {
           create(objToSave,false);
         }
       }

  }

  const create = (object,cleanSerie) => {
    service.create(object, (data, error) => {
        if(data){
          setIdrow(data.data.id)
          searchOne(data.data.id);
          setTitleWindow('Editar usuario');
          setSus(true);
          search(page,rowsPerPage,data.data.id);
          searchCredits(page,rowsPerPage,data.data.id);
          history.push('/users/'+data.data.id);
        }
    });
  }

  const update = (object,cleanSerie) => {
    service.edit(id,object, (data, error) => {
        if(data){

        }
    });
  }



  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      }
      if(!object['lastname']){
        toast.notify("Debe indicar el apellido", {position:'top', duration: 10000})
        return false;
      }
      if(!object['email']){
        toast.notify("Debe el email", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }

  const cleanElementsBeforeSave = function(object){
    let newObj = JSON.parse(JSON.stringify(object));
    return newObj;
  }



  const search = (page,size,id = -1) => {
    if(id == -1){
      id = idRow;
    }
    setPage(page);
    setRowsPerPage(size);
    usersuscriptionService.all(id,(data, error) => {
        if(data){
          setRows(data.data);
          setRowsTotal(data.total);
        } else {
          setRows([{}]);
          setRowsTotal(0);
        }
    });
  }

  const searchCredits = (page,size,id = -1) => {
    if(id == -1){
      id = idRow;
    }
    setPageCred(page);
    setRowsPerPageCred(size);
    creditsUserService.user(id,(data, error) => {
        if(data){
          setRowsCred(data.data);
          setRowsTotalCred(data.total);
        } else {
          setRowsCred([{}]);
          setRowsTotalCred(0);
        }
    });
  }



  const [estructureformsus,setEstructureformsus] = React.useState([
          {
           type:"autocomplete",
           name: "suscriptionType.id",
           label: "Tipo de suscripción",
           service: suscriptionService.combo,
           col: 3
          },
          {
           type:"input",
           name: "suscriptionToken",
           label: "Token stripe",
           col: 3
          },
         {
           type:"input",
           name: "finishSuscription",
           label: "F. caducidad",
           inputtype: "date",
           col: 3
         },
         {
            type:"checkbox",
            name: "suscriptionActive",
            label: "Activa",
            col: 3
         }
    ]);

    const [estructureformcred,setEstructureformcred] = React.useState([
            {
             type:"imdb",
             name: "imdb",
             label: "Pelicula",
             col: 3
            },
            {
             type:"autocomplete",
             name: "job.id",
             label: "Puesto",
             service: jobService.combo,
             col: 3
            },
      ]);

  const saveSus = (data) => {
    console.log(data);
    if( isValidSus(data)) {
      data['finishSuscription'] = data['finishSuscription']+"T00:00:00Z";
      setObjectformSus(data);
      if (idSus == -1) {
        usersuscriptionService.create(idRow,data,(data, error) => {
            if(data){
              search(page,rowsPerPage);
              cleanForm(initSus);
            }
        });
      } else {
        usersuscriptionService.edit(idSus,data,(data, error) => {
            if(data){
              search(page,rowsPerPage);
              cleanForm(initSus);
            }
        });
      }
      setIdSus(-1);
      setTitleSus("Añadir suscripción");
      setExpSus(false);
    }

  }

  const saveCred = (data) => {
    debugger;
    console.log(data);
    if( isValidCred(data)) {
      data["user"] = {id:idRow}
      setObjectformCredits(data);
      creditsUserService.create(data,(data, error) => {
          if(data){
            searchCredits(page,rowsPerPage);
          }
      });
      setExpSus(true);
    }

  }



  const cleanForm = (data) => {
    console.log(data);
    setIdSus(-1)
    setTitleSus("Añadir suscripción");
  }

  const deleteHandlerSus = (id) => {
    usersuscriptionService.remove(id, (data, error) => {
        if(data){
          console.log(data);
            search(page,rowsPerPage);
        }
    });
  }

  const deleteHandlerCredit = (id) => {
    creditsUserService.remove(id, (data, error) => {
        if(data){
            searchCredits(page,rowsPerPage);
        }
    });
  }

  const eventSelect = (id) => {
      console.log("Selecciona el id " + id);
      for (var row of rows) {
        if (row["id"] == id){
          var element = row;
          let objectedit = JSON.parse(JSON.stringify(objectformSus));
          objectedit['suscriptionActive']=element["suscriptionActive"]?element["suscriptionActive"]:false;
          objectedit['suscriptionToken']=element["suscriptionToken"]?element["suscriptionToken"]:"";
          objectedit['finishSuscription']=element["finishSuscription"]?element["finishSuscription"].replace("T00:00:00Z",""):"";
          objectedit['suscriptionType']= {id:element["suscriptionType"] && element["suscriptionType"]["id"]?element["suscriptionType"]["id"]:null, name: element["suscriptionType"] && element["suscriptionType"]["name"]?element["suscriptionType"]["name"]:null};

          setObjectformSus(objectedit)
          setExpSus(true)
          setIdSus(id)
          setTitleSus("Editar suscripción");
          break;
        }
      }
  }

  const handleChange = () => (event, newExpanded) => {
     setExpSus(!expSus);
   };

   const handleChangeCredits = () => (event, newExpanded) => {
      setExpCred(!expCred);
    };


   const isValidSus = function(object){
       if(!object['suscriptionType'] || !object['suscriptionType']["id"]){
         toast.notify("Debe indicar un tipo de suscripción", {position:'top', duration: 10000})
         return false;
       }
       if(!object['finishSuscription']){
         toast.notify("Debe indicar una fecah de fin", {position:'top', duration: 10000})
         return false;
       }

       return true;
   }


    const isValidCred = function(object){
        if(!object['imdb'] || !object['imdb']["reference"]){
          toast.notify("Debe indicar la peli", {position:'top', duration: 10000})
          return false;
        }

        return true;
    }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title={titleWindow} classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} className={classes.bodycontainer}>
              <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                  <Form objectform={objectform} fields={estructureform}  searchFilter={saveForm} cleanFilter={cancelForm} labelsave="Guardar" labelcancel="Cancelar"/>
              </Grid>
              {sus ?
                <>
                  <Grid container>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h2>Suscripciones del usuario </h2>
                    </Grid>
                    <Grid item sm={12} xs={12} >
                      <Accordion expanded={expSus}  onChange={handleChange()}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
                          <Typography className={classes2.heading}> <label style={{fontSize:'1.3em',fontWeight:'bold'}}>{titleSus}</label>  </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Form objectform={objectformSus} fields={estructureformsus}  searchFilter={saveSus} cleanFilter={cleanForm} labelsave="Guardar" labelcancel="Limpiar" cleanInOK={true}/>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <CustomPaginationActionsTable rows={rows} fields={fieldstable} fieldId={"id"} rowsPerPage={10} rowsTotal={rowsTotal} headers={headersTable} page={page}  deleteaction={true}  deleteactionHandler={deleteHandlerSus}  selectHandler={eventSelect}/>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h2>Credits </h2>
                    </Grid>
                    <Grid item sm={12} xs={12} >
                      <Accordion expanded={expCred}  onChange={handleChangeCredits()}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
                          <Typography className={classes2.heading}> <label style={{fontSize:'1.3em',fontWeight:'bold'}}>Añadir Credit</label>  </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Form objectform={objectformCredits} fields={estructureformcred}  searchFilter={saveCred} cleanFilter={cleanForm} labelsave="Guardar" labelcancel="Limpiar" cleanInOK={true}/>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <CustomPaginationActionsTable rows={rowsCred} fields={fieldstableCred} fieldId={"id"} rowsPerPage={10} rowsTotal={rowsTotalCred} headers={headersTableCred} page={pageCred}  deleteaction={true}  deleteactionHandler={deleteHandlerCredit} />
                    </Grid>
                  </Grid>


                </>
              : "" }
          </Grid>

          {idRow && idRow > 0 && objectform['accpetCrew'] && <ExploitIndividualData id={idRow} service={crewViewService} /> }
          

          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
