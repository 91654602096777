import React, { useState, useEffect }  from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles } from '../../layout/styles';
import CustomPaginationActionsTable from '../../table/Table';
import Filter from '../../filter/Filter';
import { Link } from 'react-router-dom';
import { manufactureSusService as service, serieService, rentalfamilyService } from '../../../services';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';


const initFilter =  {
    serie: "",
    familyRental : ""
};


const initAdd =  {
    serie: {id:null,name:""},
    familyRental : {id:null,name:""}
};




export default function FormManufactureSusSerie(props) {

  let { id,manu } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState({...initFilter});
  const fieldstable = ["serie.name","familyRental.name"];
  const [idRow, setIdrow] = useState(id);
  const headersTable =  ["Serie","Rental"];  
  
  const serieManu = (callback) => {
    serieService.comboManufacture(manu,callback);
  }
  const estructureForm =  [

    {
       type:"autocomplete",
       name: "serie",
       label: "Serie",
       service: serieManu,
       col: 4
      },

    {
        type:"autocomplete",
        name: "familyRental",
        label: "Rental",
        service: rentalfamilyService.combo,
        col: 4
       }
  ];
  
  const titlePage =  "Rentals añadidos en la suscripcion";
  const fieldId =  "id";
  const hiddenFilter = false;
  const editable = false

  const [open, setOpen] = useState(true);
  const [rows,setRows] = useState([{}]);
  const [rowsTotal,setRowsTotal] = useState(0);
  const [haveother,setHaveother]  = useState(props.haveother? props.haveother : false);
  const [othersform,setOthersform]  = useState(props.othersform? props.othersform : null);


  const [objectAdd, setObjectAdd] = useState({...initAdd});


  const estructureFormAdd =  [

    {
       type:"autocomplete",
       name: "serie.id",
       label: "Serie",
       service: serieManu,
       col: 4
      },

    {
        type:"autocomplete",
        name: "familyRental.id",
        label: "Rental",
        service: rentalfamilyService.combo,
        col: 4
       }
  ];


  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const changePageTable = (page,size) => {
    search(page,size);
  };

  const cleanFilter = (filter) => {
    console.log(filter);
    setFilter(filter);
    search(0,rowsPerPage);

  }

  const cleanObjectAdd = (filter) => {
    setObjectAdd(filter);

  }

  const searchFilter = (filter) => {
    console.log(filter);
    setFilter(filter);
    search(0,rowsPerPage);
  }


  const search = (page,size) => {
    setPage(page);
    setRowsPerPage(size);
    service.searchSeries(idRow,page,size,filter, (data, error) => {
        if(data){
          console.log(data);
          setRows(data.data);
          setRowsTotal(data.total);
        } else {
          setRows([{}]);
          setRowsTotal(0);
        }
    });
  }

  const addFilter = (object) => {
    service.addSeries(idRow, object, (data,error) => {
        if(data && data.data) {
            search(page,rowsPerPage);
        
            toast.notify("Guardado correctamente", {position:'top', duration: 10000})
        } else {
            toast.notify("No se ha podido guardar", {position:'top', duration: 10000})
        }
    });
  }

  useEffect(() => {
    search(0,10);
  }, []);


  const deleteHandler = (id) => {
    var r:any = null;
    rows.forEach(element => {
        if(element["id"] == id){
            r = element;
        }
    });

    if(r) {
        service.removeSeries(idRow, r, (data,error) => {
            if(data && data.data) {
                search(page,rowsPerPage);
                toast.notify("Borrada correctamente", {position:'top', duration: 10000})
            } else {
                toast.notify("No se ha podido guardar", {position:'top', duration: 10000})
            }
        });
        
    }
  }



  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title={titlePage} classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
            <Grid container style={{marginBottom: '50px'}}>
                <Grid sm={5}>
                    <Filter cleanFilter={cleanObjectAdd} searchFilter={addFilter} filter={objectAdd} estructure={estructureFormAdd} hiddenButton={true} title={"Añadir"} titlebtnsave={"Guardar"}/>
                </Grid>
                <Grid sm={2}></Grid>
                <Grid sm={5}>
                    <Filter cleanFilter={cleanFilter} searchFilter={searchFilter} filter={filter} estructure={estructureForm} hiddenButton={true}/>
                </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.bodycontainer}>
              <CustomPaginationActionsTable rows={rows} fields={fieldstable} fieldId={fieldId} rowsPerPage={10} rowsTotal={rowsTotal} changePage={changePageTable} headers={headersTable} page={page} deleteaction={true} deleteactionHandler={deleteHandler} selectHandler={null}  editable={false} />
              {haveother && othersform}
            </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
