import React, { useState, useEffect }   from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudTable from "../../crud/CrudTable"
import {tstopService as service} from "../../../services"

const initFilter =  {
    name : "",
    min: "",
    max: null,
};



export default function TStop() {
  const [filter, setFilter] = React.useState(initFilter);
  const fieldstable = ["name","min","max"];
  const headersTable =  ["Nombre"," Min.","Max."];

  const createEstructureFilter =  [
    {
      type:"input",
      name: "name",
      label: "Nombre",
      col: 4
     },
     {
       type:"input",
       name: "min",
       label: "Min.",
       inputtype:"number",
       step:0.1,
       col: 4
      },
     {
       type:"input",
       name: "max",
       label: "Max",
       inputtype:"number",
       step:0.1,
       col: 4
      }
    ];


  return (
    <CrudTable
          service={service}
          titlePage={"TStop"}
          titleButton={"Crear T.Stop"}
          urledit={"/lens/tstop/"}
          urlnew={"/lens/tstop/new/"}
          filter={initFilter}
          fieldstable={fieldstable}
          headersTable={headersTable}
          estructureForm={createEstructureFilter}
      />
  );
}
