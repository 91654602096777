import BaseService from './_base.service';

export const serieService = {
    page,
    search,
    combo,
    edit,
    create,
    one,
    remove,
    removeLogic,
    comboManufacture
};

function page(page,size, callback) {
    return BaseService.get("/serie/page/"+page+"/"+size, callback, true)
}
function search(page,size,filter, callback) {
    return BaseService.post("/serie/page/"+page+"/"+size,filter, callback, true)
}
function combo(callback) {
    return BaseService.get("/serie/combo/", callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/serie/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/serie/", body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/serie/one/"+id, callback);
}

function remove(id,callback) {
    return BaseService.delete("/serie/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete("/serie/"+id+"/logic", callback);
}

function comboManufacture(id,callback) {
    return BaseService.get("/serie/combo/manufacture/"+id, callback, true)
}

