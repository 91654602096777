import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';
import Moment from 'react-moment';
import moment from 'moment';
import { ArrowBack } from '@material-ui/icons';

export default function ExploitListData(props) {

    
    const [day,setDay] = React.useState([]);
    const [dayProfile,setDayProfile] = React.useState([]);
    const [week,setWeek] = React.useState([]);
    const [weekProfile,setWeekProfile] = React.useState([]);
    const [weekUser,setWeekUser] = React.useState([]);
    const [month,setMonth] = React.useState([]);
    const [monthProfile,setMonthProfile] = React.useState([]);
    const [monthUser,setMonthUser] = React.useState([]);

    const [weekSelected, setWeekSelected] = React.useState(moment());
    const [monthSelected, setMonthSelected] = React.useState(moment());


    const [daycountry,setDaycountry] = React.useState([]);
    const [daycountryProfile,setDaycountryProfile] = React.useState([]);
    const [weekcountry,setWeekcountry] = React.useState([]);
    const [weekcountryProfile,setWeekcountryProfile] = React.useState([]);
    const [monthcountry,setMonthcountry] = React.useState([]);
    const [monthcountryProfile,setMonthcountryProfile] = React.useState([]);

    const [weekcountrySelected, setWeekcountrySelected] = React.useState(moment());
    const [monthcountrySelected, setMonthcountrySelected] = React.useState(moment());
    

    let service = props.service;


    const moreViewTodayGeneral = () => {
        service.moreViewTodayGeneral((data,error) => {
             if(data && data.data){
                setDay(data.data);
             }
        });
    }

    const moreViewTodayProfile = () => {
        service.moreViewTodayProfile((data,error) => {
             if(data && data.data){
                setDayProfile(data.data);
             }
        });
    }

    const moreViewWeekGeneral = (date) => {
        service.moreViewWeekGeneral(date,(data,error) => {
             if(data && data.data){
                setWeek(data.data);
             }
        });
    }

    const moreViewWeekProfile = (date) => {
        service.moreViewWeekProfile(date,(data,error) => {
             if(data && data.data){
                setWeekProfile(data.data);
             }
        });
    }

    const moreViewWeekUser = (date) => {
        service.exploitdatausergeneralday(date,(data,error) => {
             if(data && data.data){
                setWeekUser(data.data);
             }
        });
    }


    

    const moreViewMonthGeneral = (month,year) => {
        service.moreViewMonthGeneral(month,year,(data,error) => {
             if(data && data.data){
                setMonth(data.data);
             }
        });
    }

    const moreViewMonthProfile = (month,year) => {
        service.moreViewMonthProfile(month,year,(data,error) => {
             if(data && data.data){
                setMonthProfile(data.data);
             }
        });
    }

    const exploitdatausergeneralweek = (month,year) => {
        service.exploitdatausergeneralweek(month,year,(data,error) => {
             if(data && data.data){
                setMonthUser(data.data);
             }
        });
    }



    

    


    /** COUNTRY */


    const moreViewCountryTodayGeneral = () => {
        service.moreViewCountryTodayGeneral((data,error) => {
             if(data && data.data){
                debugger;
                setDaycountry(data.data);
             }
        });
    }

    const moreViewCountryTodayProfile = () => {
        service.moreViewCountryTodayProfile((data,error) => {
             if(data && data.data){
                setDaycountryProfile(data.data);
             }
        });
    }

    const moreViewCountryWeekGeneral = (date) => {
        service.moreViewCountryWeekGeneral(date,(data,error) => {
             if(data && data.data){
                setWeekcountry(data.data);
             }
        });
    }

    const moreViewCountryWeekProfile = (date) => {
        service.moreViewCountryWeekProfile(date,(data,error) => {
             if(data && data.data){
                setWeekcountryProfile(data.data);
             }
        });
    }


    const moreViewCountryMonthGeneral = (month,year) => {
        service.moreViewCountryMonthGeneral(month,year,(data,error) => {
             if(data && data.data){
                setMonthcountry(data.data);
             }
        });
    }

    const moreViewCountryMonthProfile = (month,year) => {
        service.moreViewCountryMonthProfile(month,year,(data,error) => {
             if(data && data.data){
                setMonthcountryProfile(data.data);
             }
        });
    }

    

    useEffect(() => {
        moreViewTodayGeneral();
        moreViewTodayProfile();
        moreViewWeekGeneral(weekSelected.format("YYYYMMDD"));
        moreViewWeekProfile(weekSelected.format("YYYYMMDD"));
        moreViewWeekUser(weekSelected.format("YYYYMMDD"));
        moreViewMonthGeneral(monthSelected.format("MM"), monthSelected.format("YYYY"));
        moreViewMonthProfile(monthSelected.format("MM"), monthSelected.format("YYYY"));
        exploitdatausergeneralweek(monthSelected.format("MM"), monthSelected.format("YYYY"));

        moreViewCountryTodayGeneral();
        moreViewCountryTodayProfile();
        moreViewCountryWeekGeneral(weekcountrySelected.format("YYYYMMDD"));
        moreViewCountryWeekProfile(weekcountrySelected.format("YYYYMMDD"));
        moreViewCountryMonthGeneral(monthcountrySelected.format("MM"), monthcountrySelected.format("YYYY"));
        moreViewCountryMonthProfile(monthcountrySelected.format("MM"), monthcountrySelected.format("YYYY"));

    }, []);

    const prevWeek = () => {
        var prev = weekSelected.subtract(1,'week');
        setWeekSelected(prev);
        moreViewWeekGeneral(prev.format("YYYYMMDD"));
        moreViewWeekProfile(prev.format("YYYYMMDD"));
        moreViewWeekUser(prev.format("YYYYMMDD"));
    }


    const nextWeek = () => {
        var prev = weekSelected.add(1,'week');
        setWeekSelected(prev);
        moreViewWeekGeneral(prev.format("YYYYMMDD"));
        moreViewWeekProfile(prev.format("YYYYMMDD"));
        moreViewWeekUser(prev.format("YYYYMMDD"));
    }


    const prevMonth = () => {
        var prev = monthSelected.subtract(1,'month');
        setMonthSelected(prev);
        moreViewMonthGeneral(prev.format("MM"), prev.format("YYYY"));
        moreViewMonthProfile(prev.format("MM"), prev.format("YYYY"));
        exploitdatausergeneralweek(prev.format("MM"), prev.format("YYYY"));
    }


    const nextMonth = () => {
        var prev = monthSelected.add(1,'month');
        setMonthSelected(prev);
        moreViewMonthGeneral(prev.format("MM"), prev.format("YYYY"));
        moreViewMonthProfile(prev.format("MM"), prev.format("YYYY"));
        exploitdatausergeneralweek(prev.format("MM"), prev.format("YYYY"));
    }

     /** COUNTRY */
    const prevWeekcountry = () => {
        var prev = weekcountrySelected.subtract(1,'week');
        setWeekcountrySelected(prev);
        moreViewCountryWeekGeneral(prev.format("YYYYMMDD"));
        moreViewCountryWeekProfile(prev.format("YYYYMMDD"));
    }

     const nextWeekcountry = () => {
        var prev = weekcountrySelected.add(1,'week');
        setWeekcountrySelected(prev);
        moreViewCountryWeekGeneral(prev.format("YYYYMMDD"));
        moreViewCountryWeekProfile(prev.format("YYYYMMDD"));
    }

    const prevMonthcountry = () => {
        var prev = monthcountrySelected.subtract(1,'month');
        setMonthcountrySelected(prev);
        moreViewCountryMonthGeneral(prev.format("MM"), prev.format("YYYY"));
        moreViewCountryMonthProfile(prev.format("MM"), prev.format("YYYY"));
    }


    const nextMonthcountry = () => {
        var prev = monthcountrySelected.add(1,'month');
        setMonthcountrySelected(prev);
        moreViewCountryMonthGeneral(prev.format("MM"), prev.format("YYYY"));
        moreViewCountryMonthProfile(prev.format("MM"), prev.format("YYYY"));
    }


    return (
        <Grid container spacing={3}>
            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>Más visitados HOY (En total)</h3>
                    </Grid>
                    {day.length  > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={day}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Total de vistas"/>
                            <Bar dataKey="numList" fill="#82ca9d" name="Total de veces Listado" />
                            <Bar dataKey="numView" fill="#8dd1e1"  name="Vistas perfil"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>
            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>Más visitados HOY en su perfil</h3>
                    </Grid>
                    {dayProfile.length  > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={dayProfile}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Total de vistas"/>
                            <Bar dataKey="numList" fill="#82ca9d" name="Total de veces Listado" />
                            <Bar dataKey="numView" fill="#8dd1e1"  name="Vistas perfil"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>

            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>Más visitados a la semana en total</h3>
                    </Grid>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginRight: "10px", cursor: "pointer", marginBottom: "-5px"}} onClick={prevWeek} /> 
                        <label>Semana {weekSelected.format("W")} ( {weekSelected.startOf('week').format("DD-MM-YYYY")} - {weekSelected.endOf('week').format("DD-MM-YYYY")} )</label>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginLeft: "10px", cursor: "pointer", transform: "rotate(180deg)", marginBottom: "-5px"}} onClick={nextWeek} /> 
                    </Grid>
                    {week.length  > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={week}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Total de vistas"/>
                            <Bar dataKey="numList" fill="#82ca9d" name="Total de veces Listado" />
                            <Bar dataKey="numView" fill="#8dd1e1"  name="Vistas perfil"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>
            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>Más visitados a la semana en su perfil</h3>
                    </Grid>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', padding:'25px'}}>
                     </Grid>
                    {weekProfile.length  > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={weekProfile}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Total de vistas"/>
                            <Bar dataKey="numList" fill="#82ca9d" name="Total de veces Listado" />
                            <Bar dataKey="numView" fill="#8dd1e1"  name="Vistas perfil"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>
            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>Usuarios reales por dia</h3>
                    </Grid>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', padding:'25px'}}>
                     </Grid>
                    {weekUser.length  > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={weekUser}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Total de usuarios"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>
            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}></Grid>

            

            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>Más visitados al més</h3>
                    </Grid>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginRight: "10px", cursor: "pointer", marginBottom: "-5px"}} onClick={prevMonth} /> 
                        <label>Més {monthSelected.format("MM")} - {monthSelected.format('YYYY')}</label>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginLeft: "10px", cursor: "pointer", transform: "rotate(180deg)", marginBottom: "-5px"}} onClick={nextMonth} /> 
                    </Grid>
                    {month.length  > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={month}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Total de vistas"/>
                            <Bar dataKey="numList" fill="#82ca9d" name="Total de veces Listado" />
                            <Bar dataKey="numView" fill="#8dd1e1"  name="Vistas perfil"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>
            

            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>Más visitados al més en su pefil</h3>
                    </Grid>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', padding: '25px'}}>
                    </Grid>
                    {monthProfile.length  > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={monthProfile}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Total de vistas"/>
                            <Bar dataKey="numList" fill="#82ca9d" name="Total de veces Listado" />
                            <Bar dataKey="numView" fill="#8dd1e1"  name="Vistas perfil"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>
            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>Más usuarios por semana del més</h3>
                    </Grid>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', padding: '25px'}}>
                    </Grid>
                    {monthUser.length  > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={monthUser}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Total de vistas"/>
                            <Bar dataKey="numList" fill="#82ca9d" name="Total de veces Listado" />
                            <Bar dataKey="numView" fill="#8dd1e1"  name="Vistas perfil"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>
            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}></Grid>

            <Grid item sm={12} xs={12} style={{textAlign:'center', marginBottom: '10px'}}>
                <h3>ESTADISTICAS POR PAIS</h3>
            </Grid>

            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>PAISES CON MAS VISITAS HOY (En total)</h3>
                    </Grid>
                    {daycountry.length  > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={daycountry}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Total de vistas"/>
                            <Bar dataKey="numList" fill="#82ca9d" name="Total de veces Listado" />
                            <Bar dataKey="numView" fill="#8dd1e1"  name="Vistas perfil"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>
            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>PAISES CON MAS VISITAS HOY A UN PERFIL</h3>
                    </Grid>
                    {daycountryProfile.length  > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={daycountryProfile}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Total de vistas"/>
                            <Bar dataKey="numList" fill="#82ca9d" name="Total de veces Listado" />
                            <Bar dataKey="numView" fill="#8dd1e1"  name="Vistas perfil"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>

            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>PAISES CON MÁS VISITAS A LA SEMANA</h3>
                    </Grid>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginRight: "10px", cursor: "pointer", marginBottom: "-5px"}} onClick={prevWeekcountry} /> 
                        <label>Semana {weekcountrySelected.format("W")} ( {weekcountrySelected.startOf('week').format("DD-MM-YYYY")} - {weekcountrySelected.endOf('week').format("DD-MM-YYYY")} )</label>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginLeft: "10px", cursor: "pointer", transform: "rotate(180deg)", marginBottom: "-5px"}} onClick={nextWeekcountry} /> 
                    </Grid>
                    {weekcountry.length  > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={weekcountry}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Total de vistas"/>
                            <Bar dataKey="numList" fill="#82ca9d" name="Total de veces Listado" />
                            <Bar dataKey="numView" fill="#8dd1e1"  name="Vistas perfil"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>
            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>PAISES CON MAS VISITAS A UN PERFIL A LA SEMANA</h3>
                    </Grid>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px',  padding: '25px'}}>
                    
                    </Grid>
                    {weekcountryProfile.length  > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={weekcountryProfile}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Total de vistas"/>
                            <Bar dataKey="numList" fill="#82ca9d" name="Total de veces Listado" />
                            <Bar dataKey="numView" fill="#8dd1e1"  name="Vistas perfil"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>

            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>PAISES CON MAS VISITAS EN EL MES</h3>
                    </Grid>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginRight: "10px", cursor: "pointer", marginBottom: "-5px"}} onClick={prevMonthcountry} /> 
                        <label>Més {monthcountrySelected.format("MM")} - {monthcountrySelected.format('YYYY')}</label>
                        <ArrowBack style={{border: "1px solid black",  borderRadius: "50%", marginLeft: "10px", cursor: "pointer", transform: "rotate(180deg)", marginBottom: "-5px"}} onClick={nextMonthcountry} /> 
                    </Grid>
                    {monthcountry.length  > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={monthcountry}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Total de vistas"/>
                            <Bar dataKey="numList" fill="#82ca9d" name="Total de veces Listado" />
                            <Bar dataKey="numView" fill="#8dd1e1"  name="Vistas perfil"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>
            

            <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>PAISES CON MAS VISITAS A UN PERFIL</h3>
                    </Grid>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', padding: '25px'}}>
                  
                   </Grid>
                    {monthcountryProfile.length  > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <BarChart width={700} height={250} data={monthcountryProfile}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="labelFormat" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="numTotal" fill="#8884d8" name="Total de vistas"/>
                            <Bar dataKey="numList" fill="#82ca9d" name="Total de veces Listado" />
                            <Bar dataKey="numView" fill="#8dd1e1"  name="Vistas perfil"/>
                        </BarChart>
                    </Grid> }
                </Grid>
            </Grid>

        </Grid>
    );

}