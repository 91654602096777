import BaseService from './_base.service';

export const orderproductService = {
    page,
    edit,
    search,
    create,
    one,
    remove,
    suscription,
    removeLogic
};

function page(page,size, callback) {
    return BaseService.get("/orderproduct/page/"+page+"/"+size, callback, true)
}

function search(page,size, filter, callback) {
    return BaseService.post("/orderproduct/page/"+page+"/"+size,filter, callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/orderproduct/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/orderproduct/", body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/orderproduct/one/"+id, callback);
}

function remove(id,callback) {
    return BaseService.delete("/orderproduct/"+id, callback);
}

function suscription(page,size, callback) {
    return BaseService.get("/user/orderproduct/page/"+page+"/"+size, callback, true)
}

function removeLogic(id,callback) {
    return BaseService.delete("/orderproduct/"+id+"/logic", callback);
}
