import React, { useState, useEffect }   from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { useStyles,drawerWidth } from "../../layout/styles";
import CrudTable from "../../crud/CrudTable"
import {markService as service, typeLensService, manufactureService} from "../../../services"

const initFilter =  {
    name : "",
    typelens: "",
    manufacture: null,
};


export default function Mark() {
  const [filter, setFilter] = React.useState(initFilter);
  const fieldstable = ["name","ordermark"];
  const headersTable =  ["Nombre","Posición"];


  const createEstructureFilter =  [
     {
       type:"input",
       name: "name",
       label: "Nombre",
       col: 4
      },
      {
        type:"autocomplete",
        name: "typelens",
        label: "Tipo",
        service: typeLensService.combo,
        col: 4
       },
       {
         type:"autocomplete",
         name: "manufacture",
         label: "Fabricante",
         service: manufactureService.combo,
         col: 4
        }
  ];


  return (
    <CrudTable
          service={service}
          titlePage={"Familia"}
          titleButton={"Crear familia"}
          urledit={"/lens/mark/"}
          urlnew={"/lens/mark/new/"}
          fieldId={"code"}
          filter={initFilter}
          fieldstable={fieldstable}
          headersTable={headersTable}
          estructureForm={createEstructureFilter}
      />
  );
}
