import BaseService from './_base.service';

export const manufactureSusService = {
    page,
    search,
    combo,
    edit,
    create,
    one,
    remove,
    removeLogic,
    searchSeries,
    addSeries,
    removeSeries
};

function page(page,size, callback) {
    return BaseService.get("/manufacturesuscription/page/"+page+"/"+size, callback)
}

function search(page,size,filter, callback) {
    return BaseService.post("/manufacturesuscription/page/"+page+"/"+size,filter, callback, true)
}

function combo(callback) {
    return BaseService.get("/manufacturesuscription/combo/", callback)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/manufacturesuscription/"+id, body, callback, false)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/manufacturesuscription/", body, callback, false)
}

function one(id,callback) {
    return BaseService.get("/manufacturesuscription/one/"+id, callback);
}

function remove(id,callback) {
    return BaseService.delete("/manufacturesuscription/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete("/manufacturesuscription/"+id+"/logic", callback);
}

function searchSeries(id,page,size,filter, callback) {
    return BaseService.post("/manufacturesuscription/"+id+"/series/page/"+page+"/"+size,filter, callback, true)
}

function addSeries(id,body, callback) {
    return BaseService.post("/manufacturesuscription/"+id+"/series/add",body, callback, true)
}
function removeSeries(id,body, callback) {
    return BaseService.post("/manufacturesuscription/"+id+"/series/remove",body, callback, true)
}