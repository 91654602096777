import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles,drawerWidth } from "../../layout/styles";
import CustomPaginationActionsTable from "../../table/Table"
import Filter from "../../filter/Filter"
import Form from "../../form/Form"
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {couponService as service} from "../../../services"

const initElement = {
     name:"",
     percent: false,
     discount: 0,
     duration: "",
     duration_in_months: 1,
     max_redemptions: 0,
     redeem_by: null,
     users: "",
     inModal: false,
     description: "",
     legendApply:""
  };





export default function FormCoupon() {
  const history = useHistory();
  let { id } = useParams();
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [objectform, setObjectform] = React.useState({...initElement});
  const [titleWindow, setTitleWindow] = React.useState(id && id > 0? 'Editar cupon':'Nuevo cupón');
  const [idRow, setIdrow] = React.useState(id);
  const [idSerie, setIdSerie] = React.useState(-1);
  const [cities,setCities] = React.useState([]);

  const [open, setOpen] = React.useState(true);
  const [rows,setRows] = React.useState([{}]);


  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };


  const [estructureform,setEstructureform] = React.useState([
         {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 4
          },
          {
           type:"checkbox",
           name: "percent",
           label: "En porcentaje",
           col: 4
         },
         {
           type:"input",
           name: "discount",
           label: "Descuento",
           inputtype:"number",
           step:0.01,
           col: 4
          },
          {
             type:"autocomplete",
             name: "duration",
             label: "Duración",
             options:[{key:"forever",name:"Para siempre"},{key:"once",name:"Solo una vez"},{key:"repeating",name:"X meses"}],
             col: 4,
           },
          {
            type:"input",
            name: "duration_in_months",
            label: "Meses que se aplica",
            inputtype:"number",
            col: 4
          },
          {
            type:"input",
            name: "max_redemptions",
            label: "Max. de usos",
            inputtype: "number",
            col: 4
           },
           {
             type:"input",
             name: "redeem_by",
             label: "Fecha fin",
             inputtype:"date",
             col: 4
            },
            {
              type:"input",
              name: "users",
              label: "Usuario permitidos",
              col: 4
             },
             {
              type:"checkbox",
              name: "inModal",
              label: "Usar como promoción en pagína de pago",
              col: 4
            },
            {
              type:"input",
              name: "legendApply",
              label: "Leyenda cuando se aplica",
              col: 4
             },
            {
             type:"textarea",
             name: "description",
             label: "Descripción",
             col: 12
           },
    ]);




  useEffect(() => {
      if(idRow && idRow > 0){
        searchOne();
        setTitleWindow('Editar cupón');
      };

  }, []);

  const searchOne = function(){
    service.one(idRow,(data, error) => {
      if(data && data.data){
        var element = data.data;
        let objectedit = JSON.parse(JSON.stringify(objectform));
        objectedit['name']=element.name?element.name:"";
        objectedit['percent']=element.percent?element.percent:false;
        objectedit['discount']=element.discount?element.discount:0;
        objectedit['duration']=element.duration?element.duration:"";
        objectedit['duration_in_months']=element.duration_in_months?element.duration_in_months:1;
        objectedit['max_redemptions']=element.max_redemptions?element.max_redemptions:0;
        objectedit['times_redeemed']=element.times_redeemed?element.times_redeemed:1;
        objectedit['redeem_by']=element.redeem_by?element.redeem_by.replace("T00:00:00Z",""):null;
        objectedit['users']=element.users?element.users:"";
        objectedit['legendApply']=element.legendApply?element.legendApply:"";
        objectedit['description']=element.description?element.description:"";
        objectedit['inModal']=element.inModal?element.inModal:false;
        setObjectform(objectedit);

     }
    });
  }

  const cancelForm = (object) => {
      history.push('/coupon');
  }

  const saveForm = (object) => {
      if(object['redeem_by']){
        object['redeem_by']=object['redeem_by']+"T00:00:00Z";
      }
       if(isValid(object)) {
         var objToSave = cleanElementsBeforeSave(object);
         if (idRow && idRow > 0){
           objToSave['id'] = id;
           update(objToSave,false);
         } else {
           create(objToSave,false);
         }
       }

  }

  const create = (object,cleanSerie) => {
    service.create(object, (data, error) => {
        if(data){
          setIdrow(data.data.id)
          setTitleWindow('Editar cupón');
          history.push('/coupon/'+data.data.id);
        }
    });
  }

  const update = (object,cleanSerie) => {
    service.edit(id,object, (data, error) => {
        if(data){

        }
    });
  }



  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      }
      if(!object['discount']){
        toast.notify("Debe indicar el descuento", {position:'top', duration: 10000})
        return false;
      }
      if(!object['redeem_by']){
        toast.notify("Debe indicar la fecha de fin", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }

  const cleanElementsBeforeSave = function(object){
    let newObj = JSON.parse(JSON.stringify(object));
    return newObj;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title={titleWindow} classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} className={classes.bodycontainer}>
              <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                  <Form objectform={objectform} fields={estructureform}  searchFilter={saveForm} cleanFilter={cancelForm} labelsave="Guardar" labelcancel="Cancelar"/>
              </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
